<div _ngcontent-eer-c169="" class="newsfeed ng-star-inserted" [class.newsfeed-article]="isNewsArticle(newsfeed.type)"
  [class.newsfeed-contest]="isNewsContest(newsfeed.type)" [class.newsfeed-project]="isNewsProject(newsfeed.type)">
  <!-- 
  <div *ngIf="isNewsArticle(newsfeed.type)" class="">
    <mat-card-header class="like-status view-it" (click)="readNewsContent(newsfeed.objectid,newsfeed.type)">
      <mat-card-title class="name">
        {{newsfeed.headerline}}
      </mat-card-title>
      <span fxFlex></span>
        <button class="post-settings" mat-icon-button *ngIf="newsfeed.entity.editable"
        (click)="readNewsContent(newsfeed.objectid,newsfeed.type, newsfeed.entity.profilepicture, newsfeed?.entity.names, newsfeed?.entity.summary)">
        <mat-icon class="edit-button">edit</mat-icon>
      </button> 
    
      
    </mat-card-header>
  </div>
-->

  <!-- {{this?.profile?.profilepicture}} -->

  <div class="header-card-container">
    <div class="expert-profile-container"
      (click)="newsfeed?.entity?.draft === 1 ? openExpertPublicProfile(newsfeed?.entity?.userinfo?.id, $event) : openExpertProfile(newsfeed?.entity)">
      <div *ngIf="newsfeed?.entity?.draft === 0">
        <div class="organisation-profile-image" *ngIf="newsfeed.entity.profilepicture">
          <img [src]="getUrl(newsfeed.entity.profilepicture) | secure | async" alt="photo"
            style="width: 40px; height: 40px;" *ngIf="newsfeed.entity?.profilepicture">
        </div>
        <div *ngIf="!newsfeed.entity.profilepicture" class="profile-initial">
          {{ newsfeed.entity.names ? newsfeed.entity.names[0].toUpperCase() : '' }}
        </div>
      </div>
      <div *ngIf="newsfeed?.entity?.draft === 1">
        <div class="organisation-profile-image" *ngIf="newsfeed?.entity?.userinfo?.profilepicture">
          <img [src]="getUrl(newsfeed?.entity?.userinfo?.profilepicture) | secure | async" alt="photo"
            *ngIf="newsfeed?.entity?.userinfo?.profilepicture">
        </div>
        <div *ngIf="!newsfeed?.entity?.userinfo?.profilepicture" class="profile-initial">
          {{ newsfeed?.entity?.userinfo?.firstname ? newsfeed?.entity?.userinfo?.firstname[0].toUpperCase() : '' }}
        </div>
      </div>
      <div>
        <div *ngIf="newsfeed?.entity?.draft === 0" class="name">{{newsfeed?.entity.names}}</div>
        <div *ngIf="newsfeed?.entity?.draft === 1" class="name">{{newsfeed?.entity?.userinfo?.firstname + ' ' +
          newsfeed?.entity?.userinfo?.lastname}}</div>
        <span *ngIf="newsfeed.type === 'contest'" class="challenge-text">{{'newsfeed.type.contest' | translate}}</span>
        <span *ngIf="newsfeed.type === 'project'" class="challenge-text">{{'newsfeed.type.project' | translate}}</span>
        <span *ngIf="newsfeed.type === 'article'" class="challenge-text">{{'newsfeed.type.article' | translate}}</span>
      </div>
      <!-- <div *ngIf="newsfeed?.entity.headline" class="summary">{{newsfeed?.entity.headline}}</div> -->
    </div>
    <div *ngIf="!isNewsArticle(newsfeed.type)" class="news_button">
      <div *ngIf="newsfeed.type === 'contest'" class="contest-control-wrapper">
        <div *ngIf="newsfeed?.entity?.openAppl" class="contest-info">

          <button class="appl_button" *ngIf="canGotoChallenge(newsfeed.application_status)"
            (click)="readNewsContent(newsfeed.objectid,newsfeed.type, newsfeed?.entity?.entityid)">
            <span>{{'newsfeed.applied-contest' | translate}}</span>
          </button>

          <button class="appl_button" *ngIf="!showManageButton(newsfeed) && canApply(newsfeed.application_status)"
            (click)="applyButtonContest(newsfeed.objectid, newsfeed.entity)">{{'newsfeed.apply' | translate}}
          </button>

          <button class="appl_button" *ngIf="showManageButton(newsfeed) && ownsContent(newsfeed?.entity?.entityid)"
            (click)="readNewsContent(newsfeed.objectid,newsfeed.type, newsfeed?.entity?.entityid)">{{'newsfeed.manage' |
            translate}}
          </button>

          <div class="nws_feed_meta">{{'newsfeed.start-date' | translate}}: <b>{{newsfeed?.started_at}}</b></div>
          <div class="nws_feed_meta">{{'newsfeed.apply-by' | translate}}: <b>{{newsfeed?.openuntildate}}</b></div>
          <div class="nws_feed_meta">{{'newsfeed.applications' | translate}}: <b>{{newsfeed?.applications}}</b></div>

        </div>
        <div *ngIf="!newsfeed?.entity?.openAppl">
          <button class="appl_button" *ngIf="showManageButton(newsfeed) && ownsContent(newsfeed?.entity?.entityid)"
            (click)="readNewsContent(newsfeed.objectid,newsfeed.type, newsfeed?.entity?.entityid)">{{'newsfeed.manage' |
            translate}}
          </button>
          <div class="nws_feed_meta">{{'newsfeed.closed-for-applicants' | translate}}</div>
          <div class="nws_feed_meta">{{'newsfeed.started-at' | translate}}: <b>{{newsfeed?.started_at}}</b></div>
        </div>
      </div>

      <div *ngIf="newsfeed.type === 'project'" class="project-control-wrapper">
        <div *ngIf="newsfeed?.entity?.openAppl" class="contest-info">
          <button class="appl_button" *ngIf="canGotoChallenge(newsfeed.application_status)"
            (click)="manageButtonProject(newsfeed.objectid,  newsfeed?.entity?.entityid)">
            <span>{{'newsfeed.applied-project' | translate}}</span>
          </button>
          <button class="appl_button" *ngIf="!showManageButton(newsfeed) && canApply(newsfeed.application_status)"
            (click)="applyButtonProject(newsfeed.objectid)">{{'newsfeed.apply' | translate}}
          </button>
          <button class="appl_button" *ngIf="showManageButton(newsfeed) && ownsContent(newsfeed?.entity?.entityid)"
            (click)="readNewsContent(newsfeed.objectid,newsfeed.type, newsfeed?.entity?.entityid)">{{'newsfeed.manage' |
            translate}}
          </button>
          <div class="nws_feed_meta">{{'newsfeed.start-date' | translate}}: <b>{{newsfeed?.startdateproj }}</b></div>
          <!-- <div class="nws_feed_meta">End date: <b>{{newsfeed?.enddateproj | date: 'mediumDate'}}</b></div> -->
          <!-- <div class="nws_feed_meta">Applications open on: <b>{{newsfeed?.startdateproj | date: 'mediumDate'}}</b></div> -->
          <div class="nws_feed_meta">{{'newsfeed.apply-by' | translate}}: <b>{{newsfeed?.enddate }}</b>
            <div class="nws_feed_meta">{{'newsfeed.applications' | translate}}: <b>{{newsfeed?.applications}}</b></div>
          </div>
        </div>
        <div *ngIf="!newsfeed?.entity?.openAppl">
          <button class="appl_button" *ngIf="ownsContent(newsfeed?.entity?.entityid)"
            (click)="readNewsContent(newsfeed.objectid,newsfeed.type, newsfeed?.entity?.entityid)">{{'newsfeed.manage' |
            translate}}
          </button>

          <div class="nws_feed_meta">{{'newsfeed.start-date' | translate}}: <b>{{newsfeed?.started_at}}</b></div>
          <div class="nws_feed_meta">{{'newsfeed.apply-by' | translate}}: <b>{{newsfeed?.openuntildate}}</b></div>
          <div class="nws_feed_meta">{{'newsfeed.applications' | translate}}: <b>{{newsfeed?.applications}}</b></div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-entity-newsfeed-name"
    (click)="readNewsContent(newsfeed.objectid,newsfeed.type, newsfeed?.entity?.entityid)">
    <span>{{newsfeed.headerline}}</span>


    <div *ngIf="newsfeed.mainimage" class="content-image"
      (click)="readNewsContent(newsfeed.objectid,newsfeed.type, newsfeed?.entity?.entityid)">
      <div *ngIf="secureImageUrl | async as bufferUrl; else loadingImage" class="background-image"
        [style.background-image]="bufferUrl">
      </div>
      <ng-template #loadingImage><span>Image is Loading...</span></ng-template>
    </div>

  </div>


  <!-- 
  <div class="post-image">

   
    <div *ngIf="isNewsContestApplicable(newsfeed.type)">
        <div class="news_button">
        <button class="appl_button" (click)="applyButtonContest(newsfeed.objectid)"
          *ngIf="newsfeed.entity.editable == false && newsfeed.entity.openAppl == true">Apply</button>
        <div class="nws_feed_meta">Applications: <b>{{newsfeed?.applications}}</b></div>
        <div class="nws_feed_meta">Open until: <b>{{newsfeed?.openuntildate | date: 'mediumDate'}}</b></div>
      </div> 
    </div>
  -->

  <!-- <div>
      <div class="news_button">
        <button class="appl_button" (click)="applyButtonProject(newsfeed.objectid)"
          >Apply</button>
        <div class="nws_feed_meta">Applications: <b>{{newsfeed?.applications}}</b></div>
        <div class="nws_feed_meta">Open until: <b>{{newsfeed?.openuntildate | date: 'mediumDate'}}</b></div>
      </div>
    </div>
  </div> -->



  <div (click)="readNewsContent(newsfeed.objectid,newsfeed.type, newsfeed?.entity?.entityid)">
    <quill-view-html
      [content]="newsfeed?.showtext?.length > 275 ? newsfeed?.showtext.slice(0, 275) + '...' : newsfeed?.showtext"
      style="width: 100%;cursor: pointer;"></quill-view-html>
  </div>






  <div class="social-wrapper">
    <div class="social-pad">
      <div class="social-info-block">
        <div class="likes-wrapper">
          <span>{{ newsfeed?.like_count}}</span>
          <!-- {{'newsfeed.actions.likes' | translate}} -->
          {{(newsfeed?.like_count === 0 || newsfeed?.like_count > 1) ? ('newsfeed.actions.likes' | translate)
          : ('newsfeed.actions.single-like' | translate)}}
        </div>
        <div class="comments-wrapper">
          <a href="javascript:void(0)" [matMenuTriggerFor]="readComments" (click)="getComments(newsfeed.objectid)"
            #commentTrigger="matMenuTrigger">
            <span>{{newsfeed?.comment_count}}</span>
            {{(newsfeed?.comment_count === 0 || newsfeed?.comment_count > 1) ? ('newsfeed.actions.comments' | translate)
            : ('newsfeed.actions.single-comment' | translate)}}
          </a>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <div class="button-likes">
        <button mat-button [color]="newsfeed?.is_liked ? 'primary' : ''"
          [matTooltip]="newsfeed?.is_liked ? 'Unlike' : 'Like'" (click)="likeUnlike(newsfeed)">
          <mat-icon>thumb_up</mat-icon> {{newsfeed?.is_liked ? ('newsfeed.actions.liked' | translate) :
          ('newsfeed.actions.like' | translate)}}</button>
      </div>
      <!-- <div class="button-shares">
        <button mat-button matTooltip="Share the contest" [matMenuTriggerFor]="shareMenu"
          #shareTrigger="matMenuTrigger"> <mat-icon>reply</mat-icon> Share</button>
      </div> -->
      <div class="button-comments">
        <button mat-button [matTooltip]="'newsfeed.actions.comment.tooltip' | translate"
          [matMenuTriggerFor]="postComment" #postTrigger="matMenuTrigger">
          <mat-icon>comment</mat-icon> {{'newsfeed.actions.comment.icon' | translate}}</button>
      </div>
    </div>
  </div>

  <mat-menu #readComments="matMenu" class="custom-mat-menu">
    <div *ngFor="let comment of comments" class="comment-unit">
      <div class="comment-header-wrapper">
        <div>{{comment.userData.firstname + ' ' + comment.userData.lastname}}</div>
      </div>
      <mat-hint>{{comment.ts | date: 'medium'}}</mat-hint>
      <div>{{comment.comment}}</div>
    </div>
    <div class="no-comment-unit" *ngIf="comments?.length === 0">{{'newsfeed.actions.no-comments' | translate}}</div>
  </mat-menu>

  <mat-menu #postComment="matMenu" class="custom-mat-menu">
    <form [formGroup]="commentForm" fxLayout="column" (click)="$event.stopPropagation()" fxLayoutAlign="start center"
      fxLayoutGap="10px">
      <mat-form-field class="custom-form-field" appearance="outline" style="padding: 1rem;">
        <mat-label>{{'newsfeed.actions.comment.label' | translate}}</mat-label>
        <textarea matInput type="text" [placeholder]="'newsfeed.actions.comment.placeholder' | translate"
          formControlName="comment"></textarea>
      </mat-form-field>

      <button type="button" mat-stroked-button
        (click)="comment(newsfeed?.objectId,postTrigger)">{{'newsfeed.actions.comment.button' | translate}}</button>
    </form>
  </mat-menu>

  <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
    <form [formGroup]="shareForm" (click)="$event.stopPropagation()">
      <p>{{'newsfeed.actions.share' | translate}}</p>
      <div fxLayout="column" fxLayoutAlign="start center">
        <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="uuid"
          bindLabel="name" [clearable]="true" formControlName="mynetwork" [notFoundText]="'No Results'"
          typeToSearchText="Search" placeholder="My Network">
        </ng-select>
        <!-- <button type="button" mat-stroked-button (click)="share(shareTrigger)">Share</button> -->
      </div>
    </form>
  </mat-menu>

  <!--  <mat-menu #sendMenu="matMenu" class="custom-mat-menu">
    <form [formGroup]="sendForm" (click)="$event.stopPropagation()">
      <p>Send to:</p>
      <div fxLayout="column" fxLayoutAlign="start center">
        <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="uuid"
          bindLabel="name" [clearable]="true" formControlName="userid" [notFoundText]="'No Results'"
          typeToSearchText="Search" placeholder="Person">
        </ng-select>

        <button type="button" mat-stroked-button (click)="send(sendTrigger)">Send</button>
      </div>
    </form>
  </mat-menu> -->

</div>