<div class="main-div">
    <div class="read-card">
        <mat-card class="article-main-container mat-elevation-z3">
            <!-- <pre>{{ article | json }}</pre> -->
            <div class="row" style="display: flex; padding: 16px">
                <div style="display: flex; gap: 8px">
                    <div>
                        <!-- <div *ngIf="entity?.draft === 0" style="cursor: pointer" (click)="openExpertProfile()">
                            <img [src]="getUrl(entity?.logofileid) | secure | async" alt="photo" class="profile-pic"
                                *ngIf="entity?.logofileid !== ''" />
                            <div *ngIf="entity?.logofileid === ''" class="profile-initial">
                                {{ entity?.orgname ? entity?.orgname[0].toUpperCase() : '' }}
                            </div>
                        </div> -->

                        <!-- <div *ngIf="entity?.draft === 1" style="cursor: pointer" (click)="openExpertProfile()">
                            <img [src]="getUrl(userPersonalData?.profilepicture) | secure | async" alt="photo"
                                class="profile-pic" *ngIf="userPersonalData?.profilepicture" />
                            <div *ngIf="!userPersonalData?.profilepicture" class="profile-initial">
                                {{ userPersonalData?.firstname ? userPersonalData?.firstname[0].toUpperCase() : '' }}
                            </div>
                        </div> -->

                    </div>
                    <h1 class="article-title">{{ article?.title }}</h1>
                    <p class="mute-tag">{{ article?.type }}</p>
                </div>
                <div style="display: flex; gap: 8px" class="mutes-container">
                    <p class="mute-tag">
                        {{ "Published on " + article?.ts?.split("T")[0] }}
                    </p>
                </div>
                <p class="mute-tag">{{ article?.category + " Category" }}</p>
            </div>
            <!-- <center *ngFor="let i of list" class="logo-container-article">
                <img [src]="getUrl(article?.mainimage) | secure | async" />
            </center> -->

            <div class="content-container">
                <!-- <h2>Content</h2> Not sure if this is needed-->
                <quill-view-html [content]="article?.content"></quill-view-html>
            </div>


            <div class="bibliography-container">
                <h2>Bibliography</h2>
                <quill-view-html [content]="article?.bibliography" theme="snow"></quill-view-html>
            </div>

            <!-- <div *ngIf="article?.mainimage && files?.length - 1 > 0" class="bibliography-container">
                <h2>Additional resources</h2>
                <div *ngFor="let file of files" fxLayout="row" fxLayoutAlign="space-between left">
                    <a *ngIf="file?.id !== article?.mainimage" [href]="getUrl(file?.id) | secure | async"
                        download="{{ file.name }}">
                        <span>{{ file.name }}</span>
                        <span class="file-size">({{ file.sizebytes | fileSize }})</span>
                    </a>
                </div>
            </div> -->

            <div class="keyword-container" fxLayout="row wrap" fxLayoutGap="10px">
                <p *ngFor="let keyword of article?.keywords" class="keyword-wrap">
                    {{ keyword?.keyword }}
                </p>
            </div>

            <!-- <app-social-media-controlls [content]="article"></app-social-media-controlls> -->

        </mat-card>
    </div>
</div>