<main class="container">
    <div class="card">
        <div class="messenger-container" *ngIf="loadingEntities">
            <span class="spinner-container">
                <mat-spinner diameter="60" color="primary"></mat-spinner>
            </span>
        </div>

        <div class="messenger-container" *ngIf="!loadingEntities">
            <div class="user-list-column">
                <div *ngIf="this?.contacts?.length > 0" class="card-items">
                    <mat-list-item *ngFor="let user of this?.contacts" (click)="selectUser(user)" class="user-item">
                        <div class="profile-pic-messenger">
                            <img class="image" *ngIf=" user?.userdata?.profilepicture"
                                [src]="getUrl( user?.userdata?.profilepicture) | secure | async">
                            <span *ngIf="!user?.userdata?.profilepicture"
                                class="no-img">{{user?.userdata?.firstname[0].toUpperCase()}}</span>
                            <span class="user-name-span">
                                {{ (user?.userdata?.firstname + ' ' + user?.userdata?.lastname).slice(0, 20) || '' }}
                                <ng-container
                                    *ngIf="(user?.userdata?.firstname + ' ' + user?.userdata?.lastname)?.length > 20">...</ng-container>
                            </span>
                        </div>
                    </mat-list-item>
                </div>

                <!-- <div *ngIf="!this?.contacts?.length" class="card-items">
                    <span class="no-contacts-card">{{'messaging.contacts.text' | translate}}
                        <mat-icon>sentiment_very_dissatisfied</mat-icon></span>
                </div> -->

                <div *ngIf="!this?.contacts?.length" class="card-items">
                    <span class="no-contacts-card">{{text}}
                        <mat-icon>sentiment_very_dissatisfied</mat-icon></span>
                </div>
            </div>


            <span *ngIf=" loadingMessages" class="spinner-container">
                <mat-spinner diameter="60" color="primary"></mat-spinner>
            </span>
            <mat-card class="chat-window-column" *ngIf="selectedUser && !loadingMessages">
                <div *ngIf="!loadingMessages" class="selected-user-info">
                    <div class="profile-pic-selected" (click)="openExpertPublicProfile(selectedUser.id)">
                        <img class="image" *ngIf=" selectedUser?.profilepicture"
                            [src]="getUrl( selectedUser?.profilepicture) | secure | async">
                        <span *ngIf="!selectedUser?.profilepicture"
                            class="no-img">{{selectedUser?.firstname[0].toUpperCase()}}</span>
                        <div>{{ selectedUser?.firstname }} {{ selectedUser?.lastname }}</div>
                        <div class="refresh-inbox-btn" (click)="refreshMessages(profile.userid, selectedUser.id)"
                            matTooltip="Refresh Inbox">
                            <mat-icon>refresh</mat-icon>
                        </div>
                    </div>
                </div>
                <mat-list *ngIf="!loadingMessages" #scrollMe [scrollTop]="scrollMe?.scrollHeight" class="list-mat">
                    <section *ngIf="allMessages?.length && !loadingMessages" class="messages-section">
                        <mat-list-item *ngFor="let message of allMessages; let last = last">
                            <span
                                [ngClass]="(message?.senderid === profile?.userid) ? 'message-ts-sender' : 'message-ts'">
                                {{ message.ts | date: 'medium' }}
                            </span>
                            <div class="message-container">
                                <span [ngClass]="message?.senderid === profile?.userid ? 'sent-by-reciever' : 'sent'">
                                    {{ message.message }}
                                </span>
                            </div>
                            <div *ngIf="message?.senderid === profile?.userid" class="seen-message">
                                <div *ngIf="last && message.is_read">Read <mat-icon
                                        class="check-icon">check_circle</mat-icon></div>
                            </div>
                            <div *ngIf="message?.senderid === profile?.userid" class="seen-message">
                                <div *ngIf="last && !message.is_read">Delivered <mat-icon
                                        class="check-icon">check_circle_outline</mat-icon></div>
                            </div>
                        </mat-list-item>
                    </section>
                    <section *ngIf="!allMessages?.length && !loadingMessages">
                        <span class="no-messages-card">{{'messaging.start.text' | translate}}</span>
                    </section>
                </mat-list>
                <div class="message-input">
                    <mat-form-field class="write-msg" floatLabel="never">
                        <input matInput [(ngModel)]="newMessage" #messageInput
                            [placeholder]="'messaging.start.placeholder' | translate"
                            (keyup.enter)="sendMessage(newMessage, selectedUser.id)">
                        <mat-icon matSuffix (click)="sendMessage(messageInput.value, selectedUser.id)"
                            style="cursor: pointer;">{{'messaging.send.text' | translate}}</mat-icon>
                    </mat-form-field>
                </div>
            </mat-card>

            <mat-card class="chat-window-column-none" *ngIf="!selectedUser">
                <div class="no-chat-selected">
                    <p class="no-chat-text">{{'messaging.select.text' | translate}}</p>
                    <mat-icon class="no-chat-icon">chat</mat-icon>
                </div>
            </mat-card>
        </div>
    </div>
</main>