import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from '../dashboard/_components/nav-bar/nav-bar.component';
import { NavigationComponent } from './navigation.component';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatFormField, MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatBadgeModule } from '@angular/material/badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenav, MatSidenavContainer, MatSidenavContent, MatSidenavModule } from '@angular/material/sidenav';
import { AuthModule } from '../auth/auth.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PipesModule } from '../pipes/pipes.module';
import { QuillModule } from 'ngx-quill';
import { DashboardRoutingModule } from '../dashboard/dashboard-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    NavBarComponent,
    NavigationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    PipesModule,
    MatTooltipModule,
    QuillModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatTabsModule,
    MatChipsModule,
    MatCardModule,
    IvyCarouselModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    AuthModule,
    TranslateModule,
    
  ],
  exports: [
    NavBarComponent,
    NavigationComponent,
    RouterModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AuthModule
  ]
})
export class NavigationModule { }
