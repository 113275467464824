import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleService } from 'src/app/article/_services/article.service';

@Component({
  selector: 'app-entity-view-article',
  templateUrl: './entity-view-article.component.html',
  styleUrls: ['./entity-view-article.component.scss']
})
export class EntityViewArticleComponent implements OnInit {

  article
  entityId;
  articleId
  constructor(
    private articleService: ArticleService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.initAll()
  }

  initAll(): any {
    this.setIds()
    this.getArticle()

  }

  setIds(): any {
    this.setProjectId()
    this.setEntityId()
  }

  setProjectId(): any {
    this.route.parent.params.subscribe(param => {
      this.entityId = param.entityId
    })
  }

  setEntityId(): any {
    this.route.params.subscribe(param => {
      this.articleId = param.articleId
    })
  }

  getArticle(): any {
    this.articleService.getArticle(this.entityId, this.articleId).subscribe(res => {
      console.log(res);
      this.article = res.data[0]
    })
  }

}
