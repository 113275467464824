import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private readonly PROFILE_URL = `${environment.API_HOST}/user/profile/`;
  private readonly CONTESTAPPLS = `${environment.API_HOST}/user/contest/applications/`;
  private readonly PROJECTAPPLS = `${environment.API_HOST}/user/project/applications/`;
  private readonly ACTIVITY_URL = `${environment.API_HOST}/activity/`
  private readonly SUBSCRIPTIONS_URL = `${environment.API_HOST}/user/subscriptions/`;
  private readonly PROFILE_URL_BYID = `${environment.API_HOST}/user/info/`;
  private readonly ACTIVITIES_URL = `${environment.API_HOST}/user/activities`
  private readonly STATS_URL = `${environment.API_HOST}/user/stats`
  private readonly WIDGETS_URL = `${environment.API_HOST}/home/widgets/`;
  private readonly CHALLENGES_COUNTS_URL = `${environment.API_HOST}/challenges/counts/`;
  private readonly CHALLENGES_PROJECTS_URL = `${environment.API_HOST}/challenges/projects/`;
  private readonly NEWSFEED_HOMEPAGE_URL = `${environment.API_HOST}/newsfeed/homepage/`;
  private readonly NEWSFEED_COUNTS_URL = `${environment.API_HOST}/newsfeed/counts/`;
  private readonly LEADERBOARD_URL = `${environment.API_HOST}/leaderboard/summary/`;
  private readonly MESSAGE_URL = `${environment.API_HOST}/home/messages/`;
  private readonly NOTIFICATION_URL = `${environment.API_HOST}/home/notifications/`;
  private readonly MESSAGING_URL = `${environment.API_HOST}/messages/`;
  private readonly CHALLENGE_URL = `${environment.API_HOST}/home/challenges/`;
  private readonly BUTTON_URL = `${environment.API_HOST}/home/createcontent/`;
  private readonly PROJECTS_URL = `${environment.API_HOST}/project/`
  private readonly ENTITY_URL = `${environment.API_HOST}/entity/`;
  private readonly NEWSFEED_URL = `${environment.API_HOST}/newsfeed/`;
  private readonly RELATIONS_URL = `${environment.API_HOST}/entity/entity-relations/`

  constructor(private http: HttpClient,
    private sanitizer: DomSanitizer, private cookieService: CookieService) {
  }

  getSubscriptions(): Observable<any> {
    return this.http.get<any>(this.SUBSCRIPTIONS_URL);
  }

  getUserProfile(): Observable<any> {
    return this.http.get<any>(this.PROFILE_URL);
  }

  getEntityRelations(entityId): Observable<any> {
    return this.http.get<any>(`${this.RELATIONS_URL}${entityId}`);
  }

  getUserStats(): Observable<any> {
    return this.http.get<any>(this.STATS_URL)
  }

  getUserProfileById(id): Observable<any> {
    return this.http.get<any>(`${this.PROFILE_URL_BYID}${id}`);
  }

  getMyActivities(userid): Observable<any> {
    return this.http.get<any>(`${this.ACTIVITY_URL}${userid}`);
  }

  getWidgets(): Observable<any> {
    return this.http.get<any>(this.WIDGETS_URL);
  }

  getChallengesCounts(): Observable<any> {
    return this.http.post<any>(this.CHALLENGES_COUNTS_URL, {});
  }

  getChallengesProjects(): Observable<any> {
    return this.http.post<any>(this.CHALLENGES_PROJECTS_URL, {});
  }

  getNewsfeedHomepage(numberNewsfeedPosts): Observable<any> {
    let data = {
      num_recs: numberNewsfeedPosts
    }
    return this.http.post<any>(this.NEWSFEED_HOMEPAGE_URL, data);
  }

  getNewsfeedSingle(feedid): Observable<any> {
    return this.http.get<any>(`${environment.API_HOST}/${feedid}`);
  }

  getNewsfeedCounts(): Observable<any> {
    return this.http.get<any>(this.NEWSFEED_COUNTS_URL);
  }

  getUserActivity(): Observable<any> {
    return this.http.get<any>(this.ACTIVITY_URL);
  }

  getLeaderboard(): Observable<any> {
    return this.http.post<any>(this.LEADERBOARD_URL, {});
  }

  getMessages(): Observable<any> {
    return this.http.get<any>(this.MESSAGE_URL);
  }

  getTalentCotests(): Observable<any> {
    return this.http.get<any>(this.CONTESTAPPLS);
  }

  getTalentProjects(): Observable<any> {
    return this.http.get<any>(this.PROJECTAPPLS);
  }

  getNotifications(): Observable<any> {
    return this.http.get<any>(this.NOTIFICATION_URL);
  }

  getNotificationsPost(entityId): Observable<any> {
    let url = `${this.NOTIFICATION_URL}${entityId}`;

    return this.http.post<any>(url, entityId);
  }

  postNotifications(entityId, loadMore): Observable<any> {
    let data = {
      per_page: 10,
      from_items: loadMore
    }
    let url = `${this.NOTIFICATION_URL}${entityId}`;

    return this.http.post<any>(url, data);
  }

  postMarkReadNotifications(entityId, notifid): Observable<any> {
    let data = {
      notifid: notifid
    }
    let url = `${this.NOTIFICATION_URL}${entityId}/markread`;

    return this.http.post<any>(url, data);
  }

  markReadMessage(receiverUser, data): Observable<any> {
    let url = `${this.MESSAGING_URL}${receiverUser}/markread`;

    return this.http.post<any>(url, data);
  }

  getChallenges(): Observable<any> {
    return this.http.get<any>(this.CHALLENGE_URL);
  }

  getButtons(): Observable<any> {
    return this.http.get<any>(this.BUTTON_URL);
  }


  getEntity(id): Observable<any> {
    return this.http.get<any>(`${this.ENTITY_URL}${id}/read/`);
  }

  getNewsfeed(id): Observable<any> {
    return this.http.post<any>(`${this.ENTITY_URL}${id}/newsfeed/`, {});
  }

  getContests(id): Observable<any> {
    return this.http.post<any>(`${this.ENTITY_URL}${id}/contests/`, {});
  }

  getProjects(id): Observable<any> {
    return this.http.post<any>(`${this.ENTITY_URL}${id}/projects/`, {});
  }

  getAllProjects(): Observable<any> {
    return this.http.get<any>(`${this.PROJECTS_URL}`, {})
  }

  getAllAvailableProjects(): Observable<any> {
    return this.http.get<any>(`${this.PROJECTS_URL}/available`)
  }
  getArticles(id): Observable<any> {
    return this.http.post<any>(`${this.ENTITY_URL}${id}/articles/`, {});
  }

  getPeople(id): Observable<any> {
    return this.http.post<any>(`${this.ENTITY_URL}${id}/people/`, {});
  }

  changeLogo(data, id): Observable<any> {
    return this.http.post<any>(`${this.ENTITY_URL}${id}/changelogo/`, data);
  }

  changeMainImage(data, id): Observable<any> {
    return this.http.post<any>(`${this.ENTITY_URL}${id}/changemainimage/`, data);
  }

  follow(id): Observable<any> {
    return this.http.get<any>(`${this.ENTITY_URL}${id}/follow/`);
  }

  unfollow(id): Observable<any> {
    return this.http.get<any>(`${this.ENTITY_URL}${id}/unfollow/`);
  }

  reportAbuse(id): Observable<any> {
    return this.http.get<any>(`${this.ENTITY_URL}${id}/reportabuse/`);
  }

  message(id, data): Observable<any> {
    return this.http.post<any>(`${this.ENTITY_URL}${id}/message/`, data);
  }

  entityShare(id, data): Observable<any> {
    return this.http.post<any>(`${this.ENTITY_URL}${id}/share/`, data);
  }

  getComments(id): Observable<any> {
    return this.http.get(`${this.NEWSFEED_URL}${id}/comments/`);
  }

  postComment(id, data): Observable<any> {
    return this.http.post(`${this.NEWSFEED_URL}${id}/comment/`, data);
  }

  send(id, data): Observable<any> {
    return this.http.post(`${this.NEWSFEED_URL}${id}/send/`, data);
  }

  newsfeedShare(id, data): Observable<any> {
    return this.http.post(`${this.NEWSFEED_URL}${id}/share/`, data);
  }

  like(objectid, entityid): Observable<any> {
    return this.http.get(`${this.NEWSFEED_URL}${objectid}/${entityid}/likeit/`);
  }

  unlike(objectid, entityid): Observable<any> {
    return this.http.get(`${this.NEWSFEED_URL}${objectid}/${entityid}/unlikeit/`);
  }


  transform(url): Observable<SafeUrl> {
    const access = this.cookieService.get('access');
    return this.http.get(url, { responseType: 'blob', headers: { 'agora-sec-token': access } }).pipe(
      map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }

  postProfileInfo(entityId): Observable<any> {
    let data = {
      "userid": entityId
    }
    return this.http.post(`${environment.API_HOST}/user/publicprofile`, data);
  }

  getChatMessages(senderId, receiverId): Observable<any> {
    let data = {
      "receiverid": receiverId
    }
    return this.http.post<any>(`${environment.API_HOST}/messages/${senderId}`, data);
  }

  getChatMessagesAfterID(senderId, receiverId, messid): Observable<any> {
    let data = {
      "senderid": receiverId,
      "messid": messid,
    }
    return this.http.post<any>(`${environment.API_HOST}/messages/${senderId}/getnewmessages`, data);
  }

  postNewMessage(senderId, receiverId, message): Observable<any> {
    let data = {
      "receiverid": receiverId,
      "message": message
    }
    return this.http.post<any>(`${environment.API_HOST}/messages/${senderId}/sendnew`, data);
  }

  postContacts(entityId): Observable<any> {
    let data = {
      "search": "",
    }
    return this.http.post<any>(`${environment.API_HOST}/messages/${entityId}/getcontacts`, data);
  }

  postEntityRelation(entityId, data): Observable<any> {
    return this.http.post<any>(`${environment.API_HOST}/entity/entity-relations/`, data)
  }

  postEntityContact(data): Observable<any> {
    return this.http.post<any>(`${environment.API_HOST}/entity/entity-contact/`, data)
  }
}
