import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mobile-content',
  templateUrl: './mobile-content.component.html',
  styleUrls: ['./mobile-content.component.scss']
})
export class MobileContentComponent implements OnInit {
  currentLink;
  entityId;
  activeLink;
  articles;
  links = [
    {
      name: 'Articles',
      class: 'article-mobile-content'
    },
    {
      name: 'Contests',
      class: 'contest-mobile-content'
    },
    {
      name: 'Projects',
      class: 'project-mobile-content'
    }
  ];

  constructor(
    private service: DashboardService,
    private route: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.getEntityId()
    this.getActiveLink()
  }

  getEntityId(): any {
    this.route.parent.params.subscribe((params) => {
      this.entityId = params.id;
    });
  }

  getActiveLink(): any {
    this.route.firstChild?.url.subscribe(parentSegments => {
      this.activeLink = parentSegments[1].path
    });
  }
  // getArticles(): any {
  //   try {
  //     this.service
  //       .getArticles(this.entityId)
  //       .subscribe((resp) => (this.articles = resp.data));
  //     console.log('-------------- articles');
  //     console.log(this.articles);

  //   } catch (error) {
  //     console.log(error);

  //   }
  // }

}
