import { Component, Input, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/dashboard/_services/dashboard.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-general-social-block',
  templateUrl: './general-social-block.component.html',
  styleUrls: ['./general-social-block.component.scss']
})

export class GeneralSocialBlock implements OnInit {
  @Input() urlToShare: string = window.location.href;

  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;
  entityId = 1;
  shareForm: FormGroup;
  myNetwork;
  contestId;
  commentForm: FormGroup;
  comments: Array<any>;
  newsfeed;


  constructor(
    private router: Router,
    private dashService: DashboardService,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
  ) { 
    this.commentForm = fb.group({
      comment: ['']
    });

    this.shareForm = fb.group({
      mynetwork: [null]
    });
  }

  ngOnInit(): void {
  }

  likeUnlike(newsfeedx): any {
    let newsfeed = this.newsfeed
    console.log("NEWSFEED???", newsfeed)
    if (newsfeed.is_liked) {
      console.log("Unliking..")
      this.dashService
          .unlike(newsfeed.objectid, this.entityId)
          .subscribe(resp => {
            if(!resp?.error) {
              newsfeed.is_liked = !newsfeed.is_liked
              newsfeed.like_count--;
            }
          });      
    } else {
      console.log("Liking..")
      this.dashService
          .like(newsfeed.objectid, this.entityId)
          .subscribe(resp => {
            if(!resp?.error) {
              newsfeed.is_liked = !newsfeed.is_liked
              newsfeed.like_count++;
            }
          });
    }
  }

  getComments(id): any {
    this.dashService.getComments(this.contestId).subscribe(resp => {
      this.comments = resp.data;
    });
  }

  comment(id, trigger): any {
    trigger.closeMenu()
    this.dashService.postComment(this.contestId, this.commentForm.value).subscribe(resp => {
      this.snackbar.open('Comment Added!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
      this.commentForm.reset();
    }, () => {
      this.snackbar.open('Comment Failed!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    });
  }

  share(trigger): any {
    trigger.closeMenu()
    this.dashService.newsfeedShare(this.entityId, this.shareForm.value).subscribe(() => {
      this.trigger.toArray()[6].closeMenu();
      this.snackbar.open('Shared Successfully!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
      this.shareForm.reset();
    }, () => {
      this.snackbar.open('Sharing Failed!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    });
  }


}
