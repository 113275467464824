import { Component, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProfileService } from '../../_services/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { ExperienceFormComponent } from '../experience-form/experience-form.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DialogConfirmComponent } from '../../../common/dialog-confirm/dialog-confirm.component';
import { EducationFormComponent } from '../education-form/education-form.component';
import { QualificationFormComponent } from '../qualification-form/qualification-form.component';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarMessagesComponent } from 'src/app/common/snackbar-messages/snackbar-messages.component';

@Component({
  selector: 'app-mentor-entity',
  templateUrl: './mentor-entity.component.html',
  styleUrls: ['./mentor-entity.component.scss'],
  providers: [DialogConfirmComponent]
})

export class MentorEntityComponent implements OnInit {

  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  introForm: FormGroup;
  motivationForm: FormGroup;
  loading = false;
  watcher: Subscription;
  isMobile = false;
  snackbarMessages: SnackbarMessagesComponent
  experiences;
  educations;
  qualifications;

  constructor(private fb: FormBuilder,
    private service: ProfileService,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private deleteDialog: DialogConfirmComponent,
    private mediaObserver: MediaObserver,
  ) {
    this.snackbarMessages = new SnackbarMessagesComponent(snackbar, translate)

    this.introForm = this.fb.group({
      firstname: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      lastname: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      summary: ['', Validators.maxLength(700)],
    });

    this.motivationForm = this.fb.group({
      motivationSummary: ['', Validators.maxLength(700)],
    });

    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.isMobile = change.mqAlias == 'xs'
    })
  }

  ngOnInit(): void {
    this.getIntro();
    this.getExperience();
    this.getEducation();
    this.getQualification();
  }

  getIntro(): void {
    this.service.getUserIntro().subscribe(resp => this.introForm.patchValue(resp['data']['result'][0]));
  }

  getExperience(): void {
    this.service.getUserExperience().subscribe(resp => this.experiences = resp['data']['result']);
  }

  getEducation(): void {
    this.service.getUserEducation().subscribe(resp => this.educations = resp['data']['result']);
  }

  getQualification(): void {
    this.service.getUserQualification().subscribe(resp => this.qualifications = resp['data']['result']);
  }

  openExperienceForm(experience): any {
    this.dialog.open(ExperienceFormComponent, { width: '600px', data: experience, disableClose: true })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.hasOperations) {
          this.getExperience();
        }
      });
  }

  dropExperience(event: CdkDragDrop<string[]>): any {
    moveItemInArray(this.experiences, event.previousIndex, event.currentIndex);
    const sortedArray = [];
    for (let i = 0; i < this.experiences.length; i++) {
      sortedArray.push({ id: this.experiences[i]['eid'], seqnum: i });
    }
    this.service.sortUserExperience({ data: sortedArray }).subscribe(resp => this.getExperience());
  }

  deleteExperience(id): any {
    this.translate.get('profile.manage.talent.education.remove-modal.text').subscribe((message: string) => {
      this.deleteDialog.message = message
      this.deleteDialog.openDialog((value) => {
        if (value) {
          this.service.removeUserExperience(id)
            .subscribe(() => {
              this.getExperience();
            }, () => {
            });
        }
      });
    })
  }

  openEducationForm(education): any {
    this.dialog.open(EducationFormComponent, { width: '600px', data: education, disableClose: true })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.hasOperations) {
          this.getEducation();
        }
      });
  }

  dropEducation(event: CdkDragDrop<string[]>): any {
    moveItemInArray(this.educations, event.previousIndex, event.currentIndex);
    const sortedArray = [];
    for (let i = 0; i < this.educations.length; i++) {
      sortedArray.push({ id: this.educations[i]['eid'], seqnum: i });
    }
    this.service.sortUserEducation({ data: sortedArray }).subscribe(resp => this.getEducation());
  }

  deleteEducation(id): any {
    this.deleteDialog.message = 'Are you sure you want to remove this Education?';
    this.deleteDialog.openDialog((value) => {
      if (value) {
        this.service.removeUserEducation(id)
          .subscribe(() => {
            this.getEducation();
          }, () => {
          });
      }
    });
  }

  openQualificationForm(qualification): any {
    this.dialog.open(QualificationFormComponent, { width: '600px', data: qualification, disableClose: true })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.hasOperations) {
          this.getQualification();
        }
      });
  }

  dropQualification(event: CdkDragDrop<string[]>): any {
    moveItemInArray(this.qualifications, event.previousIndex, event.currentIndex);
    const sortedArray = [];
    for (let i = 0; i < this.qualifications.length; i++) {
      sortedArray.push({ id: this.qualifications[i]['qid'], seqnum: i });
    }
    this.service.sortUserQualification({ data: sortedArray }).subscribe(resp => this.getQualification());
  }

  deleteQualification(id): any {
    this.deleteDialog.message = 'Are you sure you want to remove this Qualification?';
    this.deleteDialog.openDialog((value) => {
      if (value) {
        this.service.removeUserQualification(id)
          .subscribe(() => {
            this.getQualification();
          }, () => {
          });
      }
    });
  }

  onSuccess(resp): any {
    this.snackbarMessages.onSuccess()
    this.router.navigate(['/dashboard']);
  }

  onError(error): any {
    this.snackbarMessages.onError()
    this.setServerErrors(error);
  }

  setServerErrors(error): any {
    error.data?.msg.forEach(obj => {
      this.introForm.get(obj.param).setErrors({ serverError: obj.msg });
    });
  }

  submitIntro(): void {
    if (this.introForm.valid) {
      this.service.postUserIntro(this.introForm.value).subscribe(resp => {
        console.log(resp);
      });
    }
  }

  create(): any {
    if (!this.experiences.length) {
      this.snackbar.open('You need to add at least one experience!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
      return;
    }

    if (!this.educations.length) {
      this.snackbar.open('You need to add at least one education!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
      return;
    }

    if (!this.qualifications.length) {
      this.snackbar.open('You need to add at least one qualification!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
      return;
    }

    this.service.createMentor({
      ...this.motivationForm.value,
      experiences: this.experiences.map(exp => exp.eid),
      educations: this.educations.map(edu => edu.eid),
      qualifications: this.qualifications.map(qua => qua.qid),
    }).subscribe((resp) => this.onSuccess(resp), (error) => this.onError(error));
  }

  goBack(): any {
    this.router.navigate(['/profile'])
  }

}
