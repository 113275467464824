import { Component, Injectable, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DashboardService } from '../../../dashboard/_services/dashboard.service';
import { TalentProjectsListComponent } from 'src/app/dashboard/_components/talent-projects-list/talent-projects-list.component';
import { TalentContestsListComponent } from 'src/app/dashboard/_components/talent-contests-list/talent-contests-list.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
})
@Injectable()
export class ProfileInfoComponent implements OnInit {
  @Input() userId
  profile;
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  skills;
  achievements;
  isMobile = false;
  entityId;
  profileStats
  activities
  // userId
  constructor(private dashService: DashboardService, private dialog: MatDialog) { }

  ngOnInit(): void {
    // this.getUserProfile();
    this.getActivities();
    this.getUserStats()

  }
  getUserStats(): any {
    this.dashService.getUserStats().subscribe((resp) => {
      this.profileStats = resp.stats
    })
  }

  getUserProfile(): any {
    this.dashService.getUserProfile().subscribe((resp) => {
      this.profile = resp.data;
      this.entityId = this.profile.entityid;
      this.userId = this.profile.userid
      this.getActivities()
    });
  }

  openProjectsList(): any {
    this.dialog.open(TalentProjectsListComponent, {
      width: '750px',
      height: '450px',
      panelClass: 'mentor-public-profile',
      data: {  }
    })
      .afterClosed()
      .subscribe(() => { });
  }

  openContestsList(): any {
    this.dialog.open(TalentContestsListComponent, {
      width: '750px',
      height: '450px',
      panelClass: 'mentor-public-profile',
      data: {  }
    })
      .afterClosed()
      .subscribe(() => { });
  }

  getActivities(): any {
    this.dashService.getMyActivities(this.userId).subscribe(resp => {
      const { data } = resp
      this.activities = data;
    });
  }
}
