import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-entity-project-view',
  templateUrl: './entity-project-view.component.html',
  styleUrls: ['./entity-project-view.component.scss']
})
export class EntityProjectViewComponent implements OnInit {
  @Input() project;

  myNetwork;
  sendForm: FormGroup;
  shareForm: FormGroup;
  commentForm: FormGroup;
  projectImgURL = `${environment.API_HOST}/files/profilepicture/`;

  constructor(
    private fb: FormBuilder,
    private router: Router

  ) {
    this.sendForm = fb.group({
      userid: [null],
    });

    this.shareForm = fb.group({
      mynetwork: [null]
    });

    this.commentForm = fb.group({
      comment: ['']
    });
  }

  ngOnInit(): void {
    console.log(`${this.projectImgURL}/ATBB2zGXsmznWchfXPGTWGaFSmaF0E592058`);
    
  }
  getProjectMainImage(imageId): any {
    return  `${this.projectImgURL}${imageId}`
  }
  keywordParser(project): any {
    return JSON.parse(project.keywords)
  }

  share(shareTrigger): any {

  }

  comment(item, postTrigger): any {

  }

  like(item): any {

  }

  editProject(): any {
    this.router.navigate([`entity/view-content/${this.project.ownerid}/project/${this.project.projectid}`])
  }

}
