import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-talent-contests-list',
  templateUrl: './talent-contests-list.component.html',
  styleUrls: ['./talent-contests-list.component.scss']
})
export class TalentContestsListComponent implements OnInit {
  contests: []
  constructor(private dashService: DashboardService, private router: Router) {}

  ngOnInit(): void {
    this.dashService.getTalentCotests().subscribe((resp) => {
      this.contests = resp.data
    })
  }

  goToContest(id, eid) {
    this.router.navigate(['contest/read', { id, entityId: eid }]).then();
  }
}
