import { Component, OnDestroy, OnInit, QueryList, ViewChildren, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../../project/_services/project.service';
import { ArticleService } from '../../../article/_services/article.service';
import { CommonService } from '../../../common/_services/common.service';
import { ContestService } from '../../../contest/_services/contest.service';
import { DashboardService } from '../../_services/dashboard.service';
import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScreenSizeService } from 'src/app/navigation/screen-size.service';
import { MatDialog } from '@angular/material/dialog';
import { TalentProjectsListComponent } from '../talent-projects-list/talent-projects-list.component';
import { TalentContestsListComponent } from '../talent-contests-list/talent-contests-list.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChildren('lastElement', { read: ElementRef })
  lastElement: QueryList<ElementRef>;
  observer: any;

  widgetActiveTab = 'leaderboard';
  events: string[] = [];
  opened: boolean;
  currentDate = new Date();
  profile;
  entityId;
  data;
  challengesCounts;
  projects;
  totalProjects;
  activeProjects = 0
  inprogressProjects = 0
  completeProjects = 0
  newsfeedCounts;
  newsfeedHomepage;
  skills;
  achievements;
  leaderboard;
  people = [];
  articleButton: boolean;
  contestButton: boolean;
  projectButton: boolean;
  profileStats;
  openProjects = []
  notStartedProjects = []

  activities;

  profileInterval;
  challengeInterval;
  newsfeedInterval;
  activityInterval;
  leaderboardInterval;
  createcontentInterval;

  numberNewsfeedPosts = 2;
  bottomNavActiveLink = 'newsfeed';
  bottomNavLinks = [
    {
      name: 'profile',
      class: 'profile-tab',
      icon: '',
      link: 'nav-tab/profile',
    },
    {
      name: 'newsfeed',
      class: 'newsfeed-tab',
      icon: '',
      link: 'nav-tab/newsfeed'
    },
    {
      name: 'challenges',
      class: 'challenges-tab',
      icon: '',
      link: 'nav-tab/challenges'
    }
  ];
  url = `${environment.API_HOST}/files/download/`;
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  sendForm: FormGroup;
  shareForm: FormGroup;
  commentForm: FormGroup;
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;


  watcher: Subscription;
  isMobile = false;


  constructor(private service: DashboardService,
    private screenSizeService: ScreenSizeService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private articleService: ArticleService,
    private contestService: ContestService,
    private projectService: ProjectService,
    private matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private mediaObserver: MediaObserver,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router) {
    this.commonService.changeData(false);

    this.sendForm = fb.group({
      userid: [null],
    });

    this.shareForm = fb.group({
      mynetwork: [null]
    });

    this.commentForm = fb.group({
      comment: ['']
    });

    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.isMobile = change.mqAlias == 'xs'
    })

    this.matIconRegistry.addSvgIcon("filter", domSanitizer.bypassSecurityTrustResourceUrl("/assets/filter.svg"))
    this.matIconRegistry.addSvgIcon("folder", domSanitizer.bypassSecurityTrustResourceUrl("/assets/folder.svg"))
    this.matIconRegistry.addSvgIcon("postSettings", domSanitizer.bypassSecurityTrustResourceUrl("/assets/post-settings.svg"))
    this.matIconRegistry.addSvgIcon("like", domSanitizer.bypassSecurityTrustResourceUrl("/assets/like.svg"))
    this.matIconRegistry.addSvgIcon("liked", domSanitizer.bypassSecurityTrustResourceUrl("/assets/liked.svg"))
    this.matIconRegistry.addSvgIcon("comment", domSanitizer.bypassSecurityTrustResourceUrl("/assets/comment.svg"))
    this.matIconRegistry.addSvgIcon("share", domSanitizer.bypassSecurityTrustResourceUrl("/assets/share.svg"))
    this.matIconRegistry.addSvgIcon("send", domSanitizer.bypassSecurityTrustResourceUrl("/assets/send.svg"))
    this.matIconRegistry.addSvgIcon("post-image", domSanitizer.bypassSecurityTrustResourceUrl("/assets/post-image.svg"))
  }





  ngOnDestroy(): void {
    clearInterval(this.activityInterval);
    clearInterval(this.newsfeedInterval);
    clearInterval(this.profileInterval);
    clearInterval(this.challengeInterval);
    clearInterval(this.leaderboardInterval);
    clearInterval(this.createcontentInterval);
  }

  ngOnInit(): void {
    this.getUserProfile();
    this.getButtons();
    this.getWidgets();
    this.getChallenges();
    this.getNewsfeed();
    this.getLeaderboard();
    this.getPeople();
    this.getAllProjects();
    this.getAvailableProjects()
    this.intersectionObserver();
    this.getUserStats()
    this.setBottomNavActiveLink()
    this.checkScreenSize()
    // this.getActivities();
  }

  ngAfterViewInit() {
    this.lastElement.changes.subscribe((d) => {
      if (d.last) this.observer.observe(d.last.nativeElement);
    });
  }

  checkScreenSize(): any {
    this.screenSizeService.getScreenWidth().subscribe(width => {
      if (width < 720 && this.profile?.entityid?.draft === 1) {
        // this.router.navigateByUrl(`/dashboard/nav-tab/${this.bottomNavActiveLink}`)
      } else if (width > 720 && this.profile?.entityid?.draft === 1) {
        // this.router.navigateByUrl('/dashboard')
      }
    })
  }

  setBottomNavActiveLink(): any {
    this.route.children[0]?.firstChild?.url.subscribe(vals => {
      this.bottomNavActiveLink = vals[1]?.path
    });
  }

  getUrl(id): any {
    return this.profilePictureUrl + id;
  }

  getUserStats(): any {
    this.service.getUserStats().subscribe((resp) => {
      console.log(resp);
      
      this.profileStats = resp.stats
    })
  }

  getWidgets(): any {
    const leftcolumn = document.getElementById('leftcolumn');
    const rightcolumn = document.getElementById('rightcolumn');
    const midcolumn = document.getElementById('mid-column');
    const widgets = {};
    // this.service.getWidgets().subscribe(resp => {
    //   this.data = resp.data;
    //   this.data.sort((a, b) => (a.screenorder > b.screenorder) ? 1 : -1);

    //   for (const obj of this.data) {
    //     widgets[obj.widgetname] = +obj.reloadrate;
    //     if (obj.screenorder <= 2) {
    //       const element = document.getElementById(obj.widgetname);
    //       element.style.display = 'block';
    //       leftcolumn.append(element);
    //     } else if (obj.screenorder > 2 && obj.screenorder < 5) {
    //       const element = document.getElementById(obj.widgetname);
    //       element.style.display = 'block';
    //       rightcolumn.append(element);
    //     } else if (obj.screenorder == 6) {
    //       const element = document.getElementById(obj.widgetname);
    //       element.style.display = 'block';
    //       midcolumn.append(element);
    //     }
    //   }

    //   if (widgets['challengesboard'] !== 0) {
    //     this.challengeInterval = setInterval(() => this.getChallenges(), widgets['challengesboard'] * 1000);
    //   }

    //   if (widgets['profile'] !== 0) {
    //     this.profileInterval = setInterval(() => this.getUserProfile(), widgets['profile'] * 1000);
    //   }

    //   if (widgets['newsfeed'] !== 0) {
    //     this.newsfeedInterval = setInterval(() => this.getNewsfeed(), widgets['newsfeed'] * 1000);
    //   }

    //   if (widgets['activities'] !== 0) {
    //     this.activityInterval = setInterval(() => this.getActivities(), widgets['activities'] * 1000);
    //   }

    //   if (widgets['leaderboard'] !== 0) {
    //     this.leaderboardInterval = setInterval(() => this.getLeaderboard(), widgets['leaderboard'] * 1000);
    //   }

    //   if (widgets['createcontent'] !== 0) {
    //     this.createcontentInterval = setInterval(() => this.getButtons(), widgets['createcontent'] * 1000);
    //   }
    // });
  }

  getUserProfile(): any {
    this.service.getUserProfile().subscribe(resp => {
      this.profile = resp.data;
      this.getActivities()
      const availableEntities = this?.profile?.entities.filter((e) => e?.eid !== this.profile.entityid.eid)

      if (availableEntities.length >= 1) {
        this.entityId = availableEntities[0]?.eid
        localStorage.setItem('currentEntity', this.entityId)
      } else {
        this.entityId = this.profile.entityid.eid;
      }

      if (this.profile.entityid.draft === -1) {
        this.router.navigate(['/entity/homepage/' + this.entityId]).then(() => {
          // location.reload()
        })
      }
    });
  }

  getAllProjects(): any {
    this.service.getAllProjects().subscribe(resp => {
      if (resp['data']?.length > 0) {
        this.totalProjects = resp['data']
        this.setProjectsInChallengesBoard()
      } else {
        this.totalProjects = 0
      }
    });
  }

  getAvailableProjects(): any {
    this.service.getAllAvailableProjects().subscribe(resp => {
      if (resp.data) {
        this.openProjects = resp.data.openApplications
        this.notStartedProjects = resp.data.notStartedProjects
      }
    })
  }
  getLeadProjectImage(id): any {
    return this.url + id;
  }
  readProject(id, eid, ownerid): any {
    this.router.navigate(['project/read', { id, entityId: eid, newsfeed: { entityid: ownerid } }]).then();
  }
  setProjectsInChallengesBoard(): any {
    this.setActiveProjects()
    this.setInprogressProjects()
    this.setCompleteProjects()
  }

  setActiveProjects(): any {
    if (this.totalProjects) {
      for (let i = 0; i < this.totalProjects.length; i++) {
        const project = this.totalProjects[i];
        const projectApplicationEndDate = new Date(project.enddate)
        if (projectApplicationEndDate <= this.currentDate) {
          this.activeProjects += 1
        }
      }
    }
  }

  setInprogressProjects(): any {
    if (this.totalProjects) {
      for (let i = 0; i < this.totalProjects.length; i++) {
        const project = this.totalProjects[i];
        const projectEndDate = new Date(project.enddateproj)
        if (projectEndDate >= this.currentDate) {
          this.inprogressProjects += 1
        }

      }

    }
  }

  setCompleteProjects(): any {
    if (this.totalProjects) {
      for (let i = 0; i < this.totalProjects.length; i++) {
        const project = this.totalProjects[i];
        const projectEndDate = new Date(project.enddateproj)
        if (this.currentDate >= projectEndDate) {
          this.completeProjects += 1
        }
      }

    }
  }


  getChallengesCounts(): any {
    this.service.getChallengesCounts().subscribe(resp => this.challengesCounts = resp['data'][0]);
  }

  getChallengesProjects(): any {
    this.service.getChallengesProjects().subscribe(resp => this.projects = resp['data']['projects']);
  }

  getChallenges(): any {
    this.getChallengesCounts();
    this.getChallengesProjects();
  }

  getNewsfeedCounts(): any {
    this.service.getNewsfeedCounts().subscribe(resp => this.newsfeedCounts = resp['data']);
  }




  intersectionObserver() {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.65,
    };

    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.numberNewsfeedPosts += 3;
        this.getNewsfeed();
      }
    }, options);
  }

  getNewsfeedHomepage(): any {
    this.service.getNewsfeedHomepage(this.numberNewsfeedPosts).subscribe(resp => {
      if (JSON.stringify(resp['data']['newsfeed']) !== JSON.stringify(this.newsfeedHomepage)) {
        this.newsfeedHomepage = resp['data']['newsfeed'];
      }
    });
  }

  getNewsfeed(): any {
    this.getNewsfeedCounts();
    this.getNewsfeedHomepage();
  }

  getActivities(): any {
    this.service.getMyActivities(this.profile.userid).subscribe(resp => {
      const { data } = resp
      this.activities = data;
    });
  }

  createArticle(): any {
    this.articleService.getDrafts(this.entityId)
      .subscribe(resp => {
        if (resp.data.length !== 0)
          this.router.navigate(['article/draft', { entityId: this.entityId }])

        else this.router.navigate(['article/form', { id: 0, entityId: this.entityId }]);
      })
  }



  createContest(): any {
    this.contestService.getDrafts(this.entityId).subscribe(resp => {
      if (resp.data.length !== 0)
        this.router.navigate(['contest/draft', { entityId: this.entityId }])
      else this.router.navigate(['contest/form', { id: 0, entityId: this.entityId }])

    });
  }

  createProject(): any {
    this.projectService.getDrafts(this.entityId).subscribe(resp => {
      if (resp.data.length !== 0)
        this.router.navigate(['project/draft', { entityId: this.entityId }])
      else this.router.navigate(['project/form', { id: 0, entityId: this.entityId }])

    });
  }

  getLeaderboard(): any {
    this.service.getLeaderboard().subscribe(resp => this.leaderboard = resp['data']['feeds']);
  }

  getPeople(): any {
    this.service.getPeople(this.entityId).subscribe(resp => this.people = resp.data);
  }

  getButtons(): any {
    this.service.getButtons().subscribe(resp => {
      this.articleButton = resp['data']['article'];
      this.contestButton = resp['data']['quest'];
      this.projectButton = resp['data']['project'];
    });
  }

  onSelectWidgetTab(value: string): any {
    this.widgetActiveTab = value;
  };

  openProjectsList(): any {
    this.dialog.open(TalentProjectsListComponent, {
      width: '750px',
      height: '450px',
      panelClass: 'mentor-public-profile',
      data: {  }
    })
      .afterClosed()
      .subscribe(() => { });
  }

  openContestsList(): any {
    this.dialog.open(TalentContestsListComponent, {
      width: '750px',
      height: '450px',
      panelClass: 'mentor-public-profile',
      data: {  }
    })
      .afterClosed()
      .subscribe(() => { });
  }


}

