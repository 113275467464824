import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../../_services/dashboard.service';
import { ProjectService } from 'src/app/project/_services/project.service';

@Component({
  selector: 'app-entity-view-project',
  templateUrl: './entity-view-project.component.html',
  styleUrls: ['./entity-view-project.component.scss']
})
export class EntityViewProjectComponent implements OnInit {

  project;
  entityId;
  projectId



  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private projectService: ProjectService,
  ) { }

  ngOnInit(): void {
    this.setIds()
  }

  setIds(): any {
    this.setProjectId()
    this.setEntityId()
    this.getContest()
  }

  setProjectId(): any {
    this.route.parent.params.subscribe(param => {
      this.entityId = param.entityId
    })
  }

  setEntityId(): any {
    this.route.params.subscribe(param => {
      this.projectId = param.projectId
    })
  }

  getContest(): any {
    this.projectService.getProject(this.entityId, this.projectId).subscribe(res => {
      if (res.error === false) {
        if (res.data.length > 0) {
          this.project = res.data[0]
        }
      }
    })
  }
}


  
