<mat-card *ngIf="!projects?.length">
    <mat-card-subtitle>
        {{'entity-dashboard.desktop-version.entity-content.projects-tab.no-content' | translate}}
    </mat-card-subtitle>
</mat-card>
<div *ngIf="projects" style="display: flex; flex-direction: column; gap: 1rem; cursor: pointer;"
    class="entity-desktop-project-wrapper">
    <mat-card mat-ripple *ngFor="let project of projects" (click)="editProject(project)">
        <mat-card-title>{{project?.title}}</mat-card-title>
        <!-- <mat-card-subtitle>{{ 'entity-dashboard.desktop-version.entity-content.categories.' + project?.category |
            translate }}</mat-card-subtitle> -->
        <mat-card-content>
            <i>
                <quill-view-html
                    [content]="project?.abstract?.length > 150 ? (project?.abstract | slice:0:150) + '...' : project?.abstract"></quill-view-html>
            </i>
            <div class="picture-description-container">
                <img *ngIf="project?.picture" mat-card-md-image
                    [src]="getLeadContestImage(project?.picture) | secure | async" alt="project image">
                <quill-view-html
                    [content]="project?.description?.length > 150 ? (project?.description | slice:0:150) + '...' : project?.description"></quill-view-html>
            </div>
        </mat-card-content>
        <mat-card-footer>
            <!-- <mat-card-subtitle>Start: {{project?.startdate}}</mat-card-subtitle>
            <mat-card-subtitle>End: {{project?.enddate}}</mat-card-subtitle> -->
        </mat-card-footer>
    </mat-card>
</div>