import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-small-content-cards',
  templateUrl: './small-content-cards.component.html',
  styleUrls: ['./small-content-cards.component.scss']
})
export class SmallContentCardsComponent implements OnInit {
  @Input() articles;
  @Input() contests;
  @Input() projects;

  constructor() { }


  ngOnInit(): void {
  }

}
