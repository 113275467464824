import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArticleService } from 'src/app/article/_services/article.service';
import { DashboardService } from '../../_services/dashboard.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-talent-mobile-newsfeed-tab',
  templateUrl: './talent-mobile-newsfeed-tab.component.html',
  styleUrls: ['./talent-mobile-newsfeed-tab.component.scss']
})
export class TalentMobileNewsfeedTabComponent implements OnInit {

  entityId;
  numberNewsfeedPosts = 3;
  newsfeedHomepage
  newsfeedCounts;

  constructor(
    private router: Router,
    private articleService: ArticleService,
    private dashboardService: DashboardService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon("folder", domSanitizer.bypassSecurityTrustResourceUrl("/assets/folder.svg"))
  }

  ngOnInit(): void {
    this.getNewsfeed()
    this.getLoggedProfile()
  }

  getLoggedProfile(): any {
    this.dashboardService.getUserProfile().subscribe(resp => {
      this.entityId = resp.data.entityid.eid
    })
  }


  createArticle(): any {
    this.articleService.getDrafts(this.entityId)
      .subscribe(resp => {
        if (resp.data.length !== 0)
          this.router.navigate(['article/draft', { entityId: this.entityId }])
        else this.router.navigate(['article/form', { id: 0, entityId: this.entityId }]);
      })
  }



  getNewsfeedHomepage(): any {
    this.dashboardService.getNewsfeedHomepage(this.numberNewsfeedPosts).subscribe(resp => {
      if (JSON.stringify(resp['data']['newsfeed']) !== JSON.stringify(this.newsfeedHomepage)) {
        this.newsfeedHomepage = resp['data']['newsfeed'];
      }
    });
  }

  getNewsfeedCounts(): any {
    this.dashboardService.getNewsfeedCounts().subscribe(resp => this.newsfeedCounts = resp['data']);
  }

  getNewsfeed(): any {
    this.getNewsfeedCounts();
    this.getNewsfeedHomepage();
  }

  onScroll(): any {
    this.numberNewsfeedPosts += 3; // Increment the number of newsfeed posts to fetch
    this.getNewsfeed(); // Fetch more newsfeed items
  }

}
