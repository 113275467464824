<div class="mobile-content-contest-container">
    <mat-card matRipple class="mobile-contest" (click)="editContest()">
        <mat-card-subtitle class="contest-main-subtitle">
            <span> {{contest?.ts}}</span>
            <div class="contest-category-container">
                <mat-icon> category</mat-icon>
                <span>
                    {{contest?.category}}
                </span>
            </div>
        </mat-card-subtitle>
        <mat-card-title>
            <p>{{contest?.title}} <mat-card-subtitle>{{contest?.visibility}}</mat-card-subtitle></p>
        </mat-card-title>
        <mat-card-content>
            <!-- <quill-view-html [content]="contest?.abstract"></quill-view-html> -->
            <quill-view-html
                [content]="contest?.abstract?.length > 150 ? (contest?.abstract | slice:0:150) + '...' : contest?.abstract"></quill-view-html>
        </mat-card-content>
        <img *ngIf="contest?.picture" mat-card-image [src]="getContestMainImage(contest.picture) | secure | async"
            alt="main contest image">
        <!-- <mat-card-content>{{contest?.content}}</mat-card-content> -->
        <div class="contest-keywords-container">
            <span class="contest-keyword" *ngFor="let keyword of keywordParser(contest)">
                {{keyword.keyword}}
            </span>
            <!-- <span class="contest-keyword" *ngFor="let keywordObject of keywordParser(contest.keywords)">
                {{keywordObject.keyword}}
            </span> -->
        </div>
        <div class="entity-contest-view-footer">
            <div class="like">
                <button mat-icon-button matTooltip="Like">
                    <mat-icon class="edit-button" svgIcon="like">
                    </mat-icon>
                    <!-- <mat-icon *ngIf="newsfeed?.entity.is_liked" class="edit-button" svgIcon="liked">
                    </mat-icon> -->
                </button>

                <mat-hint class="number-of-likes-hint">{{contest?.numLikes}}</mat-hint>
                <!-- <mat-hint class="liked-or-not-hint">&nbsp; {{newsfeed?.entity.is_liked? 'Liked': 'Like'}}</mat-hint> -->
            </div>

            <div class="comment">
                <button mat-icon-button matTooltip="Comment" [matMenuTriggerFor]="postComment"
                    #postTrigger="matMenuTrigger">
                    <mat-icon class="edit-button" svgIcon="comment"></mat-icon>
                </button>

                <mat-hint class="number-of-comments-hint">{{contest?.numComments }} </mat-hint>
                <mat-hint class="read-comments-hint" matTooltip="Read Comments" [matMenuTriggerFor]="readComments"
                    (click)="getComments(contest?.objectid)" #commentTrigger="matMenuTrigger">&nbsp;Comments
                </mat-hint>
            </div>

            <div class="share">
                <button mat-icon-button matTooltip="Share" [matMenuTriggerFor]="shareMenu"
                    #shareTrigger="matMenuTrigger">
                    <mat-icon class="edit-button" svgIcon="share"></mat-icon>
                </button>
                <mat-hint class="share-hint">Share</mat-hint>
            </div>

            <!-- <div class="send">
                <button mat-icon-button matTooltip="Send" [matMenuTriggerFor]="sendMenu" #sendTrigger="matMenuTrigger">
                    <mat-icon class="edit-button" svgIcon="send"></mat-icon>
                </button>
                <mat-hint class="send-hint">Send</mat-hint>
            </div> -->
        </div>


        <mat-menu #readComments="matMenu" class="custom-mat-menu">
            <div>
                <!-- <div *ngFor="let comment of comments" class="comment-unit">
                    <mat-hint>{{comment.ts | date: 'medium'}}</mat-hint>
                    <div>{{comment.comment}}</div>
                </div> -->
            </div>
        </mat-menu>

        <mat-menu #postComment="matMenu" class="custom-mat-menu">
            <form [formGroup]="commentForm" (click)="$event.stopPropagation()">
                <mat-form-field class="custom-form-field" appearance="outline">
                    <mat-label>Comment</mat-label>
                    <textarea matInput type="text" placeholder="Write a comment" formControlName="comment"></textarea>
                </mat-form-field>

                <button type="button" mat-stroked-button
                    (click)="comment(contest?.contestid,postTrigger)">Comment</button>
            </form>
        </mat-menu>

        <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
            <form [formGroup]="shareForm" (click)="$event.stopPropagation()">
                <p>Share to:</p>
                <div>
                    <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body"
                        bindValue="uuid" bindLabel="name" [clearable]="true" formControlName="mynetwork"
                        [notFoundText]="'No Results'" typeToSearchText="Search" placeholder="My Network">
                    </ng-select>

                    <button type="button" mat-stroked-button (click)="share(shareTrigger)">Share</button>
                </div>
            </form>
        </mat-menu>
    </mat-card>
</div>