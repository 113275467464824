import { Component, Inject, OnInit } from '@angular/core';
import { MONTHS } from '../../../common/const';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfileService } from '../../_services/profile.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-education-form',
  templateUrl: './education-form.component.html',
  styleUrls: ['./education-form.component.scss']
})


export class EducationFormComponent implements OnInit {

  months = MONTHS;
  years = [];
  educationForm: FormGroup;

  countries = [];
  filteredCountry: Observable<[]>;

  constructor(private fb: FormBuilder,
    private service: ProfileService,
    private dialogRef: MatDialogRef<EducationFormComponent>,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.educationForm = this.fb.group({
      eid: [''],
      sequential: [1],
      country: [''],
      entityname: [''],
      degree: [''],
      fieldofstudy: [''],
      fromyear: [null],
      toyear: [null],
      frommonth: [''],
      tomonth: [''],
      isexpected: [0]
    });


    if (data) {
      this.educationForm.patchValue(data);
    } else {
      this.educationForm.removeControl('eid');
    }
  }

  ngOnInit(): void {
    this.getCountries();
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 5;
    const startYear = 1950;

    this.years = Array.from({ length: endYear - startYear + 1 }, (_, index) => endYear - index);
  }

  getCountries(): any {
    this.service.getCountries().subscribe(resp => {
      this.countries = resp.data;
      //   this.filteredCountry = this.educationForm.controls['country'].valueChanges
      //     .pipe(
      //       startWith(''),
      //       map(value => typeof value === 'string' ? value : value.countryName),
      //       map(countryName => countryName ? this._filterCountries(countryName) : resp.data.slice())
      //     );
      // });
    })
  }

  _filterCountries(name: string): any {
    const filterValue = name.toLowerCase();
    return this.countries.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
  }

  submit(): void {
    if (this.educationForm.valid) {
      this.service.postUserEducation(this.educationForm.value).subscribe(resp => this.onSuccess(), error => this.onError(error));
    }
  }

  close(): any {
    this.dialogRef.close({ hasOperations: false });
  }

  onSuccess(): any {
    this.snackbar.open('Operation finished successfully!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
    this.dialogRef.close({ hasOperations: true });
  }

  onError(error): any {
    this.snackbar.open('Operation failed!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
  }

}
