import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-talent-mobile-challenges-tab',
  templateUrl: './talent-mobile-challenges-tab.component.html',
  styleUrls: ['./talent-mobile-challenges-tab.component.scss']
})
export class TalentMobileChallengesTabComponent implements OnInit {

  totalProjects;
  projects;
  completeProjects = 0
  inprogressProjects = 0;
  activeProjects = 0;
  currentDate = new Date();
  challengesCounts
  leadingPictureURL = `${environment.API_HOST}/files/profilepicture/`;
  openProjects = []
  notStartedProjects = []



  constructor(
    private dashboardService: DashboardService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAllProjects()
    this.getChallenges()
    this.getAvailableProjects()
  }

  setProjectsInChallengesBoard(): any {
    this.setActiveProjects()
    this.setInprogressProjects()
    this.setCompleteProjects()
  }
  setActiveProjects(): any {
    if (this.totalProjects) {
      for (let i = 0; i < this.totalProjects.length; i++) {
        const project = this.totalProjects[i];
        const [day, month, year] = project.enddateproj.split('/')
        const targetDate = new Date(`${year}-${month}-${day}`)
        if (targetDate > this.currentDate) {
          this.activeProjects += 1
        }
      }
    }
  }

  setInprogressProjects(): any {
    if (this.totalProjects) {
      for (let i = 0; i < this.totalProjects.length; i++) {
        const project = this.totalProjects[i];
        const [day, month, year] = project.enddateproj.split('/')
        const targetDate = new Date(`${year}-${month}-${day}`)
        if (targetDate >= this.currentDate) {
          this.inprogressProjects += 1
        }
      }
    }
  }

  setCompleteProjects(): any {
    if (this.totalProjects) {
      for (let i = 0; i < this.totalProjects.length; i++) {
        const project = this.totalProjects[i];
        const [day, month, year] = project.enddateproj.split('/')
        const targetDate = new Date(`${year}-${month}-${day}`)
        if (this.currentDate >= targetDate) {
          this.completeProjects += 1
        }
      }
    }
  }


  getAllProjects(): any {
    this.dashboardService.getAllProjects().subscribe(resp => {
      if (resp['data']?.length > 0) {
        this.totalProjects = resp['data']
        this.projects = resp.data
        this.setProjectsInChallengesBoard()
      } else {
        this.totalProjects = 0
      }
    });
  }

  getAvailableProjects(): any {
    this.dashboardService.getAllAvailableProjects().subscribe(resp => {
      if (resp.data) {      
        console.log(resp.data);
          
        this.openProjects = resp.data.openApplication
        this.notStartedProjects = resp.data.notStartedProjects
      }
    })
  }

  getLeadProjectImage(id): any {
    return this.leadingPictureURL + id;
  }

  getChallengesCounts(): any {
    this.dashboardService.getChallengesCounts().subscribe(resp => this.challengesCounts = resp['data'][0]);
  }

  getChallengesProjects(): any {
    // this.dashboardService.getChallengesProjects().subscribe(resp => this.projects = resp['data']['projects']);
  }

  getChallenges(): any {
    this.getChallengesCounts();
    this.getChallengesProjects();
  }

  readProject(id, eid, ownerid): any {
    this.router.navigate(['project/read', { id, entityId: eid, newsfeed: { entityid: ownerid } }]).then();
  }

}
