<div class="" *ngIf="!connections.length">
    <mat-card>
        <mat-card-subtitle>{{'entity-dashboard.mobile-version.bottom-nav.connect-tab.empty-message.text' |
            translate}}</mat-card-subtitle>
    </mat-card>
</div>

<div class="talent-card-spread-container">
    <div class="small-talent-card-wrapper" *ngFor="let connection of connections">
        <app-small-talent-card [connection]="connection"></app-small-talent-card>
    </div>
</div>