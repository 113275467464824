import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AuthService } from 'src/app/auth/_services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { DashboardService } from '../../_services/dashboard.service';
import { ProfileFormComponent } from 'src/app/profile/_components/profile-form/profile-form.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsService } from 'ngx-permissions';
import { COMPLETED_STATE } from 'src/app/common/const';
import { CommonService } from 'src/app/common/_services/common.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { GoogleAnalyticsService } from 'src/app/common/google-analytics.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BrowseCategoriesDialogComponent } from '../browse-categories-dialog/browse-categories-dialog.component';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { MailDialogComponent } from '../mail-dialog/mail-dialog.component';
import { ChallengesDialogComponent } from '../challenges-dialog/challenges-dialog.component';
import { environment } from 'src/environments/environment';
import { EntityHomepageComponent } from '../entity-homepage/entity-homepage.component';
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  myControl = new FormControl();
  categories = ['Contest', 'Project', 'Article', 'Entity', 'Mentor', 'Expert'];
  filteredOptions: Observable<string[]>;

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.categories.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  filterByCategory(category): any {
    let queryParams = {};
    queryParams['mainCategory'] = category; // Ensure 'category' is set to 'project' for your desired URL
    
    this.router.navigate(['/browse'], { queryParams: queryParams });
    // this.router.navigate(['/browse?mainCategory='+ category], { preserveFragment: true } );
  }

  onOptionSelected(event): any {
    this.filterByCategory(event.option.value);
  }

  today = new Date();
  nextWeek: Date = new Date();
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  pictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  isEntity = false;
  entityId;
  hasLogo = true;
  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private commonService: CommonService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private permissionsService: NgxPermissionsService,
    private overlay: Overlay
  ) { }

  @ViewChild('snav', { static: true }) snav: MatSidenav;
  opened: boolean;
  settingsOpened: boolean;
  notificationCount;
  notificationReloadRate = 100;
  messagesCount;
  messageReloadRate = 100;
  userName;
  profile;
  button_label = '';
  entityList = [];

  notificationsInterval;
  messagesInterval;
  challengesInterval;

  notificationResponse;
  messageResponse;
  challengesResponse;

  secondToolbar = false;

  clickoutHandler: Function;

  notificationDialogRef: MatDialogRef<NotificationDialogComponent>;
  mailDialogRef: MatDialogRef<MailDialogComponent>;
  challengesDialogRef: MatDialogRef<ChallengesDialogComponent>;
  browseDialogRef: MatDialogRef<BrowseCategoriesDialogComponent>;


  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.trackPageViews();
      }
    });

    let url = window.location.pathname;
    console.log(url);

    if (url.indexOf('entity/homepage') !== -1) {
      let entid = url.split('/');
      this.isEntity = true;
      this.entityId = entid[3];
    }
    this.dashboardService.getNotifications().subscribe((resp) => {
      this.notificationResponse = resp;
      this.notificationCount = resp['data']['notificationscount'];
      this.notificationReloadRate = resp['data']['reloadrate'];
      this.notificationsInterval = setInterval(
        () => this.getNotifications(),
        this.notificationReloadRate * 1000
      );
    });

    //TODO: once ready - to apply the same load/reload approach as notifications
    this.getMessages();
    this.messagesInterval = setInterval(
      () => this.getMessages(),
      this.messageReloadRate * 1000
    );

    //TODO: once ready - to apply the same load/reload approach as notifications, serverside - reload rate.
    this.getChallenges();
    this.challengesInterval = setInterval(() => this.getChallenges(), 250000);

    this.commonService.data$.subscribe((resp) => (this.isEntity = resp));
  }

  ngOnDestroy(): any {
    clearInterval(this.notificationsInterval);
    clearInterval(this.messagesInterval);
    clearInterval(this.challengesInterval);
  }

  activatedComponent(event): any {
    if (event instanceof EntityHomepageComponent) {
      this.getEntity(event.entityId);
    }
  }

  logout(): any {
    this.authService.logout();
    this.router.navigateByUrl('home');
    console.log('navigate to /home');

  }

  toggleSettings(): any {
    this.snav.toggle();
    this.settingsOpened = !this.opened;
  }

  toggle(): any {
    if (!this.settingsOpened) {
      this.snav.toggle();
    }
  }

  getUserName(trigger: MatMenuTrigger): any {
    trigger.closeMenu();
    this.isEntity = false;
    this.authService.getProfile().subscribe((resp) => {
      this.userName = resp.data.firstname;
      this.button_label = resp.data.firstname;
      this.pictureUrl = this.profilePictureUrl;
      this.hasLogo = true;
      this.entityId = resp.data.entityid;
      trigger.closeMenu();
      // this.router.navigate(['dashboard']);
    });
  }

  switchToEntity(id, trigger: MatMenuTrigger = null): any {
    trigger.closeMenu();
    this.getEntity(id);
    this.router.navigate(['entity/homepage/' + id]);
  }

  getEntity(id): any {
    this.isEntity = true;
    this.entityId = id;
    this.dashboardService.getEntity(id).subscribe((resp) => {
      this.userName = resp.data[0].orgname;
      this.button_label = resp.data[0].orgname;
      this.entityId = id;
      if (resp.data[0].logofileid) {
        this.pictureUrl = this.profilePictureUrl + resp.data[0].logofileid;
        this.hasLogo = true;
      } else {
        this.hasLogo = false;
        this.pictureUrl = '';
      }
    });
  }

  getNotifications(): any {
    this.dashboardService.getNotifications().subscribe((resp) => {
      this.notificationResponse = resp;
      this.notificationCount = resp['data']['notificationscount'];
      this.notificationReloadRate = resp['data']['reloadrate'];
    });
  }

  getMessages(): any {
    this.dashboardService.getMessages().subscribe((resp) => {
      this.messageResponse = resp;
      this.messagesCount = resp['data']['messagecount'];
      this.messageReloadRate = resp['data']['reloadrate'];
    });
  }

  getChallenges(): any {
    this.dashboardService.getChallenges().subscribe((resp) => {
      this.challengesResponse = resp;
    });
  }

  // openProfileForm(): any {
  //   this.dialog
  //     .open(ProfileFormComponent, { width: '85%', autoFocus: false })
  //     .afterClosed()
  //     .subscribe((result) => {
  //       if (result && result.hasOperations) {
  //         this.getProfile();
  //       }
  //     });
  // }

  openBrowseDialog(): any {
    setTimeout(() => {
      this.browseDialogRef = this.dialog.open(BrowseCategoriesDialogComponent, {
        width: '700px',
        height: '150px',
        hasBackdrop: false,
        panelClass: 'custom-dialog',
        position: { top: '105px', left: '22%' },
        autoFocus: false,
        data: { entityId: this.entityId },
      });

      this.browseDialogRef.afterOpened().subscribe(() => {
        this.clickoutHandler = this.closeBrowseDialogFromClickout;
      });

      this.browseDialogRef.afterClosed().subscribe(() => {
        this.clickoutHandler = null;
      });
    });
  }

  closeBrowseDialogFromClickout(event: MouseEvent): any {
    const browseMatDialogContainerEl =
      this.browseDialogRef.componentInstance.hostElement.nativeElement
        .parentElement;
    const rect = browseMatDialogContainerEl.getBoundingClientRect();
    if (
      event.clientX <= rect.left ||
      event.clientX >= rect.right ||
      event.clientY <= rect.top ||
      event.clientY >= rect.bottom
    ) {
      this.browseDialogRef.close();
    }
  }

  openMailDialogMobile(): any {
    this.dialog.open(MailDialogComponent, {
      width: '100%',
      maxWidth: '100%',
      data: this.messageResponse,
      backdropClass: 'backgroundColor:white',
      panelClass: 'mail-contacts-dialog-mobile',
      position: { top: '5%', right: '1%' },
      height: '80%',
      autoFocus: true,
    });
  }

  openMailDialog(): any {
    setTimeout(() => {
      this.mailDialogRef = this.dialog.open(MailDialogComponent, {
        data: { for_entity: this.entityId },
        hasBackdrop: false,
        panelClass: 'mail-contacts-dialog',
        position: { top: '5%', right: '1%' },
        width: '18%',
        height: '80%',
        autoFocus: true,
      });

      this.mailDialogRef.afterClosed().subscribe(() => {
        this.clickoutHandler = null;
      });
    });
  }

  closeMailDialogFromClickout(event: MouseEvent): any {
    const mailMatDialogContainerEl =
      this.mailDialogRef.componentInstance.hostElement.nativeElement
        .parentElement;
    const rect = mailMatDialogContainerEl.getBoundingClientRect();
    if (
      event.clientX <= rect.left ||
      event.clientX >= rect.right ||
      event.clientY <= rect.top ||
      event.clientY >= rect.bottom
    ) {
      this.mailDialogRef.close();
    }
  }

  openChallengesDialogMobile(): any {
    this.dialog.open(ChallengesDialogComponent, {
      width: '100%',
      maxWidth: '100%',
      data: { challenges: this.challengesResponse, entityId: this.entityId },
      backdropClass: 'backgroundColor:white',
      panelClass: 'custom-dialog',
      position: { top: '128px', left: '0px' },
      autoFocus: false,
      maxHeight: '70vh',
    });
  }

  closeChallengesDialogFromClickout(event: MouseEvent): any {
    const challengesMatDialogContainerEl =
      this.challengesDialogRef.componentInstance.hostElement.nativeElement
        .parentElement;
    const rect = challengesMatDialogContainerEl.getBoundingClientRect();
    if (
      event.clientX <= rect.left ||
      event.clientX >= rect.right ||
      event.clientY <= rect.top ||
      event.clientY >= rect.bottom
    ) {
      this.challengesDialogRef.close();
    }
  }

  openNotificationsDialog(): any {
    // setTimeout(() => {
      this.notificationDialogRef = this.dialog.open(
        NotificationDialogComponent,
        {
          width: '350px',
          data: this.notificationResponse,
          hasBackdrop: false,
          panelClass: 'custom-dialog',
          position: { top: '0', right: '0' },
          autoFocus: false,
        }
      );

      this.notificationDialogRef.afterOpened().subscribe(() => {
        this.clickoutHandler = this.closeNotificationDialogFromClickout;
      });

      this.notificationDialogRef.afterClosed().subscribe(() => {
        this.clickoutHandler = null;
      });
    // }, 100);
  }

  closeNotificationDialogFromClickout(event: MouseEvent): any {
    const notificationMatDialogContainerEl =
      this.notificationDialogRef.componentInstance.hostElement.nativeElement
        .parentElement;
    const rect1 = notificationMatDialogContainerEl.getBoundingClientRect();
    if (
      event.clientX <= rect1.left ||
      event.clientX >= rect1.right ||
      event.clientY <= rect1.top ||
      event.clientY >= rect1.bottom
    ) {
      this.notificationDialogRef.close();
    }
  }

  openNotificationsDialogMobile(): any {
    const dialog = this.dialog.open(NotificationDialogComponent, {
      width: '100%',
      maxWidth: '100%',
      data: this.notificationResponse,
      backdropClass: 'backgroundColor:white',
      panelClass: 'custom-dialog',
      position: { top: '128px', left: '0px' },
      autoFocus: false,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

}
