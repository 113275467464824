import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './error-page/error-page.component';
import { AuthGuard } from './auth/auth.guard';
import { NavigationComponent } from './navigation/navigation.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { IN_PROGRESS_STATE, COMPLETED_STATE, NEW_STATE } from './common/const';
import { OrganizationEntityComponent } from './profile/_components/organization-entity/organization-entity.component';
import { UserEntityComponent } from './profile/_components/user-entity/user-entity.component';
import { MentorEntityComponent } from './profile/_components/mentor-entity/mentor-entity.component';
import { ProfileFormComponent } from './profile/_components/profile-form/profile-form.component';
import { BrowseComponent } from './dashboard/_components/browse/browse.component';
import { DashboardComponent } from './dashboard/_components/dashboard/dashboard.component';
import { MessagingComponent } from './messaging/messaging.component';
import { DashboardModule } from './dashboard/dashboard.module';


const routes: Routes = [
  // { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'usrs-for-hacking', loadChildren: () => import('./usrs-common/usrs.module').then(m => m.UsrsRoutingModule) },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  // { path: 'error-page', component: ErrorPageComponent },
  // { path: 'navigation', component: NavigationComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
  {
    path: '',
    component: NavigationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard', loadChildren: () => DashboardModule,
        // canActivate: [NgxPermissionsGuard],
        // canActivateChild: [NgxPermissionsGuard],
        // data: {
        //   permissions: {
        //     only: COMPLETED_STATE,
        //     redirectTo: {
        //       navigationCommands: ['home'],
        //     }
        //   }
        // }
      },
      {
        path: 'entity', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),

        // canActivate: [NgxPermissionsGuard],
        // canActivateChild: [NgxPermissionsGuard],
        // data: {
        //   permissions: {
        //     only: COMPLETED_STATE,
        //     redirectTo: {
        //       navigationCommands: ['navigation'],
        //     }
        //   }
        // }
      },
      {
        path: 'newsfeed', loadChildren: () => import('./dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule),
      },
      {
        path: 'article', loadChildren: () => import('./article/article.module').then(m => m.ArticleModule),
        // canActivate: [NgxPermissionsGuard],
        // canActivateChild: [NgxPermissionsGuard],
        // data: {
        //   permissions: {
        //     only: COMPLETED_STATE,
        //     redirectTo: {
        //       navigationCommands: ['navigation'],
        //     }
        //   }
        // }
      },
      {
        path: 'contest', loadChildren: () => import('./contest/contest.module').then(m => m.ContestModule),
        // canActivate: [NgxPermissionsGuard],
        // canActivateChild: [NgxPermissionsGuard],
        // data: {
        //   permissions: {
        //     only: COMPLETED_STATE,
        //     redirectTo: {
        //       navigationCommands: ['navigation'],
        //     }
        //   }
        // }
      },
      {
        path: 'project', loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
        // canActivate: [NgxPermissionsGuard],
        // canActivateChild: [NgxPermissionsGuard],
        // data: {
        //   permissions: {
        //     only: COMPLETED_STATE,
        //     redirectTo: {
        //       navigationCommands: ['navigation'],
        //     }
        //   }
        // }
      },
      {
        path: 'organization', component: OrganizationEntityComponent,
        // data: {
        //   permissions: {
        //     only: IN_PROGRESS_STATE,
        //     redirectTo: {
        //       navigationCommands: ['navigation'],
        //     }
        //   }
        // }
      },
      {
        path: 'expert', component: UserEntityComponent,
        // data: {
        //   permissions: {
        //     only: IN_PROGRESS_STATE,
        //     redirectTo: {
        //       navigationCommands: ['navigation'],
        //     }
        //   }
        // }
      },
      {
        path: 'mentor', component: MentorEntityComponent,
        // data: {
        //   permissions: {
        //     only: IN_PROGRESS_STATE,
        //     redirectTo: {
        //       navigationCommands: ['navigation'],
        //     }
        //   }
        // }
      },
      {
        path: 'profile', component: ProfileFormComponent,
        // canActivate: [NgxPermissionsGuard],
        // canActivateChild: [NgxPermissionsGuard],
        // data: {
        //   permissions: {
        //     only: COMPLETED_STATE,
        //     redirectTo: {
        //       navigationCommands: ['navigation'],
        //     }
        //   }
        // }
      },
      {
        path: 'browse', component: BrowseComponent,
        // canActivate: [NgxPermissionsGuard],
        // canActivateChild: [NgxPermissionsGuard],
        // data: {
        //   permissions: {
        //     only: COMPLETED_STATE,
        //     redirectTo: {
        //       navigationCommands: ['navigation'],
        //     }
        //   }
        // }
      },
      {
        path: 'message', component: MessagingComponent,
        // canActivate: [NgxPermissionsGuard],
        // canActivateChild: [NgxPermissionsGuard],
        // data: {
        //   permissions: {
        //     only: COMPLETED_STATE,
        //     redirectTo: {
        //       navigationCommands: ['navigation'],
        //     }
        //   }
        // }
      }
    ],
    // canLoad: [AuthGuard],
    // canActivate: [AuthGuard]
  },
  // { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy', anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled', scrollOffset: [0, 32]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
