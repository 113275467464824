<div class="container">


  <div class="card">

    <div class="card-grid-actions">

      <div class="action-column">
        <button mat-stroked-button class="action-btn" (click)="goBack()">
          <mat-icon>arrow_back</mat-icon>
          <span>Go Back</span>
        </button>
      </div>

      <div class="action-column">
        <button mat-stroked-button class="action-btn" (click)="select(0)">
          <mat-icon>add</mat-icon>
          <span>Start new Article</span>
        </button>
      </div>

    </div>

    <!-- <mat-selection-list [multiple]="false">
      <mat-list-option (click)="select(0)">
        <mat-icon mat-list-icon>add</mat-icon>
        <div mat-line>Start new Article</div>
      </mat-list-option>
    </mat-selection-list> -->

    <h2 class="draft-header">Drafts availabe but not published:</h2>

    <div fxLayout="column">

      <div fxLayout="row" fxLayoutAlign="center" *ngFor="let draft of drafts" fxFlexFill>

        <div fxLayout="wrap" fxLayoutAlign="start center" fxLayoutGap="20px" fxFlex="45" fxFlex="100%" class="draft-row"
          (click)="select(draft.draftid)">
          <div>
            <mat-icon>edit</mat-icon>
          </div>

          <div fxLayout="column">
            <h3 style="margin: 0">{{draft.title}}</h3>
            <div>{{draft.ts | date: 'medium'}}</div>
          </div>
        </div>

        <div class="draft-row" (click)="deleteDraft(draft.draftid)">
          <button mat-icon-button disableRipple>
            <mat-icon>delete</mat-icon>
          </button>
        </div>

      </div> <!-- row -->

    </div>

  </div>

</div>
