import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-entity-article-view',
  templateUrl: './entity-article-view.component.html',
  styleUrls: ['./entity-article-view.component.scss']
})
export class EntityArticleViewComponent implements OnInit {
  @Input() article;

  myNetwork;
  sendForm: FormGroup;
  shareForm: FormGroup;
  commentForm: FormGroup;
  articleImgURL = `${environment.API_HOST}/files/profilepicture/`;

  constructor(
    private fb: FormBuilder,
    private router: Router,

  ) {
    this.sendForm = fb.group({
      userid: [null],
    });

    this.shareForm = fb.group({
      mynetwork: [null]
    });

    this.commentForm = fb.group({
      comment: ['']
    });
  }

  ngOnInit(): void {
  }

  getArticleMainImage(article): any {
    return `${this.articleImgURL}${article}`
  }

  keywordParser(article): any {
    return article.keywords
    // return JSON.parse(keywords)
  }

  share(shareTrigger): any {

  }

  logProps(): any {
    console.log(this.article);

  }

  comment(item, postTrigger): any {

  }

  like(item): any {

  }

  getComments(item): any {

  }

  editArticle(): any {
    this.router.navigate([`entity/view-content/${this.article.ownerid}/article/${this.article.articleid}`])
  }
}
