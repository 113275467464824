<div class="social-info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div class="main-actions-wrapper">
        <div class="like-comment-share">
            <div class="like">
                <button mat-icon-button matTooltip="Like" [matTooltip]="content?.is_liked ? 'Unlike' : 'Like'"
                    (click)="likeUnlike(content)">
                    <mat-icon *ngIf="!content?.is_liked" class="edit-button" svgIcon="like">
                    </mat-icon>
                    <mat-icon *ngIf="content?.is_liked" class="edit-button" svgIcon="liked">
                    </mat-icon>
                </button>

                <mat-hint>&nbsp; {{ content?.numlikes }}
                    {{ content?.is_liked ? "Liked" : "Like" }}</mat-hint>
            </div>

            <div class="comment">
                <button mat-icon-button matTooltip="Comment" [matMenuTriggerFor]="postComment"
                    #postTrigger="matMenuTrigger">
                    <mat-icon class="edit-button" svgIcon="comment"></mat-icon>
                </button>

                {{ content?.numcomments }}
                <!-- <mat-hint matTooltip="Read Comments" [matMenuTriggerFor]="readComments" (click)="getComments(content)"
                    #commentTrigger="matMenuTrigger">&nbsp;Comments
                </mat-hint> -->
            </div>

            <div class="share">
                <button mat-icon-button matTooltip="Share" [matMenuTriggerFor]="shareMenu"
                    #shareTrigger="matMenuTrigger">
                    <mat-icon class="edit-button" svgIcon="share"></mat-icon>
                </button>
                <mat-hint>Share</mat-hint>
            </div>
        </div>
        <div class="apply-edit-manage">
            <div *ngIf="!isOwner && isOpenForAppl" class="footer">
                <button mat-stroked-button class="action-btn search-bar-button" (click)="applyButtonContest()">
                    <span>Apply</span>
                </button>
            </div>

            <div *ngIf="isOwner" class="footer-controls">
                <button mat-stroked-button (click)="manageButtonContest()">
                    <mat-icon class="edit-button">people</mat-icon>
                </button>

                <button mat-stroked-button (click)="editButtonContest()">
                    <mat-icon class="edit-button">edit</mat-icon>
                </button>

                <button mat-stroked-button (click)="deleteButtonContest()">
                    <mat-icon class="delete-button">delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<mat-menu #readComments="matMenu" class="custom-mat-menu">
    <div style="padding: 15px 20px 0 20px">
        <div *ngFor="let comment of comments" class="comment-unit">
            <mat-hint>{{comment.ts | date: 'medium'}}</mat-hint>
            <div>{{comment.comment}}</div>
        </div>
    </div>
</mat-menu>

<mat-menu #postComment="matMenu" class="custom-mat-menu">
    <form [formGroup]="commentForm" style="padding: 0 20px" fxLayout="column" (click)="$event.stopPropagation()"
        fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-form-field class="custom-form-field" appearance="outline">
            <mat-label>Comment</mat-label>
            <textarea matInput type="text" placeholder="Write a comment" formControlName="comment"></textarea>
        </mat-form-field>

        <button type="button" mat-stroked-button (click)="comment(content)">Comment</button>
    </form>
</mat-menu>

<mat-menu #shareMenu="matMenu" class="custom-mat-menu">
    <form [formGroup]="shareForm" style="padding: 0 20px" (click)="$event.stopPropagation()">
        <p>Share to:</p>
        <div fxLayout="column" fxLayoutAlign="start center">
            <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body"
                bindValue="uuid" bindLabel="name" [clearable]="true" formControlName="mynetwork"
                [notFoundText]="'No Results'" typeToSearchText="Search" placeholder="My Network">
            </ng-select>

            <button type="button" mat-stroked-button (click)="share(shareTrigger)">Share</button>
        </div>
    </form>
</mat-menu>