<div class="container">
  <mat-horizontal-stepper #stepper [@.disabled]="true">
    <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">

      <mat-step [label]="'profile.manage.entity.type.tab' | translate" [completed]="form.controls['orgtype'].value">
        <mat-error *ngIf="form.controls['orgtype'].invalid">
          {{'profile.manage.entity.type.error' | translate}}
        </mat-error>
        <div class="info-organization-container">
          <mat-radio-group class="organization-type" aria-label="Select an option" formControlName="orgtype" required
            fxLayout="column" fxLayoutGap="20px">
            <mat-radio-button *ngFor="let type of orgTypes" [value]="type.name">
              {{'profile.manage.entity.type.'+type.key | translate}}</mat-radio-button>
          </mat-radio-group>
          <div class="btn-container">
            <button type="button" matStepperNext mat-raised-button>
              {{'profile.manage.entity.type.buttons.next' | translate}} <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step [label]="'profile.manage.entity.identity.tab' | translate">
        <div class="info-organization-container">
          <mat-form-field appearance="outline">
            <mat-label>{{'profile.manage.entity.identity.name.label' | translate}}</mat-label>
            <input matInput [placeholder]="'profile.manage.entity.identity.name.placeholder' | translate"
              formControlName="orgname">
            <mat-error *ngIf="form.controls['orgname'].hasError('serverError')">
              {{form.controls['orgname'].getError('serverError')}}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'profile.manage.entity.identity.website.label' | translate}}</mat-label>
            <input matInput [placeholder]="'profile.manage.entity.identity.website.placeholder' | translate"
              formControlName="website">
            <mat-error *ngIf="form.controls['website'].hasError('serverError')">
              {{form.controls['website'].getError('serverError')}}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="info-organization-container" style="margin-top: 0;">
          <mat-form-field appearance="outline">
            <mat-label>{{'profile.manage.entity.identity.headline.label' | translate}}</mat-label>
            <input matInput [placeholder]="'profile.manage.entity.identity.headline.placeholder' | translate"
              formControlName="headline">
            <mat-error *ngIf="form.controls['headline'].hasError('serverError')">
              {{form.controls['headline'].getError('serverError')}}
            </mat-error>
          </mat-form-field>
          <div class="info-organization-container" style="margin-top: 0;">
            <mat-form-field appearance="outline">
              <mat-label>{{'profile.manage.entity.identity.industry.label' | translate}}</mat-label>
              <input type="text" [placeholder]="'profile.manage.entity.identity.industry.placeholder' | translate"
                matInput formControlName="industry" [matAutocomplete]="auto" required>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let industry of industries | async" [value]="'profile.manage.entity.identity.industry.'+industry.key | translate">
                  {{'profile.manage.entity.identity.industry.'+industry.key | translate}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="form.controls['industry'].hasError('serverError')">
                {{form.controls['industry'].getError('serverError')}}
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline" *ngIf="isEducational">
              <mat-label>Academic Disciplines</mat-label>
              <input type="text" placeholder="Search for academic discipline" matInput
                formControlName="academicdiscipline" [matAutocomplete]="auto" required>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let discipline of disciplines | async" [value]="discipline">
                  {{discipline}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="form.controls['academicdiscipline'].hasError('serverError')">
                {{form.controls['academicdiscipline'].getError('serverError')}}
              </mat-error>
            </mat-form-field> -->

            <mat-form-field appearance="outline">
              <mat-label>{{'profile.manage.entity.identity.legal.label' | translate}}</mat-label>
              <mat-select formControlName="legaltype" required>
                <mat-option *ngFor="let type of orgLegalTypes" [value]="type.name">
                  {{'profile.manage.entity.identity.legal.'+type.key | translate}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['legaltype'].hasError('serverError')">
                {{form.controls['legaltype'].getError('serverError')}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="info-organization-container" style="margin-top: 0;">
          <mat-form-field appearance="outline">
            <mat-label>{{'profile.manage.entity.identity.description.label' | translate}}</mat-label>
            <textarea matInput [placeholder]="'profile.manage.entity.identity.description.placeholder' | translate"
              formControlName="description" style="height: 100px;"></textarea>
            <mat-error *ngIf="form.controls['description'].hasError('serverError')">
              {{form.controls['description'].getError('serverError')}}
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngFor="let i of list" class="logo-container-organization">
          <img [src]="getUrl() | secure | async" style="max-width: 20%">
        </div>

        <div class="img-upload-btn-container">
          <input #input1 class="hidden-input" type="file" accept="image/*" (change)="handleFileInput($event)">
          <button type="button" mat-flat-button (click)="input1.click()" [class.spinner]="loading" style="padding: 0;">
            <mat-icon>insert_photo</mat-icon>
            {{!isMobile? ('profile.manage.entity.identity.image.label' | translate) : ''}}
          </button>
        </div>

        <div class="footer-button">
          <button mat-raised-button class="backward-button" matStepperPrevious
            type="button"><mat-icon>arrow_backward</mat-icon>{{'profile.manage.entity.identity.buttons.back' |
            translate}}</button>
          <button *ngIf="!isUpdate" class="add-btn" mat-raised-button (click)="submit()" matStepperNext
            type="submit">{{'profile.manage.entity.identity.buttons.save' | translate}}</button>
          <button *ngIf="isUpdate" class="add-btn" mat-raised-button (click)="updateEnity()" matStepperNext
            type="submit">{{'profile.manage.entity.identity.buttons.update' | translate}}</button>
        </div>
      </mat-step>
    </form>
  </mat-horizontal-stepper>
</div>