<div class="container">
  <div class="grid-container">
    <div class="desktop-container">
      <div class="left-sidebar" id="left-sidebar">
        <div class="profile" id="profile">
          <div class="profile-wrapper">
            <div class="profile-pic-and-info">
              <div class="profile-pic">
                <img class="image" [src]="profilePictureUrl | secure | async" />
              </div>
              <div class="profile-info">
                <div class="name">
                  {{ profile?.firstname }} {{ profile?.lastname }}
                </div>
                <div class="designation">{{ profile?.headline }}</div>
              </div>
            </div>
            <div class="profile-status">
              <div class="profile-status-header">
                <div class="profile-status-icon-wrapper">
                  <mat-icon class="profile-status-icon">assessment</mat-icon>
                </div>
                <span>{{'dashboard.left-column.profile-stats.title' | translate}}</span>
              </div>
              <div (click)="openProjectsList()" class="project-item">
                <label class="project">{{'dashboard.left-column.profile-stats.project-count' | translate}}</label>
                <label class="count">{{profileStats?.projects}}</label>
              </div>
              <div (click)="openContestsList()" class="project-item">
                <label class="project">{{'dashboard.left-column.profile-stats.contest-count' | translate}}</label>
                <label class="count">{{profileStats?.contests}}</label>
              </div>
            </div>

          </div>
          <!-- Activities from >= 720 width starts here -->
          <app-talent-activities [activities]="activities"></app-talent-activities>
        </div>
      </div>
      <div class="main-content-container" id="main-content-container">
        <mat-card class="create-content">
          <mat-card-title class="create-content-header">{{'dashboard.middle-column.create-content.title' |
            translate}}</mat-card-title>
          <mat-card-actions class="create-content-button-container">
            <button mat-flat-button class="create-article-button" (click)="createArticle()">
              <mat-icon class="create-article-button-icon">library_books</mat-icon>
              {{'dashboard.middle-column.create-content.create-article-button' |
              translate}}
            </button>
          </mat-card-actions>
        </mat-card>
        <div class="newsfeed-main-container">
          <div class="newsfeed-cards-container" *ngFor="let newsfeed of newsfeedHomepage" #lastElement>
            <app-newsfeed-card [newsfeed]="newsfeed" [entityId]="this.entityId"></app-newsfeed-card>
          </div>
        </div>
      </div>
      <div class="right-sidebar" id="right-sidebar">
        <div class="challengesboard" id="challengesboard">
          <div class="board-status">
            <div class="challengeboard-header">
              <span class="label"> {{'dashboard.right-column.challenges.title' |
                translate}}</span>
              <mat-icon>stars</mat-icon>
              <button class="filter-button" mat-icon-button>
                <mat-icon svgIcon="filter"></mat-icon>
              </button>
            </div>
            <div class="open-projects-container">
              <span class="open-projects">{{'dashboard.right-column.project-stats.total' |
                translate}}</span>
              <span class="open-count">{{ totalProjects?.length ? totalProjects?.length : 0 }}</span>
            </div>
            <div class="project-spread-container">
              <div class="project-status active-project" [matTooltip]="'dashboard.right-column.project-stats.active.tooltip' |
              translate">
                <span class="project-icon" aria-hidden="true"><mat-icon style="color: #ff754c;">star</mat-icon></span>
                <h3 class="count">{{ activeProjects }}</h3>
              </div>
              <div class="project-status in-progress" [matTooltip]="'dashboard.right-column.project-stats.in-progress.tooltip' |
              translate">
                <span class="project-icon" aria-hidden="true"><mat-icon
                    style="color: #00b7ff;">access_time</mat-icon></span>
                <h3 class="count">{{ inprogressProjects }}</h3>
              </div>
              <div class="project-status complete" [matTooltip]="'dashboard.right-column.project-stats.complete.tooltip' |
              translate">
                <span class="project-icon" aria-hidden="true"><mat-icon
                    style="color: #008d91;">check_circle</mat-icon></span>
                <h3 class="count">{{ completeProjects }}</h3>
              </div>
            </div>
          </div>
          <div class="available-projects-container">
            <h4>{{'dashboard.right-column.available-projects.title' | translate}}</h4>
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="{{'dashboard.right-column.available-projects.apply.tab' | translate}}">
                <div *ngIf="openProjects" class="available-projects-cards">
                  <mat-card mat-ripple *ngFor="let project of openProjects"
                    (click)="readProject(project.projectid, project.userid, project.ownerid)">
                    <div class="mat-card-header">
                      <mat-card-title>{{project?.title}}
                      </mat-card-title>
                      <mat-card-subtitle>
                        Application deadline: {{project?.enddate}}
                      </mat-card-subtitle>
                    </div>
                    <mat-card-subtitle>{{project?.maincategory}}</mat-card-subtitle>
                    <mat-card-content>
                      <i>
                        <quill-view-html
                          [content]="project?.abstract?.length > 150 ? (project?.abstract | slice:0:150) + '...' : project?.abstract"></quill-view-html>
                      </i>
                      <div class="picture-description-container">
                        <img *ngIf="project?.leadingimage" mat-card-md-image
                          [src]="getLeadProjectImage(project?.leadingimage) | secure | async" alt="project image">
                        <quill-view-html
                          [content]="project?.description?.length > 150 ? (project?.description | slice:0:150) + '...' : project?.description"></quill-view-html>
                      </div>
                    </mat-card-content>
                    <mat-card-footer>
                      <!-- <mat-card-subtitle>Start: {{project?.startdate}}</mat-card-subtitle>
                              <mat-card-subtitle>End: {{project?.enddate}}</mat-card-subtitle> -->

                      <div class="keyword-container" fxLayout="row wrap" fxLayoutGap="10px">
                        <p *ngFor="let keyword of project?.keywords" class="keyword-wrap">
                          {{ keyword?.keyword }}
                        </p>
                      </div>
                    </mat-card-footer>
                  </mat-card>
                </div>
                <div *ngIf="!openProjects" class="">
                  <mat-card class="">
                    <mat-card-subtitle>
                      {{'dashboard.right-column.available-projects.apply.tab-message' | translate}}
                    </mat-card-subtitle>
                  </mat-card>
                </div>
              </mat-tab>
              <mat-tab label="{{'dashboard.right-column.available-projects.track.tab' | translate}}">
                <div *ngIf="notStartedProjects" class="available-projects-cards">
                  <mat-card mat-ripple *ngFor="let project of notStartedProjects"
                    (click)="readProject(project.projectid, project.userid, project.ownerid)">
                    <div class="mat-card-header">
                      <mat-card-title>{{project?.title}}
                      </mat-card-title>
                      <mat-card-subtitle>
                        Application deadline: {{project?.enddate}}
                      </mat-card-subtitle>
                    </div>
                    <mat-card-subtitle>{{project?.maincategory}}</mat-card-subtitle>
                    <mat-card-content>
                      <i>
                        <quill-view-html
                          [content]="project?.abstract?.length > 150 ? (project?.abstract | slice:0:150) + '...' : project?.abstract"></quill-view-html>
                      </i>
                      <div class="picture-description-container">
                        <img *ngIf="project?.leadingimage" mat-card-md-image
                          [src]="getLeadProjectImage(project?.leadingimage) | secure | async" alt="project image">
                        <quill-view-html
                          [content]="project?.description?.length > 150 ? (project?.description | slice:0:150) + '...' : project?.description"></quill-view-html>
                      </div>
                    </mat-card-content>
                    <mat-card-footer>
                      <!-- <mat-card-subtitle>Start: {{project?.startdate}}</mat-card-subtitle>
                              <mat-card-subtitle>End: {{project?.enddate}}</mat-card-subtitle> -->

                      <div class="keyword-container" fxLayout="row wrap" fxLayoutGap="10px">
                        <p *ngFor="let keyword of project?.keywords" class="keyword-wrap">
                          {{ keyword?.keyword }}
                        </p>
                      </div>
                    </mat-card-footer>
                  </mat-card>
                </div>

                <div *ngIf="!notStartedProjects" class="">
                  <mat-card class="">
                    <mat-card-subtitle>
                      {{'dashboard.right-column.available-projects.track.tab-message' | translate}}
                    </mat-card-subtitle>
                  </mat-card>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <!-- <mat-list class="project-list-container">
          <mat-list-item class="project-list-item" *ngFor="let project of projects">
            <div class="">
              <mat-icon mat-list-icon svgIcon="folder"></mat-icon>
            </div>
            <span class="project-list-item-timestamp" mat-line>{{ project.ts }}</span>
            <div class="project-list-item-text" mat-line>{{ project.showtext }}</div>
            <button mat-icon-button>
              <mat-icon>chevron_right</mat-icon>
            </button>
          </mat-list-item>
        </mat-list> -->
        </div>
      </div>
    </div>
    <div class="bottom-nav">
      <nav mat-tab-nav-bar class="bottom-nav-button-container">
        <a mat-tab-link [disableRipple]="true" *ngFor="let bottomNavLink of bottomNavLinks"
          (click)="bottomNavActiveLink = bottomNavLink.name" [active]="bottomNavActiveLink == bottomNavLink.name"
          [ngClass]="bottomNavLink.class" [routerLink]=" bottomNavLink.link" routerLinkActive="active">
          {{'dashboard.bottom-nav.'+bottomNavLink.name | translate}}
        </a>
      </nav>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>