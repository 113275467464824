<div class="container">
  <mat-toolbar class="nav-bar-tool-bar">
    <div style="width: unset;">
      <img alt="icon" src="../../assets/logo-final.svg" (click)="getUserName(menuTrigger)" class="logo"
        routerLink="dashboard" />
    </div>


    <!-- <form>
      <div class="search-input-container">
        <div class="search-icon-container">
          <mat-icon matPrefix svgIcon="search" class="search-icon"></mat-icon>
        </div>
      </div>
    </form>
    -->
    <form action="" class="search-form">
      <mat-form-field class="example-full-width">
        <span matPrefix> </span>
        <input type="text" placeholder="Search by category" aria-label="Search by category" matInput
          [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete class="autocomplete-class" #auto="matAutocomplete"
          (optionSelected)="onOptionSelected($event)">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="filterByCategory(option)"
            (keydown.enter)="filterByCategory(option)" (input)="filterByCategory(option)">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
        <!-- <input type="tel" matInput placeholder="Search" name="search" > -->
        <button matSuffix mat-button><mat-icon>search</mat-icon></button>
      </mat-form-field>
      <!-- <mat-icon>search_icon</mat-icon>
      <input type="text" placeholder="Search by category" aria-label="Search by category" matInput> -->
    </form>


    <div class="nav-buttons-container">

      <div class="profile-item" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" [fxHide.xs]="true">
        <p class="menu-text">{{ button_label }}</p>
        <mat-icon svgIcon="d-arrow"></mat-icon>
      </div>

      <mat-menu class="user-profile-menu" #menu="matMenu">
        <div>
          <button mat-menu-item [matMenuTriggerFor]="profileMenu" (click)="getUserName(menuTrigger)">
            {{ profile?.firstname }} {{ profile?.lastname }}
          </button>

          <button mat-menu-item [routerLink]="'/profile'">
            <mat-icon>account_circle</mat-icon>
            <span>My Profile</span>
          </button>

          <button mat-menu-item *ngFor="let entity of entityList" [matMenuTriggerDisabled]="true"
            (click)="switchToEntity(entity.eid, menuTrigger)">
            {{ entity.orgname }}
          </button>
        </div>
      </mat-menu>

      <mat-menu #profileMenu="matMenu" xPosition="after">
        <button mat-menu-item [routerLink]="'/profile'">
          <mat-icon>account_circle</mat-icon>
          <span>My Profile</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>

      <div class="circle-nav-buttons-container">

        <div2 class="hamburger" [matMenuTriggerFor]="menu" [fxShow.xs]="true" [fxHide]="true">
          <button mat-mini-fab class="hover-color">
            <mat-icon>reorder</mat-icon>
          </button>
        </div2>

        <div class="notification-item ">
          <button mat-mini-fab class="hover-color" (click)="openNotificationsDialog()">
            <mat-icon [matBadge]="notificationCount" matBadgeSize="small" matBadgePosition="top after" svgIcon="bell">
            </mat-icon>
          </button>
        </div>

        <div class="message-item " [fxShow.xs]="false">
          <button mat-mini-fab class="hover-color" (click)="openMailDialog()">
            <mat-icon [matBadge]="messagesCount" matBadgeSize="small" matBadgePosition="top after" svgIcon="message">
            </mat-icon>
          </button>
        </div>
        
      </div>

      <!-- *ngIf="isEntity" -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="false" style="cursor: pointer"
        [routerLink]="'entity/homepage/' + entityId">
        <h1 style="margin: 0">{{ userName }}</h1>
        <img [src]="pictureUrl | secure | async" style="max-height: 30px" *ngIf="hasLogo" />
      </div>
      
    </div>

    <!--Mobile toolbar-->
    <!-- <mat-toolbar-row [fxHide]="true" [fxShow.xs]="secondToolbar" fxLayout="row" fxLayoutAlign="space-around center">
      <div class="border-space-mobile" fxLayoutAlign="center center">
        <button mat-icon-button class="hover-color text" (click)="openNotificationsDialogMobile()">
          <mat-icon [matBadge]="notificationCount" matBadgeSize="small" matBadgePosition="below after"
            matBadgeColor="accent">
            notifications_none
          </mat-icon>
        </button>
      </div>

      <div class="border-space-mobile" fxLayoutAlign="center center">
        <button mat-icon-button class="hover-color text" (click)="openMailDialogMobile()">
          <mat-icon [matBadge]="messagesCount" matBadgeSize="small" matBadgePosition="below after"
            matBadgeColor="primary">
            mail_outline
          </mat-icon>
        </button>
      </div>
      <div class="hover-color border-space-mobile text" fxLayoutAlign="center center"
        (click)="openChallengesDialogMobile()">
        Challenges
        <mat-icon style="width: 20px; vertical-align: middle">keyboard_arrow_down</mat-icon>
      </div>

      <div class="border-space-mobile" fxLayoutAlign="center center">
        <button mat-icon-button class="hover-color text" [matMenuTriggerFor]="menu">
          <mat-icon style="width: 20px; vertical-align: middle">account_circle</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>account_circle</mat-icon>
            <span>My Profile</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>

      <button mat-icon-button class="hover-color text" (click)="toggle()">
        <mat-icon>reorder</mat-icon>
      </button>
    </mat-toolbar-row> -->
  </mat-toolbar>
</div>