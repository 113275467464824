import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DashboardService } from '../dashboard/_services/dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { ExpertPublicProfileComponent } from '../dashboard/_components/expert-public-profile/expert-public-profile.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss'],
})
export class MessagingComponent implements OnInit {

  messageResponse;
  messagesCount;
  messages;
  profile;
  receiverId;
  senderId;
  allMessages;
  openedChats;
  numChats;
  messageInput;
  lastMessageID;
  readNewMessages;
  selectedUser: any;
  newMessage: string = '';
  messageValue = '';
  entityId;
  contacts;
  loadingMessages = false;
  loadingEntities = false;
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  text;

  constructor(private service: DashboardService, private changeDetectorRef: ChangeDetectorRef, private route: ActivatedRoute, public dialog: MatDialog,
    private translate: TranslateService) {
    this.text = translate.onLangChange.subscribe(() => {
      this.translateTest()
    })
  }

  ngOnInit(): void {
    this.translateTest()
    this.loadingEntities = true;

    this.service.getUserProfile().subscribe(resp => {
      this.profile = resp.data

      this.service.getEntityRelations(this.profile.entityid.eid).subscribe(resp => {
        this.contacts = resp.data;

        setTimeout(() => {
          this.loadingEntities = false;
        }, 1000);
      });

    });
  }

  openExpertPublicProfile(id): any {
    this.dialog.open(ExpertPublicProfileComponent, {
      width: '800px',
      height: '700px',
      data: { entityId: id }
    })
      .afterClosed()
      .subscribe(() => { });

  }

  translateTest(): any {
    this.translate.get('messaging').subscribe(resp => {
      this.text = resp.contacts.text
    })
  }

  getMessages(currentUser, receiverUser) {
    this.service.getChatMessages(currentUser, receiverUser).subscribe(resp => {

      this.allMessages = resp.data.messages;

      if (this.allMessages.length > 0) {
        const readMessages = this.allMessages.filter(message => message.senderid !== currentUser && !message.is_read);

        readMessages.forEach((m) => {
          this.service.markReadMessage(m.receiverid, m).subscribe((resp) => { })
        })

        this.lastMessageID = this.allMessages[this.allMessages.length - 1].messid;
        this.changeDetectorRef.detectChanges();
      } else {
        this.allMessages = [];
      }

      this.setLoadingMessages(false);
    });
  }

  refreshMessages(currentUser, receiverUser) {
    this.setLoadingMessages(true);

    this.getMessages(currentUser, receiverUser);
  }

  private setLoadingMessages(value: boolean): void {
    if (value) {
      this.loadingMessages = value;
    } else {
      setTimeout(() => {
        this.loadingMessages = false;
      }, 500);
    }
  }


  sendMessage(value: string, receiver): any {

    this.messageValue = value;
    if (this.messageValue.length > 0) {
      this.service.postNewMessage(this.profile.userid, receiver, this.messageValue).subscribe(resp => {
        this.allMessages.push(resp.data);
        this.lastMessageID = resp.data.messid;

        this.newMessage = '';
        this.getMessages(this.profile.userid, receiver)
      });
    }

  }

  selectUser(user: any): void {
    this.setLoadingMessages(true);
    this.getMessages(this.profile.userid, user.user_id)
    this.selectedUser = user.userdata;

    this.changeDetectorRef.detectChanges();
  }

  getUrl(id): any {
    if (id) {
      return this.profilePictureUrl + id;
    } else {
      return '';
    }
  }
}
