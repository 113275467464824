import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-small-article-card',
  templateUrl: './small-article-card.component.html',
  styleUrls: ['./small-article-card.component.scss']
})
export class SmallArticleCardComponent implements OnInit {
  @Input() articles;

  leadingPictureURL = `${environment.API_HOST}/files/profilepicture/`;


  constructor(
    private service: DashboardService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router

  ) { }
  ngOnInit(): void {
  }

  editArticle(article): any {
    this.router.navigate(['article/read', { id: article.articleid, entityId: article.ownerid, }]).then();
  }


  getLeadArticleImage(id): any {
    return this.leadingPictureURL + id;
  }
}
