import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-entity-edit-content',
  templateUrl: './entity-edit-content.component.html',
  styleUrls: ['./entity-edit-content.component.scss']
})
export class EntityEditContentComponent implements OnInit {
  entityId
  entity: any = {}
  projects;
  articles;
  contests;
  hasLogo;
  logoRefresh;
  hasMainImage;
  mainImageRefresh;
  data: any = {
    projects: '',
    articles: '',
    contests: '',
    entity: '',
  }

  url;

  constructor(
    private service: DashboardService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getAll()
  }

  getAll(): any {
    Promise.all([
      this.getEntityIdFromUrl(),
      this.getEntity(),
      this.getSpecificContent(),
      this.getContests(),
      this.getProjects(),
      this.getArticles()
    ])
  }


  getEntity(): any {
    this.service.getEntity(this.entityId).subscribe((resp) => {
      this.data.entity = resp.data[0];
      if (this.entity.logofileid) {
        this.hasLogo = true;
        this.logoRefresh = [2];
      } else {
        this.hasLogo = false;
        this.logoRefresh = [2];
      }

      if (this.entity.mainimage) {
        this.hasMainImage = true;
        this.mainImageRefresh = [2];
      } else {
        this.hasMainImage = false;
        this.mainImageRefresh = [2];
      }
    });
  }

  getSpecificContent(): any {
    // this.route.url.subscribe(data => {
    //   console.log(data);
    // })
    console.log(this.route);
  }

  getContests(): any {
    try {
      this.service
        .getContests(this.entityId)
        .subscribe((resp) => (this.data.contests = resp.data));
    } catch (error) {
      console.log(error);
    }
  }

  getProjects(): any {
    try {
      this.service
        .getProjects(this.entityId)
        .subscribe((resp) => (this.data.projects = resp.data));
    } catch (error) {
      console.log(error);
    }
  }

  getArticles(): any {
    this.service
      .getArticles(this.entityId)
      .subscribe((resp) => {
        this.data.articles = resp.data
      })
  }

  getEntityIdFromUrl(): any {
    this.route.params.subscribe(param => {
      this.entityId = param.entityId
    })

  }
}
