import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../../_services/dashboard.service';
import { ContestService } from 'src/app/contest/_services/contest.service';

@Component({
  selector: 'app-entity-view-contest',
  templateUrl: './entity-view-contest.component.html',
  styleUrls: ['./entity-view-contest.component.scss']
})
export class EntityViewContestComponent implements OnInit {

  contest;
  entityId;
  contestId



  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private contestService: ContestService,
  ) { }

  ngOnInit(): void {
    this.setIds()
  }

  setIds(): any {
    this.setContestId()
    this.setEntityId()
    this.getContest()
  }

  setContestId(): any {
    this.route.parent.params.subscribe(param => {
      this.entityId = param.entityId
    })
  }

  setEntityId(): any {
    this.route.params.subscribe(param => {
      this.contestId = param.contestId
    })
  }

  getContest(): any {
    this.contestService.getContest(this.entityId, this.contestId).subscribe(res => {
      if (res.error === false) {
        if (res.data.length > 0) {
          this.contest = res.data[0]
        }
      }
    })
  }
}
