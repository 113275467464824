<div *ngIf="profile">
  <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
    <div fxFlex="65" fxFlex.xs="100">
      <mat-card-header>
        <mat-card-title>
          <img
            [src]="getUrl(profile?.profilepicture) | secure | async"
            alt="photo"
            class="profile-pic"
            *ngIf="profile?.profilepicture"
          />
        </mat-card-title>
        <span fxFlex="10"></span>
        <div>
          <div>
            <div class="name">
              {{ profile?.intro[0].firstname }} {{ profile?.intro[0].lastname }}
            </div>
            <div>
              {{ profile?.intro[0].title }}
            </div>
          </div>

          <div class="activity-icon">
            <button (click)="followUnfollow()">
              <img src="/assets/follow-small.svg" />
              {{ entity?.following ? "Unfollow" : "Follow" }}
            </button>

            <button [matMenuTriggerFor]="shareMenu">
              <img src="/assets/share-small.svg" />Share
            </button>

            <button (click)="report()">
              <img src="/assets/report-small.svg" />Report
            </button>

            <button (click)="openMessageDialog()">
              <img src="/assets/message-small.svg" />Message
            </button>
          </div>
        </div>
      </mat-card-header>

      <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
        <form [formGroup]="shareForm" (click)="$event.stopPropagation()">
          <p>Share to:</p>
          <div
            fxLayout="column"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
          >
            <ng-select
              [items]="myShareSets"
              class="custom-ng-select"
              appearance="outline"
              appendTo="body"
              bindValue="value"
              bindLabel="name"
              [clearable]="true"
              formControlName="person"
              [notFoundText]="'No Results'"
              typeToSearchText="Search"
              placeholder="Person"
            >
            </ng-select>

            <ng-select
              [items]="myNetwork"
              class="custom-ng-select"
              appearance="outline"
              appendTo="body"
              bindValue="uuid"
              bindLabel="name"
              [clearable]="true"
              formControlName="mynetwork"
              [notFoundText]="'No Results'"
              typeToSearchText="Search"
              placeholder="My Network"
            >
            </ng-select>

            <button type="button" mat-stroked-button (click)="share()">
              Share
            </button>
          </div>
        </form>
      </mat-menu>

      <div>
        {{ profile?.intro[0].summary }}
      </div>

      <br />

      <h3 class="headers">Experience</h3>

      <div *ngFor="let exp of profile?.experience">
        <div class="row">
          <div class="sub-header">
            {{ exp.position }}
          </div>
          <div class="daterange" *ngIf="exp.iscurrent == 0">
            {{ exp.frommonth }} {{ exp.fromyear }} - {{ exp.tomonth }}
            {{ exp.toyear }}
          </div>
          <div class="daterange" *ngIf="exp.iscurrent == 1">
            {{ exp.frommonth }} {{ exp.fromyear }} - present
          </div>
        </div>
        <div>
          {{ exp.company }}
        </div>
        <mat-divider></mat-divider>
        <br />
      </div>

      <br />

      <h3 class="headers">Education</h3>

      <div *ngFor="let edu of profile?.education">
        <div class="row">
          <div class="sub-header">{{ edu.degree }} {{ edu.fieldofstudy }}</div>
          <div class="daterange">
            {{ edu.frommonth }} {{ edu.fromyear }} - {{ edu.tomonth }}
            {{ edu.toyear }}
          </div>
        </div>
        <div>
          {{ edu.country }}
        </div>
        <mat-divider></mat-divider>
        <br />
      </div>

      <br />

      <h1
        class="headers stats-header"
        [matTooltip]="'here comes an info box on hover'"
      >
        A-gora Professional Stats
      </h1>

      <mat-chip-list>
        <mat-chip
          color="primary"
          class="stats-chip"
          selected
          [matTooltip]="'here comes an info box on hover'"
          >Level: Expert /200</mat-chip
        >
      </mat-chip-list>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="50" fxFlex.xs="100">
          <div class="mentor-profile-card">
            <h3>Business Skills</h3>
            <br />
            <div class="levels">
              <div class="level">
                <h4>Level 1</h4>
                <div class="level-card">
                  <h5>A+</h5>
                  <p>1,2</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 2</h4>
                <div class="level-card">
                  <h5>A</h5>
                  <p>1,4</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 3</h4>
                <div class="level-card">
                  <h5>B+</h5>
                  <p>1,5</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 4</h4>
                <div class="level-card">
                  <h5>B</h5>
                  <p>2,4</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 5</h4>
                <div class="level-card">
                  <h5>C</h5>
                  <p>4,2</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 6</h4>
                <div class="level-card">
                  <h5>D</h5>
                  <p>2,3</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 7</h4>
                <div class="level-card">
                  <h5>E</h5>
                  <p>4,5</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="50" fxFlex.xs="100">
          <div class="mentor-profile-card">
            <h3>Soft Skills</h3>
            <br />
            <div class="levels">
              <div class="level">
                <h4>Level 1</h4>
                <div class="level-card">
                  <h5>A+</h5>
                  <p>2,3</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 2</h4>
                <div class="level-card">
                  <h5>A</h5>
                  <p>4,5</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 3</h4>
                <div class="level-card">
                  <h5>B+</h5>
                  <p>1,2</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 4</h4>
                <div class="level-card">
                  <h5>B</h5>
                  <p>1,5</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 5</h4>
                <div class="level-card">
                  <h5>C</h5>
                  <p>3,5</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 6</h4>
                <div class="level-card">
                  <h5>D</h5>
                  <p>2,4</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
              <div class="level">
                <h4>Level 7</h4>
                <div class="level-card">
                  <h5>E</h5>
                  <p>3,4</p>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                  <mat-icon class="star-icon">star</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br /><br />

      <h1 class="headers" [matTooltip]="'here comes an info box on hover'">
        A-gora achievement's portfolio
      </h1>

      <div style="position: relative" *ngIf="projects.length">
        <carousel
          [cellsToShow]="1"
          [dots]="true"
          arrowsTheme="dark"
          [images]="_projects_images"
          class="carousel-container"
        >
          <div
            class="carousel-cell mentor-profile-card portfolio-container"
            *ngFor="let project of projects"
          >
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="70" fxFlex.xs="100">
                <mat-chip-list>
                  <mat-chip
                    color="primary"
                    class="stats-chip"
                    selected
                    [matTooltip]="'here comes an info box on hover'"
                    >Projects Executed:
                    <strong style="margin-left: 5px">{{
                      projects?.length
                    }}</strong></mat-chip
                  >
                </mat-chip-list>
                <br />

                <h3>{{ project.title }}</h3>
                <p>{{ project.category }}</p>
                <p>{{ project.abstract }}</p>
              </div>
              <div fxFlex="30" fxFlex.xs="100">
                <img
                  [src]="getUrl(project.picture) | secure | async"
                  alt="photo"
                  class="portfolio-image"
                  *ngIf="project.picture"
                />
              </div>
            </div>
          </div>
        </carousel>

        <div class="custom-dots">
          <div *ngFor="let item of projects; let i = index">
            <span>{{ i + 1 }}</span>
          </div>
        </div>
      </div>

      <br />

      <div style="position: relative" *ngIf="newsfeeds.length">
        <carousel
          [cellsToShow]="1"
          [dots]="true"
          arrowsTheme="dark"
          [images]="_articles_images"
          class="carousel-container"
        >
          <div
            class="carousel-cell mentor-profile-card portfolio-container"
            *ngFor="let article of newsfeeds"
          >
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="70" fxFlex.xs="100">
                <mat-chip-list>
                  <mat-chip
                    color="primary"
                    class="stats-chip"
                    selected
                    [matTooltip]="'here comes an info box on hover'"
                    >Articles Published:
                    <strong style="margin-left: 5px">{{
                      newsfeeds?.length
                    }}</strong></mat-chip
                  >
                </mat-chip-list>
                <br />

                <h3>{{ article.headerline }}</h3>
                <p>{{ article.showtext }}</p>
              </div>
              <div fxFlex="30" fxFlex.xs="100">
                <img
                  [src]="getUrl(article.mainimage) | secure | async"
                  alt="photo"
                  class="portfolio-image"
                  *ngIf="article.mainimage"
                />
              </div>
            </div>
          </div>
        </carousel>

        <div class="custom-dots">
          <div *ngFor="let item of newsfeeds; let i = index">
            <span>{{ i + 1 }}</span>
          </div>
        </div>
      </div>

      <br />

      <div style="position: relative" *ngIf="contests.length">
        <carousel
          [cellsToShow]="1"
          [dots]="true"
          arrowsTheme="dark"
          [images]="_contests_images"
          class="carousel-container"
        >
          <div
            class="carousel-cell mentor-profile-card portfolio-container"
            *ngFor="let contest of contests"
          >
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex="70" fxFlex.xs="100">
                <mat-chip-list>
                  <mat-chip
                    color="primary"
                    class="stats-chip"
                    selected
                    [matTooltip]="'here comes an info box on hover'"
                    >Contests Won:
                    <strong style="margin-left: 5px">{{
                      contests?.length
                    }}</strong></mat-chip
                  >
                </mat-chip-list>
                <br />

                <h3>{{ contest.title }}</h3>
                <p>{{ contest.category }}</p>
                <p>{{ contest.abstract }}</p>
              </div>
              <div fxFlex="30" fxFlex.xs="100">
                <img
                  [src]="getUrl(contest.picture) | secure | async"
                  alt="photo"
                  class="portfolio-image"
                  *ngIf="contest.picture"
                />
              </div>
            </div>
          </div>
        </carousel>

        <div class="custom-dots">
          <div *ngFor="let item of contests; let i = index">
            <span>{{ i + 1 }}</span>
          </div>
        </div>
      </div>

      <br /><br />

      <div *ngIf="profile?.portfolio?.length">
        <h1 class="headers" [matTooltip]="'here comes an info box on hover'">
          Other achievement's portfolio
        </h1>

        <div style="position: relative">
          <carousel
            [cellsToShow]="1"
            [dots]="true"
            arrowsTheme="dark"
            [images]="_portfolio_images"
            class="carousel-container"
          >
            <div
              class="carousel-cell mentor-profile-card portfolio-container"
              *ngFor="let portf of profile?.portfolio"
            >
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="70" fxFlex.xs="100">
                  <mat-chip-list>
                    <mat-chip
                      color="primary"
                      class="stats-chip"
                      selected
                      [matTooltip]="'here comes an info box on hover'"
                      >{{ portf.title }}</mat-chip
                    >
                  </mat-chip-list>
                  <br />

                  <h3>{{ portf.title }}</h3>
                  <p>{{ portf.description }}</p>
                </div>
                <div fxFlex="30" fxFlex.xs="100">
                  <img
                    [src]="getPortfolioUrl(portf.files) | secure | async"
                    alt="photo"
                    class="portfolio-image"
                    *ngIf="portf.files"
                  />
                </div>
              </div>
            </div>
          </carousel>

          <div class="custom-dots">
            <div *ngFor="let item of profile.portfolio; let i = index">
              <span>{{ i + 1 }}</span>
            </div>
          </div>
        </div>

        <br /><br />

        <div id="newsfeed">
          <div class="newsfeed-card">
            <div *ngFor="let newsfeed of newsfeeds" class="newsfeed">
              <app-newsfeed-card
                [newsfeed]="newsfeed"
                [entityId]="this.entityId"
              ></app-newsfeed-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="35" fxFlex.xs="100" class="right-column">
      <div class="sticky-element">
        <mat-tab-group *ngIf="contests.length" mat-align-tabs="center" animationDuration="0ms">
          <mat-tab label="Contests">
            <ol class="contest">
              <li *ngFor="let contest of contests">
                <h4 class="lable">{{ contest.title }}</h4>
                <div class="text">{{ contest.abstract }}</div>
              </li>
            </ol>
          </mat-tab>

          <mat-tab label="Projects">
            <ol class="contest">
              <li *ngFor="let project of projects">
                <h4 class="lable">{{ project.title }}</h4>
                <div class="text">{{ project.abstract }}</div>
              </li>
            </ol>
          </mat-tab>

          <mat-tab label="Jobs">
            <ol class="contest">
              <li *ngFor="let job of jobs">
                <h4 class="lable">{{ job.title }}</h4>
                <div class="text">{{ job.abstract }}</div>
              </li>
            </ol>
          </mat-tab>
        </mat-tab-group>

        <div id="leaderboard" class="leaderboard">
          <div class="tabs-widget">
            <div
              [ngClass]="
                widgetActiveTab === 'leaderboard'
                  ? 'laderboard-header laderboard-header-active'
                  : 'laderboard-header'
              "
              (click)="onSelectWidgetTab('leaderboard')"
            >
              <div class="tropy-wrapper">
                <img class="trophy-icon" src="assets/trophy.svg" />
              </div>
              <span class="trophy-label">Leader Board</span>
            </div>
            <div
              [ngClass]="
                widgetActiveTab === 'people'
                  ? 'laderboard-header laderboard-header-active'
                  : 'laderboard-header'
              "
              (click)="onSelectWidgetTab('people')"
            >
              <div class="tropy-wrapper">
                <img class="trophy-icon" src="assets/crown.svg" />
              </div>
              <span class="trophy-label">People / Experts</span>
            </div>
          </div>

          <div
            class="leaderboard-content lblineitems"
            *ngIf="widgetActiveTab === 'leaderboard'"
          >
            <div *ngFor="let feed of leaderboard" class="feed">
              <img class="avatar" src="/assets/eclipse-image.svg" />
              <h6 class="name">{{ feed.name }}</h6>
              <p class="text">{{ feed.showtext }}</p>
              <button class="follow-button">follow</button>
            </div>
          </div>

          <div
            class="leaderboard-content lblineitems"
            *ngIf="widgetActiveTab === 'people'"
          >
            <div *ngFor="let person of people" class="feed">
              <img class="avatar" src="/assets/eclipse-image.svg" />
              <h6 class="name">{{ person.userid }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
