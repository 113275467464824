<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 style="margin: 0">{{ data ? ('profile.manage.talent.qualifications.add-modal.edit' | translate ):
    ('profile.manage.talent.qualifications.add-modal.add' | translate)}}</h2>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="qualificationForm" (ngSubmit)="submit()">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0px" style="margin-top: 20px">
      <mat-form-field fxFlex>
        <mat-label>{{'profile.manage.talent.qualifications.add-modal.certificates.label' | translate}}</mat-label>
        <input matInput [placeholder]="'profile.manage.talent.qualifications.add-modal.certificates.placeholder' | translate" formControlName="description">
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{'profile.manage.talent.qualifications.add-modal.organization.label' | translate}}</mat-label>
        <input matInput [placeholder]="'profile.manage.talent.qualifications.add-modal.organization.label' | translate" formControlName="organization">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field fxFlex>
        <mat-label>{{'profile.manage.talent.qualifications.add-modal.summary.label' | translate}}</mat-label>
        <textarea matInput [placeholder]="'profile.manage.talent.qualifications.add-modal.summary.label' | translate" formControlName="summary"></textarea>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>{{'profile.manage.talent.qualifications.add-modal.date.label' | translate}}</mat-label>
        <mat-select formControlName="foryear">
          <mat-option *ngFor="let year of years" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field fxFlex>
        <mat-label>{{'profile.manage.talent.qualifications.add-modal.credential-id.label' | translate}}</mat-label>
        <input matInput formControlName="certid">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field fxFlex>
        <mat-label>{{'profile.manage.talent.qualifications.add-modal.credential-link.label' | translate}}</mat-label>
        <input matInput formControlName="certurl">
      </mat-form-field>
    </div>
    <br>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
      <button mat-stroked-button type="button"
        (click)="close()">{{'profile.manage.talent.qualifications.add-modal.buttons.cancel.text' | translate}}</button>
      <button mat-raised-button color="primary"
        type="submit">{{'profile.manage.talent.qualifications.add-modal.buttons.save.text' | translate}}</button>
    </div>

  </form>
</mat-dialog-content>