import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private ENTITY_URL = `${environment.API_HOST}/entity/`;
  private readonly VISIBILITY_URL = `${environment.API_HOST}/entity/%entityId%/project/visibilities/`;

  constructor(private http: HttpClient) { }

  getCategories(): Observable<any> {
    return this.http.get<any>(`${this.ENTITY_URL}project/maincategories/`);
  }

  getVisibilities(entityId): Observable<any> {
    return this.http.get<any>(
      this.VISIBILITY_URL.replace('%entityId%', entityId)
    );
  }

  getSubCategories(data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}project/subcategories/`,
      data
    );
  }

  getDrafts(entityId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/getdrafts/`
    );
  }

  getDraft(entityId, id): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/getdraft/${id}/`
    );
  }

  getProject(entityId, id): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/read/${id}/`
    );
  }

  postDraft(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/draft/`,
      data
    );
  }

  getNewDraftId(entityId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/getnewdraftid/`
    );
  }

  createProject(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/create/`,
      data
    );
  }

  getFiles(entityId, draftId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/getfiles/${draftId}/`
    );
  }

  addFile(entityId, data, id): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/addfile/${id}/`,
      data
    );
  }

  removeFile(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/removefile`,
      data
    );
  }

  deleteDraft(entityId, id): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/discard/${id}/`,
      {}
    );
  }

  updateProject(entityId, projectId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/update/${projectId}}`,
      data
    );
  }

  cancelApplication(entityId, projectid, applid): Observable<any> {
    return this.http.put<any>(
      `${this.ENTITY_URL}${entityId}/project/application/${projectid}`, { entityId, projectid, applid }
    );
  }

  postMainimage(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/draft/mainimage`,
      data
    );
  }

  postMainimageProject(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/mainimage`,
      data
    );
  }

  getComments(entityId, draftId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/comments/${draftId}/`
    );
  }

  createApplication(entityId, projectid, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/application/${projectid}`,
      data
    );
  }

  getNewApplicationId(entityId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/getnewapplicationid/`
    );
  }

  getApplication(entityId, projectid): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/application/${projectid}`
    );
  }

  submitApplication(entityId, projectid, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/application/apply/${projectid}`,
      data
    );
  }

  getAppFiles(entityId, draftId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/application/getfiles/${draftId}/`
    );
  }

  addAppFile(entityId, data, id): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/application/addfile/${id}/`,
      data
    );
  }

  removeAppFile(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/application/remove/file`,
      data
    );
  }

  getApplications(entityId, projectid): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/mgmt/listapps/${projectid}/`
    );
  }

  readApplication(entityId, projectid, data): Observable<any> {
    let data2 = {
      applicant_id: data,
    };
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/mgmt/getapp/${projectid}/`,
      data2
    );
  }

  saveRating(entityId, projectid, numberStars, applid): Observable<any> {
    let data = {
      rating: numberStars,
      applicant_id: applid,
    };
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/mgmt/apps-ratings/${projectid}/`,
      data
    );
  }

  postAward(entityId, projectid, applid, taskIds): Observable<any> {
    let data = {
      applicant_id: applid,
      taskid: taskIds,
    };
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/mgmt/award-project/${projectid}/`,
      data
    );
  }

  saveNewTask(entityId, projectid, applid, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/mgmt/tasks/${projectid}/appl/${applid}`,
      data
    );
  }

  getTasks(entityId, projectid, applid): Observable<any> {
    let url = `${this.ENTITY_URL}${entityId}/project/mgmt/tasks/${projectid}/appl/${applid}`;
    return this.http.get<any>(url);
  }

  updateTask(entityId, projectid, applid, data): Observable<any> {
    let url = `${this.ENTITY_URL}${entityId}/project/mgmt/tasks/${projectid}/appl/${applid}/update`;
    return this.http.post<any>(url, data);
  }

  removeTask(entityId, projectid, applid, taskId): Observable<any> {
    let data = {
      taskid: taskId,
    };
    let url = `${this.ENTITY_URL}${entityId}/project/mgmt/tasks/${projectid}/appl/${applid}/remove`;
    return this.http.post<any>(url, data);
  }

  updateTaskOrder(entityId, projectid, applid, taskList): Observable<any> {
    let url = `${this.ENTITY_URL}${entityId}/project/mgmt/tasks/${projectid}/appl/${applid}/updatetasksequence`;
    let data = {
      tasks: taskList,
    };
    return this.http.post<any>(url, data);
  }

  cancelAward(entityId, projectid, applid): Observable<any> {
    let data = {
      applicant_id: applid,
    };
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/mgmt/cancel-project-award/${projectid}/`,
      data
    );
  }

  // manage project:
  getTaskId(entityId, projectId, applid): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/mgmt/tasks/${projectId}/appl/${applid}/getnewtaskid/`
    );
  }

  getTaskFiles(entityId, taskid): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/project/mgmt/tasks/getfiles/${taskid}/`
    );
  }

  postTaskFiles(entityId, taskid, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/mgmt/tasks/addfile/${taskid}/`,
      data
    );
  }

  removeTaskFile(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/mgmt/tasks/remove/file`,
      data
    );
  }

  filterProjects(filters): Observable<any> {
    return this.http.post<any>(
      `${environment.API_HOST}/project/browse/`,
      filters
    );
  }

  deleteProject(entityId, id): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/project/delete/${id}`,
      {}
    );
  }

  getProjectConnections(entityId): Observable<any> {
    return this.http.get<any>(`${environment.API_HOST}/project/mgmt/get-applicants/${entityId}`)
  }
}
