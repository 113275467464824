<div class="">
    <p>talent profile stats</p>
    <p>talent profile stats</p>
    <p>talent profile stats</p>
    <p>talent profile stats</p>
</div>
<!-- <div class="left-sidebar" id="left-sidebar">
    <div class="profile" id="profile">
      <div class="profile-wrapper">
        <div class="profile-pic-and-info">
          <div class="profile-pic">
            <img class="image" [src]="profilePictureUrl | secure | async" />
          </div>
          <div class="profile-info">
            <div class="name">
              {{ profile?.firstname }} {{ profile?.lastname }}
            </div>
            <div class="designation">{{ profile?.headline }}</div>
          </div>
        </div>
        <div class="profile-status">
          <div class="profile-status-header">
            <div class="profile-status-icon-wrapper">
              <mat-icon class="profile-status-icon">assessment</mat-icon>
            </div>
            <span>Profile Stats</span>
          </div>
          <div class="project-item">
            <label class="project">Projects</label>
            <label class="count">2</label>
          </div>
          <div class="project-item">
            <label class="project">Contests</label>
            <label class="count">3</label>
          </div>
        </div>
  
      </div>
      <div id="activities" class="activity">
        <div class="activity-wrapper">
          <div class="activity-header">
            <div class="clock-wrapper">
              <img class="clock-icon" src="assets/clock.svg" />
            </div>
            <span class="activity-label">My Activities</span>
          </div>
          <div class="achievement-wrapper">
            <div *ngFor="let achievement of achievements">
              <div class="date">{{ achievement.ts }}</div>
              <div class="text">{{ achievement.txtbody }}</div>
              <br />
            </div>
          </div>
        </div>
      </div>
  
    </div>
    <div class="profile-mobile" id="profile">
      <div class="profile-wrapper">
        <div class="profile-pic-and-info">
          <div class="profile-pic">
            <img class="image" [src]="profilePictureUrl | secure | async" />
          </div>
          <div class="profile-info">
            <div class="name">
              {{ profile?.firstname }} {{ profile?.lastname }}
            </div>
            <div class="designation">{{ profile?.headline }}</div>
          </div>
        </div>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="profile-status-icon">assessment</mat-icon>
            </mat-panel-title>
            <mat-panel-description>
              Profile Stats
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="profile-status">
            <div class="project-item">
              <label class="project">Projects</label>
              <label class="count">2</label>
            </div>
            <div class="project-item">
              <label class="project">Contests</label>
              <label class="count">3</label>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <img class="clock-icon" src="assets/clock.svg" />
          </mat-panel-title>
          <mat-panel-description>
            My Activities
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="achievement-wrapper">
          <div *ngFor="let achievement of achievements">
            <div class="date">{{ achievement.ts }}</div>
            <div class="text">{{ achievement.txtbody }}</div>
            <br />
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div> -->