<div *ngIf="!design">
  <mat-form-field class="testing-class-for-language" appearance="fill">
    <mat-label> <mat-icon>language</mat-icon></mat-label>
    <mat-select [(value)]="selectedLanguage" (selectionChange)="setLanguage($event.value)">
      <mat-option *ngFor="let lang of languages" [value]="lang.value">
        {{lang.text}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="design === 'menubutton'">
  <button mat-menu-item [matMenuTriggerFor]="languagesMenu">
    <mat-icon>language</mat-icon>
    <span>{{ 'nav-bar.profile.language_select' | translate }} ({{ selectedLanguage }})</span>
  </button>
  <mat-menu #languagesMenu="matMenu">
    <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang.value)">
      <span [class]="lang.value === selectedLanguage ? 'selected' : '' ">{{lang.text}} </span>
    </button>
  </mat-menu>
</div>