import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DialogConfirmComponent } from '../../../common/dialog-confirm/dialog-confirm.component';
import { ArticleService } from '../../_services/article.service';

import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarMessagesComponent } from 'src/app/common/snackbar-messages/snackbar-messages.component';

export interface Keyword {
  keyword: string;
}

@Component({
  selector: 'app-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.scss'],
  providers: [DialogConfirmComponent, { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } }]
})
export class ArticleEditComponent implements OnInit, OnDestroy {
  snackbarMessages: SnackbarMessagesComponent
  formGroup: FormGroup;
  id;
  entityId;
  publicationTypes;
  visibilities;
  categories;
  saveInterval;
  loading = false;
  mainImageId = null;
  list = [];
  article;
  isOwner;
  profile;

  filteredCategories: Observable<[]>;
  filteredTypes: Observable<[]>;

  files: Array<any>;
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  keywords: Keyword[] = [];

  selectedTab = 0;

  constructor(private formBuilder: FormBuilder,
    private service: ArticleService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialog: DialogConfirmComponent,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>) {
    this.snackbarMessages = new SnackbarMessagesComponent(snackbar, translate)
    dateAdapter.setLocale('en-in');
    this.id = this.route.snapshot.paramMap.get('articleId');
    this.entityId = this.route.snapshot.paramMap.get('entityId');
    this.formGroup = this.formBuilder.group({
      draftid: [''],
      title: [''],
      category: [''],
      visibility: ['public'],
      type: [''],
      abstract: [''],
      content: [''],
      bibliography: [''],
      keywords: [''],
      authorship: this.formBuilder.group({
        creationdate: [''],
        externallink: [''],
        authors: this.formBuilder.array([
          this.initAuthors()
        ])
      })
    });
  }

  ngOnInit(): any {
    this.getCategories();
    this.getPublicationTypes();
    this.getVisibilities();
    this.getArticle();
    this.getFiles();
  }

  getArticle(): any {
    this.service.getArticle(this.entityId, this.route.snapshot.params.id).subscribe(resp => {
      this.article = resp.data[0];

      if (typeof this.article.keywords === 'string') this.article.keywords = JSON.parse(this.article.keywords);
      if (typeof this.article.authorship === 'string') this.article.authorship = JSON.parse(this.article.authorship);

      if (this.article.ownerid === this.entityId) {
        this.isOwner = true;
      } else {
        this.goBack()
      }

      if (this.article.mainimage) {
        this.list = [1];
      }
      this.setFormValues(this.article);
    });
  }

  setFormValues(article): void {
    this.formGroup.patchValue({
      title: article.title,
      category: article.category,
      visibility: article.visibility,
      type: article.type,
      abstract: article.abstract,
      content: article.content,
      bibliography: article.bibliography,
      keywords: article.keywords,
      authorship: {
        creationdate: article?.authorship?.creationdate,
        externallink: article?.authorship?.externallink,
        authors: article?.authorship?.authors?.map(author => ({
          name: author?.name,
          contribution: author?.contribution
        }))
      }
    });

    this.mainImageId = article.mainimage;
    if (this.mainImageId) {
      this.list = [1];
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.saveInterval);
  }


  setKeywords(keywords): void {
    const keywordsArray = this.formGroup.get('keywords') as FormArray;
    keywordsArray.clear();
    keywords.forEach(kw => {
      keywordsArray.push(this.formBuilder.group({
        keyword: kw.keyword
      }));
    });
  }

  addKeyword(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    console.log(this.formGroup.controls);

    if ((value || '').trim()) {
      this.formGroup.controls['keywords'].value.push({ keyword: value.trim() })
    }

    if (input) {
      input.value = '';
    }
  }

  removeKeyword(keywordIndex): void {
    if (keywordIndex >= 0) {
      this.formGroup.controls['keywords'].value.splice(keywordIndex, 1);
    }
  }

  initAuthors(): FormGroup {
    return this.formBuilder.group({
      name: [''],
      contribution: ['']
    });
  }

  addAuthor(): any {
    const control = this.formGroup.controls['authorship']['controls']['authors'] as FormArray;
    control.push(this.initAuthors());
  }

  removeAuthor(i: number): any {
    const control = this.formGroup.controls['authorship']['controls']['authors'] as FormArray;
    control.removeAt(i);
  }

  _filterCategories(name: string): any {
    const filterValue = name.toLowerCase();
    return this.categories.filter(option => option.cat.toLowerCase().indexOf(filterValue) === 0);
  }



  getCategories(): any {
    this.service.getCategories(this.entityId).subscribe(resp => {
      this.categories = resp['data'];
      // this.categories = resp['data']['categories'];
      this.filteredCategories = this.formGroup.controls['category'].valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.cat),
          map(cat => cat ? this._filterCategories(cat) : this.categories.slice())
        );
    });
  }

  _filterTypes(name: string): any {
    const filterValue = name.toLowerCase();
    return this.publicationTypes.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getPublicationTypes(): any {
    this.service.getPublicationTypes(this.entityId).subscribe(resp => {
      this.publicationTypes = resp['data'];
      this.filteredTypes = this.formGroup.controls['type'].valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(type => type ? this._filterTypes(type) : this.publicationTypes.slice())
        );
    });
  }

  getVisibilities(): any {
    this.service.getVisibilities(this.entityId).subscribe(resp => {
      this.visibilities = resp['data']['visibilities'];
    });
  }

  getNewDraftId(): any {
    this.service.getNewDraftId(this.entityId).subscribe(resp => {
      this.formGroup.controls['draftid'].patchValue(resp['data']['newdraftid']);
      // this.getFiles();
    });
  }

  submit(): any {
    if (this.formGroup.valid) {
      const formValues = Object.assign({}, this.formGroup.value);
      formValues.articleid = this.route.snapshot.params.id;

      this.confirmDialog.openDialog((value) => {
        if (value) {
          this.service.updateArticle(this.entityId, formValues).subscribe((resp) => this.onSuccess(resp), (error) => this.onError(error));
        }
      });
    }
  }

  save(): any {
    if (this.formGroup.valid) {
      const formValues = Object.assign({}, this.formGroup.value);
      formValues['keywords'] = this.keywords;
      this.service.postDraft(this.entityId, formValues).subscribe(() => null, (error) => this.onError(error));
    }
  }

  handleFileInput(event): any {
    this.loading = true;
    const formData: FormData = new FormData();
    const file: File = event.target.files[0];
    formData.append('filecontent', file, file.name);
    this.service.addFile(this.entityId, formData, this.route.snapshot.params.id).subscribe(() => {
      this.getFiles();
      this.loading = false;
    }, () => {
    });
  }

  handleAbstractFileInput(event): any {
    this.loading = true;
    const formData: FormData = new FormData();
    const file: File = event.target.files[0];
    formData.append('filecontent', file, file.name);
    this.service.addFile(this.entityId, formData, this.route.snapshot.params.id).subscribe(resp => {
      this.selectMainimage(resp.data.fileid);
      if (this.mainImageId) {
        this.removeFile(this.mainImageId);
      }
      this.loading = false;
    }, () => {
    });
  }

  getFiles(): any {
    this.service.getFiles(this.entityId, this.route.snapshot.params.id).subscribe(resp => {
      this.files = resp.data;
      this.files = this.files.filter(obj => obj.id !== this.mainImageId);
    });
  }

  removeFile(fileId): any {
    const data = { fileid: fileId, draftid: this.article.fromdraftid };
    this.service.removeFile(this.entityId, data).subscribe(resp => {
      this.getFiles();
    });
  }

  getUrl(id): any {
    return this.profilePictureUrl + id;
  }

  selectMainimage(id): any {
    const data = { draftid: this.route.snapshot.params.id, fileid: id };
    this.service.postMainimageArticle(this.entityId, data).subscribe(resp => {
      this.mainImageId = id;
      this.list = [2];
    });
  }

  onSuccess(resp): any {
    this.snackbarMessages.onSuccess()
    this.router.navigate(['article/read', { id: resp.data.id, entityId: this.entityId }]).then();
  }

  onError(error): any {
    this.snackbarMessages.onError()
    this.setServerErrors(error);
  }

  setServerErrors(error): any {
    error.data?.msg.forEach(obj => {
      this.formGroup.get(obj.param).setErrors({ serverError: obj.msg });
    });
  }

  hasErrorFirstTab(): boolean {
    return this.formGroup.controls['title'].hasError('serverError') ||
      this.formGroup.controls['category'].hasError('serverError') ||
      this.formGroup.controls['type'].hasError('serverError') ||
      this.formGroup.controls['abstract'].hasError('serverError');
  }

  hasErrorSecondTab(): boolean {
    return this.formGroup.controls['content'].hasError('serverError');
  }

  hasErrorThirdTab(): boolean {
    return this.formGroup.controls['bibliography'].hasError('serverError') ||
      this.formGroup.controls['authorship'].hasError('serverError') ||
      this.formGroup.controls['visibility'].hasError('serverError');
  }

  nextTab() {
    this.selectedTab++;
  }

  previousTab() {
    this.selectedTab--;
  }

  goBack(): any {
    this.router.navigate(['/dashboard'])
  }
}
