<mat-card-header>
    <mat-card-title class="header">
        <h4 *ngIf="projects.length; else elseBlock">A list of projects that you interact with</h4>
        <ng-template #elseBlock>
            <h4>No Projects Available</h4>
        </ng-template>
    </mat-card-title>
    <span fxFlex></span>
</mat-card-header>
<br />

<div *ngFor="let project of projects" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" #lastElement>
    <div *ngIf="project.project.length" (click)="goToProject(project.projectid, project.project[0].ownerid)"
        class="notification-unread">
        <section class="row-contest-item">
            <div>
                <h5 class="notification-header">{{project?.project[0]?.title}}</h5>
                <h5 class="notification-header">{{project?.project[0]?.maincategory}}</h5>
            </div>
            <div>
                <h5 class="notification-header">Status:
                    <ng-container *ngIf="project.state === 1">In Progress</ng-container>
                    <ng-container *ngIf="project.state === 2">Submitted</ng-container>
                    <ng-container *ngIf="project.state === 3">Canceled</ng-container>
                    <ng-container *ngIf="project.state === 4">Winner</ng-container>
                </h5>
            </div>
        </section>
    </div>
</div>