import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-small-talent-card',
  templateUrl: './small-talent-card.component.html',
  styleUrls: ['./small-talent-card.component.scss']
})
export class SmallTalentCardComponent implements OnInit {
  @Input() connection;
  constructor() { }

  ngOnInit(): void {
  }

}
