import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralSocialBlock } from './general-social-block/general-social-block.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    GeneralSocialBlock,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
  ],
  exports: [
    GeneralSocialBlock,
  ],
})
export class SharedModule { }
