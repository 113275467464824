<div class="background">
  <div class="container">
    <div class="row login">
      <!--  <img alt="icon" src="../../assets/login-background.svg" class="login-background-icon">  -->

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 columns">
        <img alt="icon" class="auth-logo" src="../../assets/logo-final-white.svg" (click)="goToHome()"
          style="cursor: pointer;">
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 columns">
        <mat-tab-group #mtg class="header-less-tabs" animationDuration="0ms">

          <mat-tab>
            <ng-container>
              <form (ngSubmit)="login()" [formGroup]="loginForm">
                <app-language-dropdown></app-language-dropdown>
                <div class="login-container">
                  <div class="title">{{ 'sign-in.title' | translate }}</div>
                  <div class="company-name-login">
                    <!--  <img alt="icon" src="../../../assets/logo-final.png" style="width: 200px">  -->
                    <!--              <h1>A-GORA</h1>-->
                  </div>
                  <div class="login-body">
                    <div class="login-forms">
                      <div class="error-field" [ngStyle]="{'visibility': errorMessage ? 'visible': 'hidden'}">
                        {{errorMessage ? errorMessage : '&nbsp;'}}
                      </div>
                      <div>
                        <!--  <div class="input-icon">
                          <img alt="input icon" src="../../../assets/login-user.svg">
                        </div>  -->
                        <mat-form-field appearance="outline" class="input-form">
                          <mat-label class="auth-text">{{'sign-in.email-label' | translate}}</mat-label>
                          <input matInput autocapitalize="off" autocomplete="off" class="input-form-email"
                            formControlName="email" placeholder="Email" type="text">
                        </mat-form-field>
                      </div>
                      <div>
                        <!--  <div class="input-icon">
                          <img alt="input icon" src="../../../assets/password-icon.svg">
                        </div>  -->
                        <mat-form-field appearance="outline" class="input-form">
                          <mat-label class="auth-text">{{'sign-in.password-label' | translate}}</mat-label>
                          <input matInput autocapitalize="off" autocomplete="off" class="input-form-password"
                            formControlName="password" placeholder="Password" type="password"
                            [type]="hide ? 'password' : 'text'">
                          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>
                      <span class="forgot-pwd" (click)="goToResetPass()">{{'sign-in.forgot-password' |
                        translate}}</span>
                    </div>
                    <div class="bottom-buttons">
                      <div class="login-button">
                        <button mat-button type="submit">{{'sign-in.sign-in-button' | translate}}</button>
                      </div>
                      <!--  <div class="login-button-google">
                        <button mat-button type="submit">
                          <img alt="icon" src="../../../assets/google-signin.svg" style="margin-right: 17%; margin-left: 8%;">
                          Sign in with Google
                        </button>
                      </div>  -->
                      <div class="separation-line">
                        <span class="line"></span>
                        <span class="line-text" style="padding: 0 10px;"> {{'sign-in.separator' | translate}} </span>
                        <span class="line"></span>
                      </div>
                      <div class="register-button">
                        <button mat-button (click)="goToSignup()">{{'sign-in.register-button' | translate}}</button>
                      </div>
                    </div>
                    <!-- <p class="signup">Don't have an account?
                      <b (click)="goToSignup()" class="pointer" style="text-decoration: underline;">Register</b>
                    </p>  -->
                  </div>
                </div>
              </form>
            </ng-container>
          </mat-tab>

          <mat-tab>
            <ng-container>
              <form (ngSubmit)="confirm()" [formGroup]="pinForm">
                <div class="login-container">
                  <div class="company-name-login">
                    <img alt="icon" src="../../../assets/logo-final.png" style="width: 200px">
                  </div>
                  <!-- <div class="login-body">
                    <div class="login-forms">
                      <div class="error-field" [ngStyle]="{'visibility': errorMessage ? 'visible': 'hidden'}">
                        {{errorMessage ? errorMessage : '&nbsp;'}}
                      </div>

                      <div>
                        <mat-form-field class="input-form">
                          <input matInput autocapitalize="off" autocomplete="off" class="input-form-email"
                            formControlName="pincode" placeholder="Pin" type="text">
                        </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="center center">
                        <button fxFlex="50" mat-stroked-button color="primary" type="button"
                          (click)="getNewPinCode()">Resend
                          Code
                        </button>
                      </div>
                    </div>
                    <div class="login-button">
                      <button mat-button type="submit">Confirm</button>
                      <p class="signup">You have an account?
                        <b (click)="goToLogin()" class="pointer" style="text-decoration: underline;">Login</b>
                      </p>
                    </div>
                  </div> -->
                </div>
              </form>
            </ng-container>
          </mat-tab>

          <mat-tab>
            <ng-container>
              <form (ngSubmit)="confirm()" [formGroup]="pwdForm">
                <!-- <app-language-dropdown></app-language-dropdown> -->
                <div class="login-container">
                  <div class="company-name-login">
                    <img alt="icon" src="../../../assets/logo-final.png" style="width: 200px">
                  </div>
                  <div class="login-body-password">
                    <div class="login-forms">
                      <div class="error-field" [ngStyle]="{'visibility': errorMessage ? 'visible': 'hidden'}">
                        {{errorMessage ? errorMessage : '&nbsp;'}}
                      </div>

                      <div class="email-wrapper">
                        <mat-form-field class="input-form">
                          <mat-label class="auth-text">{{'forgot-password.email-label' | translate}}</mat-label>
                          <input matInput autocapitalize="off" autocomplete="off" class="input-form-email"
                            formControlName="reset_pwd_email" placeholder="Email" type="text">
                        </mat-form-field>
                      </div>
                    </div>
                    <div class=" login-button">
                      <div
                        style="padding: 0.7em; border-radius: 8px; color: white; background-color: #008D91; margin-top: 2em; cursor: pointer;"
                        (click)="sendResetPasswordLink()">
                        {{'forgot-password.confirm' | translate}}</div>
                      <p class="signup">{{'forgot-password.login' | translate}}
                        <b (click)="goToLogin()" class="pointer"
                          style="text-decoration: underline;">{{'forgot-password.login-button' | translate}}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </ng-container>
          </mat-tab>

        </mat-tab-group>
      </div>

    </div>
  </div>
</div>