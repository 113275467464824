import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.scss']
})
export class NewsfeedComponent implements OnInit {

  entityId;
  newsfeeds;
  numberNewsfeedPosts = 5;
  constructor(
    private service: DashboardService,
    private route: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe((params) => {
      this.entityId = params.id;
    });
    this.getNewsfeed()

  }



  getNewsfeed(): any {
    // this.service
    //   .getNewsfeed(this.entityId)
    //   .subscribe((resp) => (this.newsfeeds = resp.data.newsfeed));

    this.service.getNewsfeedHomepage(this.numberNewsfeedPosts).subscribe(resp => {
      if (JSON.stringify(resp['data']['newsfeed']) !== JSON.stringify(this.newsfeeds)) {
        this.newsfeeds = resp['data']['newsfeed'];
        console.log(this.newsfeeds);

      }
    })
  }

  onScroll(): any {
    this.numberNewsfeedPosts += 3; // Increment the number of newsfeed posts to fetch
    this.getNewsfeed(); // Fetch more newsfeed items
  }

}
