import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarMessagesComponent } from 'src/app/common/snackbar-messages/snackbar-messages.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  snackbarMessages: SnackbarMessagesComponent

  resetPass: FormGroup;
  loading: false;
  hash;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private auth: AuthService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private router: Router,) { 
    this.snackbarMessages = new SnackbarMessagesComponent(snackbar, translate)

    }

  ngOnInit(): void {
    this.resetPass = this.getChangePasswordForm();
    this.hash = this.route.snapshot.queryParams.hash

    if (this.hash) {
      this.resetPass.patchValue({ hash: this.hash });
    }
  }

  reset() {
    if (this.resetPass.valid) {
      if (!this.resetPass.value.hash) {
        return this.snackbar.open('Expired or Invalid link! Please contact support if the error persists.', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
      }

      this.auth.changePasswordViaMail(this.resetPass.value).subscribe(
        (resp) => {
          console.log(resp);

          this.snackbar.open('Password changed successfully!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
          this.router.navigate(['/auth/login']);
        },
        error => {
          this.onError(error.error);
        }
      );
    }
  }


  public getChangePasswordForm(): FormGroup {
    const passwordValidators = [Validators.required, Validators.maxLength(128), Validators.minLength(8)];
    return this.fb.group({
      new_password: ['', [...passwordValidators]],
      repeat_new_password: ['', [...passwordValidators]],
      hash: []
    }, { validators: this.passwordMisMatchValidator });
  }


  passwordMisMatchValidator(changePasswordForm: FormGroup): any {
    if (!(changePasswordForm.dirty && changePasswordForm.touched)) {
      return null;
    }
    const password = changePasswordForm.get('new_password');
    const repeat_new_password = changePasswordForm.get('repeat_new_password');
    if (password.value === repeat_new_password.value) {
      repeat_new_password.setErrors(null);
      return null;
    } else {
      repeat_new_password.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    }
  }

  onError(error): any {
    this.snackbar.open(error ? error : 'Operation Failed!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
  }
}
