<div *ngIf="profile">
  <div>
    <div>
      <img [src]="getUrl(profile?.profilepicture) | secure | async" alt="photo" class="profile-pic"
        *ngIf="profile?.profilepicture">
      <div *ngIf="!profile?.profilepicture" class="profile-initial">
        {{ profile?.intro[0]?.firstname ? profile?.intro[0]?.firstname[0].toUpperCase() : '' }}
      </div>
    </div>
    <span fxFlex></span>
    <div>
      <div class="name">
        {{profile?.intro[0].firstname}} {{profile?.intro[0].lastname}}
      </div>
      <div>
        {{profile?.intro[0].title ? profile?.intro[0].title : 'No title listed'}}
      </div>
    </div>
    <!-- <div class="activity-icon">
      <button (click)="followUnfollow()">
        <img src="/assets/follow-small.svg">
        {{entity?.following ? 'Unfollow' : 'Follow'}}
      </button>

      <button [matMenuTriggerFor]="shareMenu">
        <img src="/assets/share-small.svg">Share
      </button>

      <button (click)="report()">
        <img src="/assets/report-small.svg">Report
      </button>

      <button (click)="openMessageDialog()">
        <img src="/assets/message-small.svg">Message
      </button>
    </div> -->
  </div>
  <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
    <form [formGroup]="shareForm" (click)="$event.stopPropagation()">
      <p>Share to:</p>
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-select [items]="myShareSets" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="value"
          bindLabel="name" [clearable]="true" formControlName="person" [notFoundText]="'No Results'"
          typeToSearchText="Search" placeholder="Person">
        </ng-select>

        <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="uuid"
          bindLabel="name" [clearable]="true" formControlName="mynetwork" [notFoundText]="'No Results'"
          typeToSearchText="Search" placeholder="My Network">
        </ng-select>

        <button type="button" mat-stroked-button (click)="share()">Share</button>
      </div>
    </form>
  </mat-menu>

  <!--  <mat-menu #sendMenu="matMenu" class="custom-mat-menu">
        <form [formGroup]="messageForm" (click)="$event.stopPropagation()">
          <p>Send to:</p>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-form-field class="custom-form-field" appearance="outline">
              <mat-label>Subject</mat-label>
              <input matInput placeholder="Subject" formControlName="subject">
            </mat-form-field>
      
            <mat-form-field class="custom-form-field" appearance="outline">
              <mat-label>Mesage</mat-label>
              <textarea matInput placeholder="Message" formControlName="message"></textarea>
            </mat-form-field>
      
            <button type="button" mat-stroked-button (click)="sendMessage()">Send</button>
          </div>
        </form>
    </mat-menu>  -->

  <div class="summary-text">
    {{profile?.intro[0].summary ? profile?.intro[0].summary : 'No summary listed'}}
  </div>
  <br />

  <h3 class="headers"> Experience </h3>
  <section *ngIf="profile?.experience.length">
    <div *ngFor="let exp of profile?.experience">
      <div class="row">
        <div class="sub-header">
          {{exp.position}}
        </div>
        <div class="daterange" *ngIf="exp.iscurrent == 0">
          {{exp.frommonth}} {{exp.fromyear}} - {{exp.tomonth}} {{exp.toyear}}
        </div>
        <div class="daterange" *ngIf="exp.iscurrent == 1">
          {{exp.frommonth}} {{exp.fromyear}} - present
        </div>
      </div>
      <div>
        {{exp.company}}
      </div>
      <mat-divider></mat-divider>
      <br />
    </div>
  </section>
  <div *ngIf="!profile?.experience.length">No experience listed</div>
  <br />

  <h3 class="headers"> Education </h3>
  <section *ngIf="profile?.education.length">
    <div *ngFor="let edu of profile?.education">
      <div class="row">
        <div class="sub-header">
          {{edu.degree}} {{edu.fieldofstudy}}
        </div>
        <div class="daterange">
          {{edu.frommonth}} {{edu.fromyear}} - {{edu.tomonth}} {{edu.toyear}}
        </div>
      </div>
      <div>
        {{edu.country}}
      </div>
      <mat-divider></mat-divider>
    </div>
  </section>
  <div *ngIf="!profile?.education.length">No education listed</div>
  <!-- <h3 class="headers" [matTooltip]="'here comes an info box on hover'"> A-gora professional stats </h3>
  <div class="containers">
    <h4>Level: expert / 2000 </h4>
    <mat-divider></mat-divider>
    <h4>Business skills </h4>
    <table>
      <tr>
        <th>Counter 1: </th>
        <th>Counter 2: </th>
        <th>Counter 3: </th>
      </tr>
      <tr>
        <th>Counter 4: </th>
        <th>Counter 5: </th>
        <th>Counter 6: </th>
      </tr>
      <tr>
        <th>Counter 7: </th>
      </tr>
    </table>
    <mat-divider></mat-divider>
    <h4>Soft skills </h4>
    <table>
      <tr>
        <th>Counter 1: </th>
        <th>Counter 2: </th>
        <th>Counter 3: </th>
      </tr>
      <tr>
        <th>Counter 4: </th>
        <th>Counter 5: </th>
        <th>Counter 6: </th>
      </tr>
      <tr>
        <th>Counter 7: </th>
      </tr>
    </table>
  </div>
  <br />
  <h3 class="headers" [matTooltip]="'here comes an info box on hover'"> Achievement’s portfolio </h3>
  <div class="containers">
    <ul>
      <li>Projects executed: …</li>
      <li>Articles published: …</li>
      <li>Contests won: …</li>
    </ul>
    <mat-divider></mat-divider>
    <h4>Article title ... </h4>
    <mat-divider></mat-divider>
    <h4>Project title ... </h4>
  </div> -->
  <br />
  <h3 class="headers"> Portfolio </h3>
  <section *ngIf="profile?.portfolio.length">
    <div *ngFor="let portf of profile?.portfolio">
      <h5>
        {{portf.title}}
      </h5>
      <div>
        {{portf.description}}
      </div>
      <div *ngFor="let file of portf.files">
        <a [href]="getUrl(file.id) | secure | async" download="{{ file.filename }}">
          <span>{{ file.filename }}</span>
        </a>
      </div>
      <mat-divider *ngIf="profile?.portfolio.length > 0"></mat-divider>
      <br />
    </div>
  </section>
  <div *ngIf="!profile?.portfolio.length">No portfolio listed</div>

  <br />
  <h3 class="headers"> Qualifications </h3>
  <section *ngIf="profile?.qualifications.length">
    <div *ngFor="let portf of profile?.qualifications">
      <h5>
        {{portf.description}} - {{portf.organization}} ({{portf.foryear}})
      </h5>
      <div>
        {{portf.summary}}
      </div>
      <div>
        {{portf.certurl}}
      </div>

      <mat-divider *ngIf="profile?.qualifications.length > 0"></mat-divider>
      <br />
    </div>
  </section>
  <div *ngIf="!profile?.qualifications.length">No qualifications listed</div>

  <br />
  <h3 class="headers"> Skills </h3>
  <section *ngIf="profile?.skills.length">
    <div *ngFor="let portf of profile?.skills">
      <h5>
        {{portf.industry}}
      </h5>
      <div class="skills-container">
        <div *ngFor="let skill of portf.skills" class="skill-container">
          <span>{{ skill.skill }}</span>
        </div>
      </div>
      <mat-divider *ngIf="profile?.skills.length > 0"></mat-divider>
      <br />
    </div>
  </section>
  <div *ngIf="!profile?.skills.length">No skills listed</div>
</div>


<div *ngIf="entity">
  <div>
    <div>
      <img [src]="getUrl(entity?.logofileid) | secure | async" alt="photo" class="profile-pic"
        *ngIf="entity?.logofileid">
      <div *ngIf="!entity?.logofileid" class="profile-initial">
        {{ entity?.orgname ? entity?.orgname[0].toUpperCase() : '' }}
      </div>
    </div>
    <span fxFlex></span>
    <div>
      <div class="name">
        {{entity?.orgname ? entity?.orgname : 'No organization name listed'}}
      </div>
      <div>
        {{entity?.website ? entity?.website : 'No website listed'}}
      </div>
    </div>

    <!-- <div class="activity-icon">
      <button (click)="followUnfollow()">
        <img src="/assets/follow-small.svg">
        {{entity?.following ? 'Unfollow' : 'Follow'}}
      </button>

      <button [matMenuTriggerFor]="shareMenu">
        <img src="/assets/share-small.svg">Share
      </button>

      <button (click)="report()">
        <img src="/assets/report-small.svg">Report
      </button>

      <button (click)="openMessageDialog()">
        <img src="/assets/message-small.svg">Message
      </button>
    </div> -->
  </div>

  <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
    <form [formGroup]="shareForm" (click)="$event.stopPropagation()">
      <p>Share to:</p>
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-select [items]="myShareSets" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="value"
          bindLabel="name" [clearable]="true" formControlName="person" [notFoundText]="'No Results'"
          typeToSearchText="Search" placeholder="Person">
        </ng-select>

        <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="uuid"
          bindLabel="name" [clearable]="true" formControlName="mynetwork" [notFoundText]="'No Results'"
          typeToSearchText="Search" placeholder="My Network">
        </ng-select>

        <button type="button" mat-stroked-button (click)="share()">Share</button>
      </div>
    </form>
  </mat-menu>

  <!--  <mat-menu #sendMenu="matMenu" class="custom-mat-menu">
        <form [formGroup]="messageForm" (click)="$event.stopPropagation()">
          <p>Send to:</p>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-form-field class="custom-form-field" appearance="outline">
              <mat-label>Subject</mat-label>
              <input matInput placeholder="Subject" formControlName="subject">
            </mat-form-field>
      
            <mat-form-field class="custom-form-field" appearance="outline">
              <mat-label>Mesage</mat-label>
              <textarea matInput placeholder="Message" formControlName="message"></textarea>
            </mat-form-field>
      
            <button type="button" mat-stroked-button (click)="sendMessage()">Send</button>
          </div>
        </form>
    </mat-menu>  -->


  <div>
    {{entity?.description ? entity?.description : 'No description listed'}}
  </div>

  <div>
    {{entity?.orgtype ? entity?.orgtype : 'No organization type listed'}}
    <br />
    {{entity?.industry ? entity?.industry : 'No industry listed'}}
    <br />
    {{entity?.legaltype ? entity?.legaltype : 'No legal type listed'}}
  </div>

</div>