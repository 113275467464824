<div class="container">
  <section class="initialuserlogin~">
    <div id="initialusercard~" class="card initialusercard~">
      <header class="card-header">

        <button *ngIf="this?.profile?.entityid?.draft === -1" class="organization-button" mat-flat-button type="button"
          (click)="openOrganizationEntity()">
          <mat-icon>apartment</mat-icon>
          <span>{{'profile.manage.entity.header' | translate}}</span>
        </button>

        <button *ngIf="this?.profile?.entityid?.draft === 1" mat-stroked-button class="export-button" color="primary"
          type="button" (click)="openUserEntity()">
          <mat-icon>manage_accounts</mat-icon>
          <span>{{'profile.manage.talent.header' | translate}}</span>
        </button>
        <!-- <button mat-stroked-button class="export-button" color="primary" type="button" (click)="openMentorEntity()">
        <mat-icon svgIcon="trophy"></mat-icon>
        Create Mentor
      </button> -->
      </header>
      <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
        <mat-tab [label]="'profile.profile-information.tab' | translate">
          <main class="card-container">
            <form [formGroup]="profileForm" (ngSubmit)="submit()" class="info-form">
              <div class="image-wrapper">
                <div class="image-wrapper-inner">
                  <ng-container *ngFor="let i of list">
                    <div class="img-container" (click)="openImageCropper()">
                      <img src="../../../../assets/login-user.svg" alt="Profile Picture" class="image"
                        *ngIf="!hasProfilePicture">
                      <img [src]="profilePictureUrl | secure | async" alt="Profile Picture" class="image"
                        *ngIf="hasProfilePicture">
                    </div>
                    <div class="middle" (click)="openImageCropper()">
                      <mat-icon>camera_alt</mat-icon>
                      <div>{{'profile.profile-information.picture' | translate}}</div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-form-field appearance="outline">
                  <mat-label>{{'profile.profile-information.first-name.label' | translate}}</mat-label>
                  <input matInput [placeholder]="'profile.profile-information.first-name.placeholder' | translate"
                    formControlName="firstname">
                  <mat-error *ngIf="profileForm.get('firstname').hasError('required')">
                    {{'profile.profile-information.first-name.error' | translate}}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{'profile.profile-information.last-name.label' | translate}}</mat-label>
                  <input matInput [placeholder]="'profile.profile-information.last-name.placeholder' | translate"
                    formControlName="lastname">
                  <mat-error *ngIf="profileForm.get('lastname').hasError('required')">
                    {{'profile.profile-information.last-name.error' | translate}}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{'profile.profile-information.telephone.label' | translate}}</mat-label>
                  <input matInput [placeholder]="'profile.profile-information.telephone.placeholder' | translate"
                    formControlName="telephone">
                  <mat-error *ngIf="profileForm.get('telephone').hasError('required')">
                    {{'profile.profile-information.telephone.error' | translate}}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{'profile.profile-information.headline.label' | translate}}</mat-label>
                  <input matInput [placeholder]="'profile.profile-information.headline.placeholder' | translate"
                    formControlName="headline">
                </mat-form-field>
              </div>
              <div fxLayout="column" fxLayoutAlign="center end" class="save-btn-container">
                <button mat-button class="add-btn" [class.spinner]="loading" type="submit">
                  {{'profile.profile-information.save-button.text' | translate}}
                </button>
              </div>
            </form>
          </main>
        </mat-tab>
        <mat-tab [label]="'profile.password-settings.tab' | translate">
          <main class="card-container">
            <form [formGroup]="pwdRenewForm" (ngSubmit)="submitPwdRenew()" class="info-form">
              <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px" style="margin-top:1rem">
                <mat-form-field appearance="outline">
                  <mat-label>{{'profile.password-settings.old-password.label' | translate}}</mat-label>
                  <input matInput type="password"
                    [placeholder]="'profile.password-settings.old-password.placeholder' | translate"
                    formControlName="old_password">
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{'profile.password-settings.new-password.label' | translate}}</mat-label>
                  <input matInput type="password"
                    [placeholder]="'profile.password-settings.new-password.placeholder' | translate"
                    formControlName="new_password">
                  <mat-error *ngIf="pwdRenewForm.get('new_password').hasError('minlength')">
                    {{'profile.password-settings.new-password.error' | translate}}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{'profile.password-settings.confirm-password.label' | translate}}</mat-label>
                  <input matInput type="password"
                    [placeholder]="'profile.password-settings.confirm-password.placeholder' | translate"
                    formControlName="repeat_new_password">
                  <mat-error
                    *ngIf="pwdRenewForm.hasError('passwordMismatch') && (pwdRenewForm.get('new_password').dirty || pwdRenewForm.get('repeat_new_password').dirty)">
                    {{'profile.password-settings.confirm-password.error' | translate}} </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="column" fxLayoutAlign="center end" class="save-btn-container">
                <button mat-button class="add-btn" [class.spinner]="loading" type="submit">
                  {{'profile.password-settings.save-button.text' | translate}}
                </button>
              </div>
            </form>
          </main>
        </mat-tab>
      </mat-tab-group>
    </div>
  </section>
</div>