<div class="main-container">

  <div class="navigation">
    <div class="container">

      <mat-toolbar class="mobile-toolbar">

        <div *ngIf="profile?.entityid?.draft === 1" class="mobile-logo-container">
          <img alt="icon" src="../../assets/logo-final.svg" class="mobile-logo mobile-button-image"
            [routerLink]="'dashboard'" />
          <button mat-button class="mobile-button" [routerLink]="'dashboard/nav-tab/newsfeed'" type="button">
            <mat-icon style="margin-right: 0.5rem">menu</mat-icon> <span>Menu</span>
          </button>
        </div>

        <div *ngIf="profile?.entityid?.draft === -1" class="mobile-logo-container">
          <img alt="icon" src="../../assets/logo-final.svg" class="mobile-logo" (click)="navigateToCurrentUrl()" />
        </div>
        <div class="icon-actions">

          <div class="mat-search-bar">
            <div [matMenuTriggerFor]="menuSearch">
              <mat-icon style="font-size: 1.5em;">search</mat-icon>
            </div>
            <mat-menu #menuSearch="matMenu" xPosition="after">
              <button mat-menu-item *ngFor="let option of filteredOptions | async" [value]="option"
                (click)="filterByCategory(option)"> <span>{{ 'nav-bar.search.'+option | translate }}</span> </button>
            </mat-menu>
          </div>

          <div class="message-item">
            <button mat-mini-fab class="hover-color" (click)="goToInbox()">
              <mat-icon [matBadge]="messagesCount" matBadgeSize="small" matBadgePosition="top after" svgIcon="message">
              </mat-icon>
            </button>
          </div>

          <div class="notification-item">
            <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
              (click)="openNotificationsDialog()">
              <mat-icon [matBadge]="notificationCount" matBadgeSize="small" matBadgePosition="top after" svgIcon="bell">
              </mat-icon>
            </button> -->

            <button mat-icon-button [matMenuTriggerFor]="customMenu" aria-label="Example icon-button with a menu">
              <mat-icon [matBadge]="notificationCount" matBadgeSize="small" matBadgePosition="top after" svgIcon="bell">
              </mat-icon>
            </button>

            <mat-menu #customMenu="matMenu">
              <div class="notification-header" fxLayout="row" fxLayoutAlign="space-between center">
                <h5 style="font-size: .875rem; color:#39527b;margin-bottom: 2px">You Have {{notificationCount}} Unread
                  Notification</h5>
                <button mat-button (click)="seeAllButton()">See all</button>
              </div>
              <div fxLayout="column" (click)="$event.stopPropagation()">
                <span *ngFor="let notification of notificationResponse?.data?.notifications" mat-menu-item>
                  <div fxLayout="row" fxLayoutAlign="space-between">
                    <div fxLayout="column">
                      <h6 class="notification-subject">{{notification?.subject}}</h6>
                      <p class="notification-date">{{notification?.ts | date: 'MMM d, y, h:mm a'}}</p>
                    </div>
                    <span *ngIf="!notification.is_read" mat-icon-button (click)="markRead(notification.notifid)"
                      [matTooltip]="'Mark as read'" style="position: absolute; right: 0; bottom: 8px;">
                      <mat-icon>done</mat-icon>
                    </span>
                  </div>
                </span>
              </div>
            </mat-menu>


          </div>

          <div class="menu-dropdown-container">

            <div class="profile-item" mat-button (click)="getUserNameMobile(menu)" [matMenuTriggerFor]="menu"
              #menuTrigger="matMenuTrigger" [fxHide.xs]="true">
              <mat-spinner *ngIf="isLoading" diameter="30" color="secondary"></mat-spinner>
              <span *ngIf="!isLoading" class="menu-text">{{ button_label ? button_label : profile?.firstname }}</span>
              <mat-icon>arrow_drop_down</mat-icon>
            </div>


            <div *ngIf="pictureUrl ; else noPicture" class="hamburger" [matMenuTriggerFor]="menu">
              <button mat-button (click)="getUserNameMobile(menu)" class="profile-circle-button">
                <img [src]="pictureUrl | secure | async" *ngIf="pictureUrl" />
              </button>
            </div>

            <ng-template #noPicture>
              <div class="hamburger" [matMenuTriggerFor]="menu">
                <button color="basic" (click)="getUserNameMobile(menu)" class="profile-circle-button-no-img">
                  <div *ngIf="profile?.firstname">{{profile?.firstname[0].toUpperCase()}}</div>
                  <!-- <div>No user data</div> -->
                </button>
              </div>
            </ng-template>
            <!-- <app-language-dropdown></app-language-dropdown> -->

            <mat-menu #menu="matMenu" xPosition="before">

              <!-- <span class="talent-menu-button" mat-menu-item [matMenuTriggerFor]="profileMenu">
              <img style="border-radius: 50%; height: 25px; width: 25px;" *ngIf="(pictureUrl | secure | async)" [src]="pictureUrl | secure | async" />
              {{ profile?.firstname }} {{ profile?.lastname }}
            </span> -->

              <button mat-menu-item [routerLink]="'/profile'">
                <mat-icon>account_circle</mat-icon>
                <span>{{ 'nav-bar.profile.my-profile' | translate }}</span>
              </button>
              <mat-divider></mat-divider>
              <app-language-dropdown [design]="'menubutton'"></app-language-dropdown>
              <mat-divider></mat-divider>
              <div *ngIf="!profile?.entityid?.draft || profile?.entityid?.draft === -1">
                <span
                  style="margin:16px 16px 6px;display:block;color:#5E5D5D;font-size:12px;">{{'nav-bar.profile.companies'
                  | translate}}</span>
                <button mat-menu-item *ngFor="let entity of entityList" [matMenuTriggerDisabled]="true"
                  (click)="switchToEntity(entity.eid, menuTrigger)">
                  <mat-icon>apartment</mat-icon>
                  <span>{{ entity.orgname }}</span>
                </button>
                <mat-divider></mat-divider>
              </div>
              <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>{{ 'nav-bar.profile.logout' | translate }}</span>
              </button>
            </mat-menu>

            <!-- <mat-menu #profileMenu="matMenu">
            <button mat-menu-item [routerLink]="'/profile'">
              <mat-icon>account_circle</mat-icon>
              <span>My Profile</span>
            </button>
            <button mat-menu-item (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu> -->

          </div>
          <!-- END MENU CONTAINER -->



        </div>

      </mat-toolbar>
    </div>
  </div>
  <router-outlet (activate)="activatedComponent($event)"></router-outlet>
</div>