import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { COMPLETED_STATE } from '../../../common/const';
import { ProfileService } from '../../_services/profile.service';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { OrganizationEntityComponent } from '../organization-entity/organization-entity.component';
import { UserEntityComponent } from '../user-entity/user-entity.component';
import { DialogConfirmComponent } from '../../../common/dialog-confirm/dialog-confirm.component';
import { environment } from 'src/environments/environment';
import { passwordMisMatchValidator } from '../../../common/error-handler/customValidators';
import { AuthService } from '../../../auth/_services/auth.service';
import { SnackbarMessagesComponent } from 'src/app/common/snackbar-messages/snackbar-messages.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
  providers: [DialogConfirmComponent]
})
export class ProfileFormComponent implements OnInit {

  profileForm: FormGroup;
  pwdRenewForm: FormGroup;
  loading = false;
  COMPLETED = COMPLETED_STATE;
  hasProfilePicture = false;
  errors: any = {};
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  list = [1];
  profile;
  snackbarMessages: SnackbarMessagesComponent

  constructor(
    private fb: FormBuilder,
    private service: ProfileService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private confirmDialog: DialogConfirmComponent,
    private translate: TranslateService,
  ) {
    this.snackbarMessages = new SnackbarMessagesComponent(snackbar, translate)

    this.matIconRegistry.addSvgIcon('company', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/company.svg'))
    this.matIconRegistry.addSvgIcon('crown-small', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/crown-small.svg'))
    this.matIconRegistry.addSvgIcon('trophy', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/trophy.svg'))

    this.profileForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      telephone: ['', [Validators.required, Validators.minLength(10)]],
      headline: [''],
    });

  }

  ngOnInit(): void {
    this.getInitialData();
    this.pwdRenewForm = this.getChangePasswordForm();
  }

  public getChangePasswordForm(): FormGroup {
    const passwordValidators = [Validators.required, Validators.maxLength(128), Validators.minLength(8)];
    return this.fb.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [...passwordValidators]],
      repeat_new_password: ['', [...passwordValidators]]
    }, { validators: passwordMisMatchValidator });
  }

  getInitialData(): any {
    this.service.getUserProfile().subscribe(resp => {
      this.profile = resp.data;
      this.profileForm.patchValue(resp['data']);
      if (resp['data']['profilepicture']) {
        this.hasProfilePicture = true;
      }
    });
  }

  redirectTo(uri: string) {
    //console.log('should redirect now!' + this.router.url);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }

  submit(): any {
    if (this.profileForm.valid) {
      this.loading = true;
      const formValues = Object.assign({}, this.profileForm.value);
      this.service.postUserProfile(formValues)
        .subscribe(
          () => {
            this.onSuccess();
            this.loading = false;

            if (this.profile.entityid.draft === -1) {
              if (this.profile.firstname === "" || this.profile.lastname === "" || this.profile.telephone === "") {
                this.router.navigate(['/organization']).then(() => window.location.reload());
                return this.dialog.closeAll();
              }
            } else if (this.profile.entityid.draft === 1) {
              if (this.profile.firstname === "" || this.profile.lastname === "" || this.profile.telephone === "") {
                this.router.navigate(['/expert']).then(() => window.location.reload());
                return this.dialog.closeAll();
              }
            }
            window.location.reload()
          },
          (error) => this.onError(error));
    } else {
      this.loading = false;
    }
  }

  submitPwdRenew() {
    if (!this.pwdRenewForm.invalid) {
      this.confirmDialog.message = 'Are you sure you want to change your password?';
      this.confirmDialog.openDialog((res) => {
        if (res) {
          this.authService.changeProfilePassword(this.pwdRenewForm.value).subscribe(
            this.snackbar.open('Password changed successfully!', 'Close', { duration: 3000, panelClass: 'success-snackbar' }),
            error => this.onError(error)
          );
        }
      });
    }
  }

  handleFileInput(event): any {
    const formData: FormData = new FormData();
    const file: File = event.target.files[0];
    formData.append('filecontent', file, file.name);
    this.service.uploadProfilePicture(formData).subscribe(() => {
      this.list = [2];
    }, () => {
    });
  }

  openOrganizationEntity(): any {
    if (this.profileForm.valid) {
      this.router.navigateByUrl('/organization', { skipLocationChange: false });
      this.dialog.closeAll();
    } else {
      this.snackbar.open('Please create your profile first.', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    }
  }

  openUserEntity(): any {
    if (this.profileForm.valid) {
      this.router.navigateByUrl('/expert', { skipLocationChange: false });
      this.dialog.closeAll();
    } else {
      this.snackbar.open('Please create your profile first.', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    }
  }

  openMentorEntity(): any {
    this.router.navigate(['/mentor']);
    this.dialog.closeAll();
  }

  openImageCropper(): any {
    this.dialog.open(ImageCropperComponent, { width: '800px', autoFocus: false, maxHeight: '80vh', maxWidth: '100%' })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.hasOperations) {
          this.list = [3];
        }
      });
  }

  onSuccess(): any {
    this.snackbarMessages.onSuccess()
  }

  onError(error): any {
    this.snackbar.open(error.data.msg ? error.data.msg :
      this.snackbarMessages.onError())
  }
}
