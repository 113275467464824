import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../../_services/dashboard.service';
import { ProfileService } from 'src/app/profile/_services/profile.service';
import { CommonService } from 'src/app/common/_services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContestService } from 'src/app/contest/_services/contest.service';
import { ArticleService } from 'src/app/article/_services/article.service';
import { ProjectService } from 'src/app/project/_services/project.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-entity-profile-card',
  templateUrl: './entity-profile-card.component.html',
  styleUrls: ['./entity-profile-card.component.scss']
})
export class EntityProfileCardComponent implements OnInit {
  @Input() data;

  logoRefresh = [1];
  watcher: Subscription;
  isMobile;
  messageForm
  shareForm
  sendForm
  newsfeedShareForm
  commentForm
  constructor(
    private router: Router,
    private service: DashboardService,
    private userService: ProfileService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private contestService: ContestService,
    private articleService: ArticleService,
    private projectService: ProjectService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mediaObserver: MediaObserver
  ) {
    this.matIconRegistry.addSvgIcon(
      'postSettings',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/post-settings.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'like',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/like.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'liked',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/liked.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'comment',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/comment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'share',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/share.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'send',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/send.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'post-image',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/post-image.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'instagram',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/mobile-icon/instagram.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/mobile-icon/facebook.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'twitter',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/mobile-icon/twitter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'crown',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/crown.svg')
    );

    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.isMobile = change.mqAlias == 'xs';
    });

    // this.entityId = this.route.snapshot.paramMap.get('id');
    // this.route.params.subscribe((params) => {
    //   this.entityId = params.id;
    //   this.getAllData();
    // });
    this.commonService.changeData(true);

    this.messageForm = fb.group({
      subject: [''],
      message: [''],
    });

    this.shareForm = fb.group({
      person: [null],
      mynetwork: [null],
    });

    this.sendForm = fb.group({
      userid: [null],
    });

    this.newsfeedShareForm = fb.group({
      mynetwork: [null],
    });

    this.commentForm = fb.group({
      comment: [''],
    });
  }

  ngOnInit(): void {
  }

  public modifyCompany(id) {
    console.log(id);

    // this.router.navigate(['organization', { id }]).then();
  }

}
