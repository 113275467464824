<div class="main-div">
    <div class="read-card">
        <mat-card class="project-main-container mat-elevation-z3">
            <div class="project-title-container">
                <h1>{{project?.title}}</h1>
                <div class="project-meta">
                    <div>Start date <span>{{project?.startdate | date: 'mediumDate'}}</span></div>
                    <!-- <div>End date <span>{{project?.enddate | date: 'mediumDate'}}</span></div> -->
                    <div>Applications: <span>{{project?.applicants_count}}</span></div>
                    <div>Applications In-Progress: <span>{{project?.in_progress_apps}}</span></div>
                </div>
            </div>
            <br />

            <section>
                <!-- <div *ngFor="let file of files" fxLayout="row" fxLayoutAlign="space-between center">
                    <div *ngIf="file?.id === project?.leadingimage" class="project-image">
                        <img [src]="getUrl(file.id) | secure | async" alt="photo">
                    </div>
                </div> -->

                <div class="project-description-container">
                    <p>Description</p>
                    <div class="contest-description-text">
                        <quill-view-html [content]="project?.description"></quill-view-html>
                    </div>
                </div>

                <br />

                <div class="project-tasks-container">
                    <p>Requirements</p>
                    <div class="table-responsive">
                        <table class="project-tasks-table">
                            <thead>
                                <tr>
                                    <th scope="col">Education</th>
                                    <th scope="col">Experience</th>
                                    <th scope="col">Skills</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let requirement of project?.requirements">
                                    <td>{{ requirement?.education }}</td>
                                    <td>{{ requirement?.experience }}</td>
                                    <td>
                                        <span *ngFor="let skill of requirement?.skills; let last = last">{{ skill.name
                                            }}{{ !last ? ', ' :
                                            '' }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <br />

                <div class="project-tasks-container">
                    <p>Tasks</p>
                    <div class="table-responsive">
                        <table class="project-tasks-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Start date</th>
                                    <th scope="col">End date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let task of project?.tasks">
                                    <td>{{ task.taskname }}</td>
                                    <td>{{ task.taskdescription }}</td>
                                    <td>{{ task.taskstartdate | date: 'mediumDate' }}</td>
                                    <td>{{ task.taskenddate | date: 'mediumDate' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br>
                <!-- <div *ngIf="project?.leadingimage && files?.length - 1 > 0" class="project-description-container"
                    fxLayout="column wrap">
                    <p>Additional resources</p>
                    <div *ngFor="let file of files" fxLayout="row" fxLayoutAlign="space-between left">
                        <a *ngIf="file?.id !== project?.leadingimage" [href]="getUrl(file.id) | secure | async"
                            download="{{ file.name }}">
                            <span>{{ file.name }}</span>
                            <span class="file-size">({{ file.sizebytes | fileSize }})</span>
                        </a>
                    </div>
                </div> -->
            </section>

            <br />

            <section>
                <div class="project-reward-container">
                    <p>Reward</p>
                    <div class="project-reward">
                        <!-- <h3>{{contest?.rewardheader}}</h3> -->
                        <div class="">
                            <quill-view-html [content]="project?.reward"></quill-view-html>
                        </div>
                    </div>
                </div>

                <!-- <div class="footer">
                    <button mat-stroked-button class="action-btn search-bar-button" (click)="applyButtonProject()">
                      <span>Apply</span>
                    </button>
                </div> -->
            </section>

            <!-- <app-social-media-controlls [content]="project"></app-social-media-controlls> -->

        </mat-card>
    </div>

</div>