import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-small-contest-card',
  templateUrl: './small-contest-card.component.html',
  styleUrls: ['./small-contest-card.component.scss']
})
export class SmallContestCardComponent implements OnInit {
  @Input() contests;
  leadingPictureURL = `${environment.API_HOST}/files/profilepicture/`;

  constructor(
    private service: DashboardService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router

  ) { }
  ngOnInit(): void {
  }

  getLeadContestImage(id): any {
    return this.leadingPictureURL + id;
  }

  editContest(contest): any {
    this.router.navigate(['contest/read', { id: contest.contestid, entityId: contest.ownerid, }]).then();
  }
}
