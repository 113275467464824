<div class="background">
    <section class="main-section">
        <img alt="icon" class="auth-logo" src="../../assets/logo-final-white.svg" style="cursor: pointer;">
        <div class="form-wrapper">
            <h4 style="text-align: center;"> Reset your password</h4>
            <form [formGroup]="resetPass" (ngSubmit)="reset()" class="info-form">
                <app-language-dropdown></app-language-dropdown>
                <div fxLayout="column" fxLayoutAlign="start center" style="margin-top:1rem">
                    <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 0.5em;">
                        <mat-label>New Password</mat-label>
                        <input matInput type="password" placeholder="Enter New Password" formControlName="new_password">
                        <mat-error *ngIf="resetPass.get('new_password').hasError('minlength')">
                            New Password must be at least 8 characters long
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Confirm New Password</mat-label>
                        <input matInput type="password" placeholder="Confirm New Password"
                            formControlName="repeat_new_password">
                        <mat-error
                            *ngIf="resetPass.hasError('passwordMismatch') && (resetPass.get('new_password').dirty || resetPass.get('repeat_new_password').dirty)">
                            Passwords must match
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" class="save-btn-container">
                    <button mat-button class="add-btn" [class.spinner]="loading" type="submit">
                        Reset
                    </button>
                </div>
            </form>
        </div>
    </section>
</div>