<div class="profile-card">
    <!-- <input type="file" #input1  accept="image/*" (change)="changeLogo($event)"> -->
    <div class="logo-info-container">
        <div *ngFor="let i of logoRefresh">
            <div class="logo-container mat-elevation-z2" (click)="input1.click()">
                <img class="image" src="../../../../assets/logo-final.png" alt="logo" class="logo-image"
                    *ngIf="!hasLogo">
                <img class="image" [src]="getLogoUrl() | secure | async" alt="logo" class="logo-image" *ngIf="hasLogo">
                <div class="middle">
                    <mat-icon>camera_alt</mat-icon>
                    <div class="span">Change Logo</div>
                </div>
            </div>
        </div>
        <div class="text-block">
            <div class="name">{{data.entity?.orgname}}</div>
            <div class="designation">{{data.entity?.website}}</div>
            <div class="place">{{data.entity?.industry}}</div>
            <!-- <div class="social-block">
                <button mat-icon-button>
                    <mat-icon svgIcon="instagram"></mat-icon>
                </button>
                <button mat-icon-button>
                    <mat-icon svgIcon="twitter"></mat-icon>
                </button>
                <button mat-icon-button>
                    <mat-icon svgIcon="facebook"></mat-icon>
                </button>
            </div> -->
        </div>
    </div>
    <div class="project-spread-container">
        <div class="active-project">
            <span class="project-label">Articles</span>
            <h3 class="count">{{ data.articles?.length > 0 ? data.articles?.length : 0 }}</h3>
        </div>
        <div class="in-progress">
            <span class="project-label">Contests</span>
            <h3 class="count">{{data.contests?.length > 0 ? data.contests?.length : 0}}</h3>
        </div>
        <div class="complete">
            <span class="project-label">Projects</span>
            <h3 class="count">{{ data.projects?.length > 0 ? data.projects?.length : 0 }}</h3>
        </div>
    </div>
    <div class="edit-icon">
        <button (click)="modifyCompany(data)">
            <mat-icon class="edit-button">edit</mat-icon>
        </button>
    </div>
</div>