import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { NotificationDialogComponent } from './_components/notification-dialog/notification-dialog.component';
import { MailDialogComponent } from './_components/mail-dialog/mail-dialog.component';
import { ChallengesDialogComponent } from './_components/challenges-dialog/challenges-dialog.component';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '../pipes/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuillModule } from 'ngx-quill';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ManageMessagesComponent } from './_components/manage-messages/manage-messages.component';
import { ManageNotificationsComponent } from './_components/manage-notifications/manage-notifications.component';
import { NewsfeedCardComponent } from './_components/newsfeed-card/newsfeed-card.component';
import { EntityHomepageComponent } from './_components/entity-homepage/entity-homepage.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { ExpertPublicProfileComponent } from './_components/expert-public-profile/expert-public-profile.component';
import { MentorPublicProfileComponent } from './_components/mentor-public-profile/mentor-public-profile.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { BrowseComponent } from './_components/browse/browse.component';
import { BrowseCategoriesDialogComponent } from './_components/browse-categories-dialog/browse-categories-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree'
import { MatExpansionModule } from '@angular/material/expansion';
import { SmallContentCardsComponent } from './_components/small-content-cards/small-content-cards.component';
import { SmallPeopleExpertsContainerComponent } from './_components/small-people-experts-container/small-people-experts-container.component';
import { SmallContestCardComponent } from './_components/small-contest-card/small-contest-card.component';
import { SmallProjectCardComponent } from './_components/small-project-card/small-project-card.component';
import { SmallArticleCardComponent } from './_components/small-article-card/small-article-card.component';
import { NewsfeedComponent } from './_components/newsfeed/newsfeed.component';
import { MobileContentComponent } from './_components/mobile-content/mobile-content.component';
import { MobileConnectComponent } from './_components/mobile-connect/mobile-connect.component'
import { MatRippleModule } from '@angular/material/core';
import { EntityArticleViewComponent } from './_components/entity-article-view/entity-article-view.component';
import { EntityProjectViewComponent } from './_components/entity-project-view/entity-project-view.component';
import { EntityContestViewComponent } from './_components/entity-contest-view/entity-contest-view.component';
import { EntityContentContestComponent } from './_components/entity-content-contest/entity-content-contest.component';
import { EntityContentProjectComponent } from './_components/entity-content-project/entity-content-project.component';
import { EntityContentArticleComponent } from './_components/entity-content-article/entity-content-article.component';
import { EntityEditContentComponent } from './_components/entity-edit-content/entity-edit-content.component';
import { EntityProfileCardComponent } from './_components/entity-profile-card/entity-profile-card.component';
import { SmallTalentCardComponent } from './_components/small-talent-card/small-talent-card.component';
import { EntityViewArticleComponent } from './_components/entity-view-article/entity-view-article.component';
import { EntityViewContestComponent } from './_components/entity-view-contest/entity-view-contest.component';
import { SocialMediaControllsComponent } from './_components/social-media-controlls/social-media-controlls.component';
import { EntityViewProjectComponent } from './_components/entity-view-project/entity-view-project.component';
import { AddContactModalComponent } from './_components/add-contact-modal/add-contact-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { DashboardTabsRoutingModule } from '../navigation/dashboard-tabs-routing/dashboard-tabs-routing.module';
import { TalentMobileChallengesTabComponent } from './_components/talent-mobile-challenges-tab/talent-mobile-challenges-tab.component';
import { TalentMobileNewsfeedTabComponent } from './_components/talent-mobile-newsfeed-tab/talent-mobile-newsfeed-tab.component';
import { TalentMobileProfileTabComponent } from './_components/talent-mobile-profile-tab/talent-mobile-profile-tab.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ArticleModule } from '../article/article.module';
import { TalentProfileStatsComponent } from './_components/talent-profile-stats/talent-profile-stats.component';
import { TalentActivitiesComponent } from './_components/talent-activities/talent-activities.component';
import { AuthModule } from '../auth/auth.module';
import { TranslateModule } from '@ngx-translate/core';
import { TalentProjectsListComponent } from './_components/talent-projects-list/talent-projects-list.component';
import { TalentContestsListComponent } from './_components/talent-contests-list/talent-contests-list.component';

@NgModule({
  declarations: [
    DashboardComponent,
    NotificationDialogComponent,
    MailDialogComponent,
    ChallengesDialogComponent,
    ManageMessagesComponent,
    ManageNotificationsComponent,
    NewsfeedCardComponent,
    EntityHomepageComponent,
    ExpertPublicProfileComponent,
    MentorPublicProfileComponent,
    BrowseComponent,
    BrowseCategoriesDialogComponent,
    SmallContentCardsComponent,
    SmallPeopleExpertsContainerComponent,
    SmallContestCardComponent,
    SmallProjectCardComponent,
    SmallArticleCardComponent,
    NewsfeedComponent,
    MobileContentComponent,
    MobileConnectComponent,
    EntityArticleViewComponent,
    EntityProjectViewComponent,
    EntityContestViewComponent,
    EntityContentContestComponent,
    EntityContentProjectComponent,
    EntityContentArticleComponent,
    EntityEditContentComponent,
    EntityProfileCardComponent,
    SmallTalentCardComponent,
    EntityViewArticleComponent,
    EntityViewContestComponent,
    SocialMediaControllsComponent,
    EntityViewProjectComponent,
    AddContactModalComponent,
    TalentMobileChallengesTabComponent,
    TalentMobileNewsfeedTabComponent,
    TalentMobileProfileTabComponent,
    TalentProfileStatsComponent,
    TalentActivitiesComponent,
    TalentProjectsListComponent,
    TalentContestsListComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    DashboardTabsRoutingModule,
    ArticleModule,
    FormsModule,
    MatCheckboxModule,
    MatListModule,
    MatIconModule,
    FlexLayoutModule,
    MatTreeModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    PipesModule,
    MatTooltipModule,
    QuillModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgSelectModule,
    MatTabsModule,
    MatChipsModule,
    MatCardModule,
    IvyCarouselModule,
    MatAutocompleteModule,
    InfiniteScrollModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    TranslateModule
  ]
})
export class DashboardModule {
}
