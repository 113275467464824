<mat-card class="create-content">
    <mat-card-title class="create-content-header">{{'dashboard.middle-column.create-content.title' |
        translate}}</mat-card-title>
    <mat-card-actions class="create-content-button-container">
        <button class="create-article-button" (click)="createArticle()">
            <mat-icon class="create-article-button-icon">library_books</mat-icon>
            {{'dashboard.middle-column.create-content.create-article-button' |
            translate}}
        </button>
    </mat-card-actions>
</mat-card>
<div class="newsfeed-main-container" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300"
    (scrolled)="onScroll()">
    <div class="newsfeed-cards-container" *ngFor="let newsfeed of newsfeedHomepage" #lastElement>
        <app-newsfeed-card [newsfeed]="newsfeed" [entityId]="this.entityId"></app-newsfeed-card>
    </div>
</div>