SHARE BUTTON HERE



<div class="social-wrapper">
    <div class="social-pad">
    <div class="social-info-block">
      <div class="likes-wrapper">{{ newsfeed?.like_count || 0 }} Likes</div>
      <div class="comments-wrapper">
        <a href="javascript:void(0)" [matMenuTriggerFor]="readComments" (click)="getComments(contestId)" #commentTrigger="matMenuTrigger">{{ newsfeed?.commentcount || 0 }} Comments</a>
      </div>
    </div>
    </div>
    <div class="button-wrapper">
      <div class="button-likes"> 
        <button mat-button [color]="newsfeed?.is_liked ? 'primary' : ''" [matTooltip]="newsfeed?.is_liked ? 'Unlike' : 'Like'" (click)="likeUnlike(newsfeed)"> <mat-icon>thumb_up</mat-icon> Like</button> 
      </div>
      <div class="button-shares"> 
        <button mat-button matTooltip="Share the contest" [matMenuTriggerFor]="shareMenu" #shareTrigger="matMenuTrigger"> <mat-icon>reply</mat-icon> Share</button> 
      </div>
      <div class="button-comments"> 
        <button mat-button matTooltip="Add New Comment" [matMenuTriggerFor]="postComment" #postTrigger="matMenuTrigger"> <mat-icon>comment</mat-icon> Comment</button> 
      </div>
    </div>
  </div>


        <!-- Comments -->
        <mat-menu #readComments="matMenu" class="custom-mat-menu">
            <div style="padding: 15px 20px 0 20px">
              <div *ngFor="let comment of comments" class="comment-unit">
                <mat-hint>{{comment.ts | date: 'medium'}}</mat-hint>
                <div>{{comment.comment}}</div>
              </div>
            </div>
          </mat-menu>
    
          <!-- Comments New -->
          <mat-menu #postComment="matMenu" class="custom-mat-menu">
            <form [formGroup]="commentForm" style="padding: 0 20px" fxLayout="column" (click)="$event.stopPropagation()"
              fxLayoutAlign="start center" fxLayoutGap="10px">
                <div>
                    <!-- <mat-form-field class="custom-form-field" appearance="outline">
                        <mat-label>Comment</mat-label>
                        <textarea matInput type="text" placeholder="Write a comment" formControlName="comment"></textarea>
                    </mat-form-field> -->
                </div>
    
              <button type="button" mat-stroked-button (click)="comment(contestId,postTrigger)">Comment</button>
            </form>
          </mat-menu>
    
          <!-- Share Form-->
          <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
            <form [formGroup]="shareForm" style="padding: 0 20px" (click)="$event.stopPropagation()">
              <p>Share to:</p>
              <div fxLayout="column" fxLayoutAlign="start center">
                <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body"
                  bindValue="uuid" bindLabel="name" [clearable]="true" formControlName="mynetwork"
                  [notFoundText]="'No Results'" typeToSearchText="Search" placeholder="My Network">
                </ng-select>
                <button type="button" mat-stroked-button (click)="share(shareTrigger)">Share</button>
              </div>
            </form>
          </mat-menu>