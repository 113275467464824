<mat-card *ngIf="!articles?.length">
    <mat-card-subtitle>
        {{'entity-dashboard.desktop-version.entity-content.articles-tab.no-content' | translate}}
    </mat-card-subtitle>
</mat-card>
<div *ngIf="articles" style="display: flex; flex-direction: column; gap: 1rem;cursor: pointer;"
    class="entity-desktop-article-wrapper">
    <mat-card mat-ripple *ngFor="let article of articles" (click)="editArticle(article)">
        <mat-card-title>{{article?.title}}</mat-card-title>
        <!-- <mat-card-subtitle>{{ 'entity-dashboard.desktop-version.entity-content.categories.' + article?.category |
            translate }}</mat-card-subtitle> -->
        <mat-card-content>
            <i>
                <quill-view-html
                    [content]="article?.abstract?.length > 150 ? (article?.abstract | slice:0:150) + '...' : article?.abstract"></quill-view-html>
            </i>
            <div class="picture-description-container">
                <img *ngIf="article?.mainimage" mat-card-md-image
                    [src]="getLeadArticleImage(article?.mainimage) | secure | async" alt="article image">
                <quill-view-html
                    [content]="article?.content?.length > 150 ? (article?.content | slice:0:150) + '...' : article?.content">
                </quill-view-html>
            </div>
        </mat-card-content>
        <mat-card-footer>
        </mat-card-footer>
    </mat-card>
</div>