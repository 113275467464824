import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContestService } from 'src/app/contest/_services/contest.service';
import { ProjectService } from 'src/app/project/_services/project.service';

@Component({
  selector: 'app-mobile-connect',
  templateUrl: './mobile-connect.component.html',
  styleUrls: ['./mobile-connect.component.scss']
})
export class MobileConnectComponent implements OnInit {
  entityId
  connections = [];
  constructor(
    private contestService: ContestService,
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getEntityId()
    this.getAllConnections()
  }


  getEntityId(): any {
    return this.route.parent.params.subscribe(id => {
      this.entityId = id.id
    })
  }

  getAllConnections(): any {
    return Promise.all([
      this.getContestApplicants(),
      this.getProjectApplicants()
    ])
  }

  getContestApplicants(): any {
    this.contestService.getContestConnections(this.entityId).subscribe(res => {
      const { userInfo } = res
      if (userInfo?.length > 0) {
        userInfo.forEach(user => {
          this.connections.push(user)
        });
      }
      console.log(this.connections);
    })
  }

  getProjectApplicants(): any {
    this.projectService.getProjectConnections(this.entityId).subscribe(res => {
      const { userInfo } = res
      if (userInfo?.length > 0) {
        userInfo.forEach(user => {
          this.connections.push(user)
        });
      }
    })
  }
}
