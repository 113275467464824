import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-entity-content-article',
  templateUrl: './entity-content-article.component.html',
  styleUrls: ['./entity-content-article.component.scss']
})
export class EntityContentArticleComponent implements OnInit {

  entityId;
  articles = [];

  constructor(
    private service: DashboardService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.route.parent.parent.params.subscribe(param => {
      this.entityId = param.id
    })
    this.getEntityArticles()
  }

  getEntityArticles(): any {
    this.service.getArticles(this.entityId).subscribe(resp => {
      this.articles = resp.data
    })
  }

  checkArticles(): any {
    return this.articles.length < 0
  }

}
