import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ArticleService } from '../../_services/article.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { DashboardService } from 'src/app/dashboard/_services/dashboard.service';
import { DialogConfirmComponent } from '../../../common/dialog-confirm/dialog-confirm.component';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ExpertPublicProfileComponent } from 'src/app/dashboard/_components/expert-public-profile/expert-public-profile.component';

@Component({
  selector: 'app-article-read',
  templateUrl: './article-read.component.html',
  styleUrls: ['./article-read.component.scss'],
  providers: [DialogConfirmComponent]
})
export class ArticleReadComponent implements OnInit {
  articleId;
  entityId;
  article;
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  list = [];
  files: Array<any>;
  //comments: any[];

  isMobile = false; // TODO for mobile

  picture;
  profile;
  userPersonalData;
  names;
  summary;
  entity;
  isOwner
  userId

  newsfeed;
  numberOfLikes: number = 0;
  commentForm: FormGroup;
  comments: Array<any>;
  shareForm: FormGroup;
  myNetwork;
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  constructor(
    private service: ArticleService,
    private route: ActivatedRoute,
    private location: Location,
    private dashService: DashboardService,
    private matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private confirmDialog: DialogConfirmComponent,
  ) {

    this.picture = this.route.snapshot.paramMap.get('picture');
    this.names = this.route.snapshot.paramMap.get('names');
    this.summary = this.route.snapshot.paramMap.get('summary');

    this.commentForm = fb.group({
      comment: [''],
    });

    this.shareForm = fb.group({
      mynetwork: [null],
    });

    this.matIconRegistry.addSvgIcon(
      'like',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/like.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'liked',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/liked.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'comment',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/comment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'share',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/share.svg')
    );
  }

  ngOnInit(): void {
    this.getAll()

    this.numberOfLikes = this.newsfeed.entity.likescount;

    // this.dashService.postProfileInfo(this.entityId).subscribe((resp) => {
    //   if (resp.data.expert) this.profile = resp.data.expert;
    //   else {
    //     this.entity = resp.data.entity;
    //     this.getEntity();
    //   }
    // });
  }


  getAll(): any {
    this.getUserProfile();
    this.getEntity()
    this.getIds()
    this.getArticle();
    this.getFiles();
  }

  checkIfOwner(): any {
    return this.entity.draft === 0
  }

  getUserProfile(): any {
    this.dashService.getUserProfile().subscribe(resp => {
      this.profile = resp.data;
      this.userId = resp.data.userid
    });
  }

  getUserById(id) {
    this.dashService.getUserProfileById(id).subscribe(resp => {
      this.userPersonalData = resp?.data;
    });
  }

  getIds(): any {
    Promise.all([
      this.getEntityId(),
      this.getArticleId()
    ])
  }

  getEntityId(): any {
    this.route.parent.params.subscribe(param => {
      this.entityId = param.entityId
    })
  }

  getArticleId(): any {
    this.route.params.subscribe(param => {
      this.articleId = param.articleId
    })
  }

  getEntity(): any {
    this.dashService.getEntity(this.route.snapshot.params.entityId).subscribe((resp) => {
      this.entity = resp.data[0];
    });
  }

  getArticle(): any {
    this.service.getArticle(this.route.snapshot.params.entityId, this.route.snapshot.params.id).subscribe((resp) => {
      const currentEntity = localStorage.getItem('currentEntity')
      this.article = resp.data[0];
      this.newsfeed = this.article?.nf
      if (typeof this.article.keywords === 'string') this.article.keywords = JSON.parse(this.article.keywords);
      if (typeof this.article.authorship === 'string') this.article.authorship = JSON.parse(this.article.authorship);
      
      if (currentEntity && this.article.ownerid === currentEntity) this.isOwner = true;
      if (!currentEntity && this.article.userid === this.profile.userid)  this.isOwner = true; 

      if (this.article.mainimage) {
        this.list = [1];
      }

      this.getUserById(this?.article?.userid)
    });
  }

  getFiles(): any {
    this.service.getFiles(this.route.snapshot.params.entityId, this.route.snapshot.params.id).subscribe((resp) => {
      this.files = resp.data;
    });
  }

  getUrl(id): any {
    return this.profilePictureUrl + id;
  }

  editButtonArticle(): any {
    this.router.navigate(['article/edit', { entityId: this.route.snapshot.params.entityId, id: this.route.snapshot.params.id }]).then();
  }

  deleteButtonArticle(): any {
    this.confirmDialog.message = 'Are you sure you want to delete this article? This action cannot be undone.';
    this.confirmDialog.openDialog((res) => {
      if (res) {
        this.service.deleteArticle(this?.profile?.userid, this.route.snapshot.params.id).subscribe((resp) => {
          this.snackbar.open('Article deleted successfully!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
          this.location.back()
        });
      }
    });
  }

  openExpertProfile(): any {
    this.dialog
      .open(ExpertPublicProfileComponent, {
        width: '800px',
        height: '700px',
        data: { entityId: this.route.snapshot.params.entityId },
      })
      .afterClosed()
      .subscribe(() => { });
  }

  openExpertPublicProfile(id): any {
    this.dialog.open(ExpertPublicProfileComponent, {
      width: '800px',
      height: '700px',
      data: { entityId: id }
    })
      .afterClosed()
      .subscribe(() => { });

  }

  /*getComments(): any {
    this.service.getComments(this.entityId, this.id).subscribe(resp => this.comments = resp.data.comments);
  }*/

  goBack(): void {
    this.location.back();
  }

  likeUnlike(newsfeedx): any {
    let newsfeed = this.newsfeed
    if (newsfeed.is_liked) {
      this.dashService
        .unlike(newsfeed.objectid, this.profile.userid)
        .subscribe(resp => {
          if (!resp?.error) {
            newsfeed.is_liked = !newsfeed.is_liked
            newsfeed.like_count--;
          }
        });
    } else {
      this.dashService
        .like(newsfeed.objectid, this.profile.userid)
        .subscribe(resp => {
          if (!resp?.error) {
            newsfeed.is_liked = !newsfeed.is_liked
            newsfeed.like_count++;
          }
        });
    }
  }
  getComments(id): any {
    this.dashService.getComments(this.route.snapshot.params.id).subscribe((resp) => {
      this.comments = resp.data;
    });
  }

  comment(id, trigger): any {
    trigger.closeMenu();
    this.dashService
      .postComment(this.route.snapshot.params.id, this.commentForm.value)
      .subscribe(
        (resp) => {
          this.snackbar.open('Comment Added!', 'Close', {
            duration: 3000,
            panelClass: 'success-snackbar',
          });
          this.newsfeed.comment_count++
          this.commentForm.reset();
        },
        () => {
          this.snackbar.open('Comment Failed!', 'Close', {
            duration: 3000,
            panelClass: 'danger-snackbar',
          });
        }
      );
  }

  share(trigger): any {
    trigger.closeMenu();
    this.dashService
      .newsfeedShare(this.route.snapshot.params.entityId, this.shareForm.value)
      .subscribe(
        () => {
          this.trigger.toArray()[6].closeMenu();
          this.snackbar.open('Shared Successfully!', 'Close', {
            duration: 3000,
            panelClass: 'success-snackbar',
          });
          this.shareForm.reset();
        },
        () => {
          this.snackbar.open('Sharing Failed!', 'Close', {
            duration: 3000,
            panelClass: 'danger-snackbar',
          });
        }
      );
  }
}
