<div class="mobile-content-project-container">
    <mat-card matRipple class="mobile-project" (click)="editProject()">
        <mat-card-subtitle class="project-main-subtitle">
            <span>Posted: {{project?.ts}}</span>
            <div class="project-category-container">
                <mat-icon> category</mat-icon>
                <span>
                    {{project?.category}}
                </span>
            </div>
        </mat-card-subtitle>
        <mat-card-title>
            <p>{{project?.title}} <mat-card-subtitle>{{project?.visibility}}</mat-card-subtitle></p>
        </mat-card-title>
        <mat-card-content>
            <!-- <quill-view-html [content]="project?.abstract"></quill-view-html> -->
            <quill-view-html
                [content]="project?.abstract?.length > 150 ? (project?.abstract | slice:0:150) + '...' : project?.abstract"></quill-view-html>
        </mat-card-content>
        <img *ngIf="project?.picture" mat-card-image [src]="getProjectMainImage(project?.picture) | secure | async"
            alt="main project image">
        <!-- <mat-card-content>{{project?.content}}</mat-card-content> -->
        <div class="project-main-info-container">
            <p>apply starT:{{project.startdate}}</p>
            <p>apply end:{{project.enddate}}</p>
            <p>proj start:{{project.startdateproj}}</p>
            <p>proj end:{{project.enddateproj}}</p>
        </div>
        <div class="project-keywords-container">
            <span class="project-keyword" *ngFor="let keyword of project.keywords">
                {{keyword.keyword}}
            </span>
            <!-- <span class="project-keyword" *ngFor="let keywordObject of keywordParser(project.keywords)">
                {{keywordObject.keyword}}
            </span> -->
        </div>
        <div class="entity-project-view-footer">
            <div class="like">
                <button mat-icon-button matTooltip="Like">
                    <mat-icon class="edit-button" svgIcon="like">
                    </mat-icon>
                    <!-- <mat-icon *ngIf="newsfeed?.entity.is_liked" class="edit-button" svgIcon="liked">
                    </mat-icon> -->
                </button>

                <mat-hint class="number-of-likes-hint">{{project?.numLikes}}</mat-hint>
                <!-- <mat-hint class="liked-or-not-hint">&nbsp; {{newsfeed?.entity.is_liked? 'Liked': 'Like'}}</mat-hint> -->
            </div>

            <div class="comment">
                <button mat-icon-button matTooltip="Comment" [matMenuTriggerFor]="postComment"
                    #postTrigger="matMenuTrigger">
                    <mat-icon class="edit-button" svgIcon="comment"></mat-icon>
                </button>

                <mat-hint class="number-of-comments-hint">{{project?.numComments }} </mat-hint>
                <!-- <mat-hint class="read-comments-hint" matTooltip="Read Comments" [matMenuTriggerFor]="readComments"
                    (click)="getComments(newsfeed?.objectid)" #commentTrigger="matMenuTrigger">&nbsp;Comments
                </mat-hint> -->
            </div>

            <div class="share">
                <button mat-icon-button matTooltip="Share" [matMenuTriggerFor]="shareMenu"
                    #shareTrigger="matMenuTrigger">
                    <mat-icon class="edit-button" svgIcon="share"></mat-icon>
                </button>
                <mat-hint class="share-hint">Share</mat-hint>
            </div>

            <!-- <div class="send">
              <button mat-icon-button matTooltip="Send" [matMenuTriggerFor]="sendMenu" #sendTrigger="matMenuTrigger">
                <mat-icon class="edit-button" svgIcon="send"></mat-icon>
              </button>
              <mat-hint class="send-hint">Send</mat-hint>
            </div> -->
        </div>

        <!-- <mat-menu #readComments="matMenu" class="custom-mat-menu">
            <div>
                <div *ngFor="let comment of comments" class="comment-unit">
                    <mat-hint>{{comment.ts | date: 'medium'}}</mat-hint>
                    <div>{{comment.comment}}</div>
                </div>
            </div>
        </mat-menu>
    -->

        <mat-menu #postComment="matMenu" class="custom-mat-menu">
            <form [formGroup]="commentForm" fxLayout="column" (click)="$event.stopPropagation()"
                fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field class="custom-form-field" appearance="outline">
                    <mat-label>Comment</mat-label>
                    <textarea matInput type="text" placeholder="Write a comment" formControlName="comment"></textarea>
                </mat-form-field>

                <button type="button" mat-stroked-button
                    (click)="comment(project?.projectid,postTrigger)">Comment</button>
            </form>
        </mat-menu>

        <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
            <form [formGroup]="shareForm" (click)="$event.stopPropagation()">
                <p>Share to:</p>
                <div fxLayout="column" fxLayoutAlign="start center">
                    <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body"
                        bindValue="uuid" bindLabel="name" [clearable]="true" formControlName="mynetwork"
                        [notFoundText]="'No Results'" typeToSearchText="Search" placeholder="My Network">
                    </ng-select>

                    <button type="button" mat-stroked-button (click)="share(shareTrigger)">Share</button>
                </div>
            </form>
        </mat-menu>
    </mat-card>
</div>