<div class="modal-header">
  <h2>Ndrysho fjalëkalim</h2>
</div>

<form [formGroup]="passwordForm">
  <div class="modal-inputs">
    <!-- OLD PASSWORD -->
    <mat-form-field fxFill>
      <input autocapitalize="off" autocomplete formControlName="old_password" matInput
             placeholder="Fjalëkalimi i vjetër" spellcheck="false" type="password"/>
      <mat-error>{{errors.old_password}}</mat-error>
    </mat-form-field>

    <!-- NEW PASSWORD -->
    <mat-form-field fxFill>
      <input autocapitalize="off" autocomplete
             placeholder="Fjalëkalimi i ri"
             formControlName="new_password"
             matInput
             spellcheck="false"
             type="password"/>
      <mat-error>{{errors.new_password}}</mat-error>
    </mat-form-field>

    <!-- RE NEW PASSWORD -->
    <mat-form-field fxFill>
      <input autocapitalize="off" autocomplete
             formControlName="repeat_new_password"
             matInput
             spellcheck="false"
             placeholder="Fjalëkalimi i ri (sërish)"
             type="password"/>
      <mat-error>{{errors.repeat_new_password}}</mat-error>
    </mat-form-field>
  </div>

  <!-- ACTIONS -->
  <div class="modal-buttons" fxFill fxLayoutAlign="end center" fxLayoutGap="20px">
    <!-- CANCEL BUTTON -->
    <button (click)="close()" mat-button mat-dialog-close
            mat-stroked-button type="button">Anullo
    </button>

    <!-- SUBMIT BUTTON -->
    <button (click)="changePassword()" class="change-button" color="primary" mat-button mat-raised-button
            type="submit">Ndrysho
    </button>
  </div>
</form>

