<div class="main-div">
    <!-- <div class="leftcolumn" *ngIf="profile?.entityid?.draft === 1"> -->

    <!-- <div class="read-card" [ngClass]="profile?.entityid?.draft === 0 ? 'full-width' : '' "> -->
    <div class="read-card">
        <mat-card class="contest-main-container mat-elevation-z3">
            <div class="contest-title-container">
                <h1>{{contest?.title}}</h1>
                <div class="contest-meta">
                    <div>Start date: <span>{{contest?.startdate}}</span></div>
                    <div>Apply by: <span>{{contest?.open_by}}</span></div>
                    <div>Applications: <span>{{contest?.applicants_count}}</span></div>
                    <div class="contest-number-winners-container">
                        <p>Number of winners: <span>{{contest?.numberwinners === 10000 ? 'No limit' :
                                contest?.numberwinners}}</span></p>
                    </div>
                </div>
            </div>
            <!-- <div class="nws_feed_meta">Applications In-Progress: {{contest?.in_progress_apps}}</div> -->
            <!-- 
      <p class="start-date-box">Start date {{contest?.startdate | date: 'dd/MM/yy'}}</p>
      <p class="end-date-box">End date {{contest?.enddate | date: 'dd/MM/yy'}}</p> -->

            <div class="contest-description-container">
                <p>Description</p>
                <div class="contest-description-text">
                    <quill-view-html [content]="contest?.description"></quill-view-html>
                </div>
            </div>

            <!-- <div *ngFor="let file of files" fxLayout="row" fxLayoutAlign="space-between left">
                <div *ngIf="file?.id === contest?.leadingimage" class="contest-image">
                    <img [src]="getUrl(file.id) | secure | async" alt="photo">
                </div>
            </div> -->
            <div class="contest-requirements-container">
                <p>Requirements</p>
                <div class="table-responsive">
                    <table class="contest-requirements-table">
                        <thead>
                            <tr>
                                <th scope="col">Education</th>
                                <th scope="col">Experience</th>
                                <th scope="col">Skills</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let requirement of contest?.requirements">
                                <td *ngIf="requirement?.education.length > 0">
                                    <div *ngFor="let edu of requirement?.education">{{ edu.name }}</div>
                                </td>
                                <td *ngIf="!requirement?.education.length > 0">
                                    <div>No education required</div>
                                </td>

                                <td *ngIf="requirement?.experience">
                                    <quill-view-html [content]="requirement?.experience"></quill-view-html>
                                </td>
                                <td *ngIf="!requirement?.experience">
                                    <div>No experience required</div>
                                </td>

                                <td *ngIf="requirement?.skills.length > 0">
                                    <div *ngFor="let skill of requirement?.skills">{{ skill.name }}</div>
                                </td>
                                <td *ngIf="!requirement?.skills.length > 0">
                                    <div>No skills required</div>
                                </td>
                                <!-- <td>
                    <quill-view-html [content]="requirement?.other"></quill-view-html>
                  </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>


                <!-- </div> -->
            </div>
            <div class="contest-evaluation-container">
                <p>Evaluation</p>
                <div class="">
                    <quill-view-html [content]="contest?.evaluation"></quill-view-html>
                </div>
            </div>

            <div class="contest-reward-container">
                <p>Reward</p>
                <div class="contest-reward">
                    <!-- <h3>{{contest?.rewardheader}}</h3> -->
                    <div class="">
                        <quill-view-html [content]="contest?.reward"></quill-view-html>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="contest?.leadingimage && files?.length - 1 > 0" class="contest-reward-container"
                fxLayout="column wrap">
                <p>Additional resources</p>
                <div *ngFor="let file of files" fxLayout="row" fxLayoutAlign="space-between left">
                    <a *ngIf="file?.id !== contest?.leadingimage" [href]="getUrl(file.id) | secure | async"
                        download="{{ file.name }}">
                        <span>{{ file.name }}</span>
                        <span class="file-size">({{ file.sizebytes | fileSize }})</span>
                    </a>
                </div>
            </div> -->

            <div class="keyword-container" fxLayout="row wrap" fxLayoutGap="10px">
                <p *ngFor="let keyword of contest?.keywords" class="keyword-wrap">
                    {{ keyword?.keyword }}
                </p>
            </div>

            <!-- <app-social-media-controlls [content]="contest"></app-social-media-controlls> -->

            <!-- 
            <div class="social-info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div class="main-actions-wrapper">
                    <div class="like-comment-share">
                        <div class="like">
                            <button mat-icon-button matTooltip="Like"
                                [matTooltip]="newsfeed?.is_liked ? 'Unlike' : 'Like'" (click)="likeUnlike(newsfeed)">
                                <mat-icon *ngIf="!newsfeed?.is_liked" class="edit-button" svgIcon="like">
                                </mat-icon>
                                <mat-icon *ngIf="newsfeed?.is_liked" class="edit-button" svgIcon="liked">
                                </mat-icon>
                            </button>

                            <mat-hint>&nbsp; {{ numberOfLikes }}
                                {{ newsfeed?.is_liked ? "Liked" : "Like" }}</mat-hint>
                        </div>

                        <div class="comment">
                            <button mat-icon-button matTooltip="Comment" [matMenuTriggerFor]="postComment"
                                #postTrigger="matMenuTrigger">
                                <mat-icon class="edit-button" svgIcon="comment"></mat-icon>
                            </button>

                            {{ newsfeed?.commentcount }}
                            <mat-hint matTooltip="Read Comments" [matMenuTriggerFor]="readComments"
                                (click)="getComments(contestId)" #commentTrigger="matMenuTrigger">&nbsp;Comments
                            </mat-hint>
                        </div>

                        <div class="shre">
                            <button mat-icon-button matTooltip="Share" [matMenuTriggerFor]="shareMenu"
                                #shareTrigger="matMenuTrigger">
                                <mat-icon class="edit-button" svgIcon="share"></mat-icon>
                            </button>
                            <mat-hint>Share</mat-hint>
                        </div>
                    </div>
                    <div class="apply-edit-manage">
                        <div *ngIf="!isOwner && isOpenForAppl" class="footer">
                            <button mat-stroked-button class="action-btn search-bar-button"
                                (click)="applyButtonContest()">
                                <span>Apply</span>
                            </button>
                        </div>

                        <div *ngIf="isOwner" class="footer-controls">
                            <button mat-stroked-button (click)="manageButtonContest()">
                                <mat-icon class="edit-button">people</mat-icon>
                            </button>

                            <button mat-stroked-button (click)="editButtonContest()">
                                <mat-icon class="edit-button">edit</mat-icon>
                            </button>

                            <button mat-stroked-button (click)="deleteButtonContest()">
                                <mat-icon class="delete-button">delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <mat-menu #readComments="matMenu" class="custom-mat-menu">
                <div style="padding: 15px 20px 0 20px">
                    <div *ngFor="let comment of comments" class="comment-unit">
                        <mat-hint>{{comment.ts | date: 'medium'}}</mat-hint>
                        <div>{{comment.comment}}</div>
                    </div>
                </div>
            </mat-menu>

            <mat-menu #postComment="matMenu" class="custom-mat-menu">
                <form [formGroup]="commentForm" style="padding: 0 20px" fxLayout="column"
                    (click)="$event.stopPropagation()" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-form-field class="custom-form-field" appearance="outline">
                        <mat-label>Comment</mat-label>
                        <textarea matInput type="text" placeholder="Write a comment"
                            formControlName="comment"></textarea>
                    </mat-form-field>

                    <button type="button" mat-stroked-button (click)="comment(contestId,postTrigger)">Comment</button>
                </form>
            </mat-menu>

            <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
                <form [formGroup]="shareForm" style="padding: 0 20px" (click)="$event.stopPropagation()">
                    <p>Share to:</p>
                    <div fxLayout="column" fxLayoutAlign="start center">
                        <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body"
                            bindValue="uuid" bindLabel="name" [clearable]="true" formControlName="mynetwork"
                            [notFoundText]="'No Results'" typeToSearchText="Search" placeholder="My Network">
                        </ng-select>

                        <button type="button" mat-stroked-button (click)="share(shareTrigger)">Share</button>
                    </div>
                </form>
            </mat-menu> -->
        </mat-card>
    </div>
</div>