import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-entity-content-contest',
  templateUrl: './entity-content-contest.component.html',
  styleUrls: ['./entity-content-contest.component.scss']
})
export class EntityContentContestComponent implements OnInit {
  contests;
  entityId;


  constructor(
    private service: DashboardService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.parent.parent.params.subscribe(param => {
      this.entityId = param.id
    })
    this.getEntityContests()
  }

  getEntityContests(): any {
    this.service.getContests(this.entityId).subscribe(resp => {
      this.contests = resp.data
    })
  }

}
