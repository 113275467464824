<div class="entity-article-container">
    <div *ngIf="articles && articles.length > 0 ; else notFoundArticles" class="article-card">
        <div class="" *ngFor="let article of articles">
            <app-entity-article-view [article]="article"></app-entity-article-view>
        </div>
    </div>

    <ng-template #notFoundArticles>
        <mat-card>
            <mat-card-subtitle>{{'entity-dashboard.mobile-version.bottom-nav.content-tab.entity-content.empty-message.articles'
                | translate}}
            </mat-card-subtitle>
        </mat-card>
    </ng-template>
</div>