<div class="profile" id="profile">
    <div class="profile-wrapper">
        <!-- <div class="profile-pic-and-info">
            <div class="profile-pic">
                <img class="image" [src]="profilePictureUrl | secure | async" />
            </div>
            <div class="profile-info">
                <div class="name">
                    {{ profile?.firstname }} {{ profile?.lastname }}
                </div>
                <div class="designation">{{ profile?.headline }}</div>
            </div>
        </div> -->
        <div class="profile-status">
            <div class="profile-status-header">
                <div class="profile-status-icon-wrapper">
                    <mat-icon class="profile-status-icon">assessment</mat-icon>
                </div>
                <span>{{'dashboard.left-column.profile-stats.title' | translate}}</span>
            </div>
            <div (click)="openProjectsList()" class="project-item">
                <label class="project">{{'dashboard.left-column.profile-stats.project-count' | translate}}</label>
                <label class="count">{{profileStats?.projects}}</label>
            </div>
            <div (click)="openContestsList()" class="project-item">
                <label class="project">{{'dashboard.left-column.profile-stats.contest-count' | translate}}</label>
                <label class="count">{{profileStats?.contests}}</label>
            </div>
        </div>

    </div>
    <!-- Activities from >= 720 width starts here -->
    <app-talent-activities [activities]="activities"></app-talent-activities>

</div>