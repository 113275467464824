import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-entity-content-project',
  templateUrl: './entity-content-project.component.html',
  styleUrls: ['./entity-content-project.component.scss']
})
export class EntityContentProjectComponent implements OnInit {

  entityId;
  projects;


  constructor(
    private service: DashboardService,
    private route: ActivatedRoute,
    private fb: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.route.parent.parent.params.subscribe(param => {
      this.entityId = param.id
    })
    this.getEntityProjects()
  }

  getEntityProjects(): any {
    this.service.getProjects(this.entityId).subscribe(resp => {
      this.projects = resp.data
      console.log(this.projects);
    })
  }
}
