<div fxFill fxLayout="column" fxLayoutAlign="center center">
    <div class="container-award">
        <h4>Choose the subscription you would like to use!</h4>

        <form [formGroup]="formGroup">

            <mat-form-field appearance="outline">
                <mat-label *ngIf="subscription_data?.length > 0">Choose your subscription</mat-label>
                <mat-label *ngIf="subscription_data?.length === 0">You have no credits!</mat-label>

                <mat-select formControlName="subscription" [disabled]="subscription_data?.length === 0"
                    placeholder="No access" required>
                    <mat-option *ngFor="let sub of subscription_data" [value]="sub"
                        [disabled]="sub.credit_amount === 0">
                        {{ 'Contact credits available: ' + sub.credit_amount + ' (Cost: 1 Credit)'}}
                    </mat-option>
                </mat-select>

                <mat-error *ngIf="formGroup.get('subscription').hasError('required')">
                    Subscription is required!
                </mat-error>
            </mat-form-field>

            <div class="footer-button-publish">
                <button mat-flat-button type="submit" [disabled]="subscription_data?.length === 0 || formGroup.invalid"
                    [ngStyle]="{ 'background-color': subscription_data?.length === 0 || formGroup.invalid ? 'grey' : 'rgba(1, 98, 112, 1)' }"
                    (click)="submit(formGroup.get('subscription').value)" class="publish-button">
                    Continue
                </button>
            </div>

        </form>
    </div>
</div>