import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DraftListComponent as ArticleDraftListComponent } from '../../../article/_components/draft-list/draft-list.component';
import { ArticleService } from '../../../article/_services/article.service';
import { CommonService } from '../../../common/_services/common.service';
import { DraftListComponent as ContestDraftListComponent } from '../../../contest/_components/draft-list/draft-list.component';
import { ContestService } from '../../../contest/_services/contest.service';
import { ProjectService } from '../../../project/_services/project.service';
import { DraftListComponent as ProjectDraftListComponent } from '../../../project/_components/draft-list/draft-list.component';
import { ProfileService } from '../../../profile/_services/profile.service';
import { DashboardService } from '../../_services/dashboard.service';
//import { ProjectDraftListComponent } from 'src/app/project/_components/project-draft-list/project-draft-list.component';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { ThemePalette } from '@angular/material/core';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SnackbarMessagesComponent } from 'src/app/common/snackbar-messages/snackbar-messages.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-entity-homepage',
  templateUrl: './entity-homepage.component.html',
  styleUrls: ['./entity-homepage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntityHomepageComponent implements OnInit, OnDestroy {
  // links = ['Articles', 'Contests', 'Projects'];
  // activeLink = this.links[0];
  // background: ThemePalette = undefined
  snackbarMessages: SnackbarMessagesComponent

  @ViewChildren('lastElement', { read: ElementRef })
  lastElement: QueryList<ElementRef>;
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  secureImageUrl: Observable<string>;

  observer: any;
  isLoadingNewsfeed: boolean = true;


  bottomNavLinks = [
    {
      name: 'newsfeed',
      class: 'News',
      icon: '',
      link: 'nav-tab/newsfeed',
    },
    {
      name: 'content',
      class: 'content',
      icon: '',
      link: 'nav-tab/content/tab/Articles'
    },
    {
      name: 'connect',
      class: 'connect',
      icon: '',
      link: 'nav-tab/connect'
    }
  ];

  links = [
    {
      name: 'Articles',
      class: 'article-desktop-content'
    },
    {
      name: 'Contests',
      class: 'contest-desktop-content'
    },
    {
      name: 'Projects',
      class: 'project-desktop-content'
    }
  ];
  numberNewsfeedPosts = 6
  selectedMobileTabIndex;
  bottomNavActiveLink;
  activeLink;
  background: ThemePalette = undefined;
  display: 'flex';
  entityId;
  entity: any = {};
  newsfeeds;
  profileData;
  contests;
  projects;
  articles;
  people = [];
  logoRefresh = [1];
  mainImageRefresh = [1];
  hasLogo = false;
  hasMainImage = false;
  // mainImageId = null;
  pictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  messageForm: FormGroup;
  shareForm: FormGroup;
  sendForm: FormGroup;
  newsfeedShareForm: FormGroup;
  commentForm: FormGroup;
  myShareSets;
  myNetwork;
  comments: Array<any>;
  numberOfLikes: number = 0;
  selectedTab = 'newsfeed';
  watcher: Subscription;
  isMobile = false;
  lastKeyword;
  connections;
  isLoadingEntity = false;
  constructor(
    private service: DashboardService,
    private userService: ProfileService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private contestService: ContestService,
    private articleService: ArticleService,
    private projectService: ProjectService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mediaObserver: MediaObserver,
    private http: HttpClient,
    private translate: TranslateService,

  ) {
    this.snackbarMessages = new SnackbarMessagesComponent(snackbar, translate)

    this.matIconRegistry.addSvgIcon(
      'postSettings',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/post-settings.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'like',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/like.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'liked',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/liked.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'comment',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/comment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'share',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/share.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'send',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/send.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'post-image',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/post-image.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'instagram',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/mobile-icon/instagram.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/mobile-icon/facebook.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'twitter',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/mobile-icon/twitter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'crown',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/crown.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'contest-create-trophy',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/contest-create-trophy.svg')
    );

    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.isMobile = change.mqAlias == 'xs';
    });

    // this.entityId = this.route.snapshot.paramMap.get('id');
    // this.route.params.subscribe((params) => {
    //   this.entityId = params.id;
    //   this.getAllData();
    // });
    this.commonService.changeData(true);

    this.messageForm = fb.group({
      subject: [''],
      message: [''],
    });

    this.shareForm = fb.group({
      person: [null],
      mynetwork: [null],
    });

    this.sendForm = fb.group({
      userid: [null],
    });

    this.newsfeedShareForm = fb.group({
      mynetwork: [null],
    });

    this.commentForm = fb.group({
      comment: [''],
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.entityId = params.id;
      this.getAllData();
      // this.intersectionObserver()
    });

    this.route.firstChild?.url.subscribe(parentSegments => {
      this.bottomNavActiveLink = parentSegments[1].path
    });
  }


  // ngAfterViewInit() {
  //   this.lastElement.changes.subscribe((d) => {
  //     if (d.last) this.observer.observe(d.last.nativeElement);
  //   });
  // }

  getSecureUrl(id: string): Observable<any> {
    const transformedUrl = this.getUrl(id); // Original URL transformation

    // Adjust the request to expect a 'blob' response
    return this.http.get(transformedUrl, { responseType: 'blob' }).pipe(
      map(blob =>
        this.domSanitizer.bypassSecurityTrustUrl(
          `url('${URL.createObjectURL(blob)}')`
        )
      ), // Convert the blob to a local URL
      finalize(() => { /* Any cleanup or final steps */ })
    );
  }

  getAllData(): Observable<any> {
    console.log("NE STIGNAHME ??")
    this.isLoadingEntity = true;

    const userProfile$ = this.getUserProfile();
    const entity$ = this.getEntity();
    const newsfeed$ = this.getNewsfeed();
    const contests$ = this.getContests();
    const projects$ = this.getProjects();
    const articles$ = this.getArticles();
    const people$ = this.getPeople();
    const myShareSets$ = this.getMyShareSets();
    const myNetwork$ = this.getMyNetwork();
    const secureImageUrl$ = false; //this.getSecureUrl(this.pictureUrl + entity$.logofileid)

    return forkJoin({
      userProfile: userProfile$,
      entity: entity$,
      newsfeed: newsfeed$,
      contests: contests$,
      projects: projects$,
      articles: articles$,
      people: people$,
      myShareSets: myShareSets$,
      myNetwork: myNetwork$,
      secureImageUrl: secureImageUrl$,
    }).pipe(
      map((response: any) => {
        this.isLoadingEntity = false;
        return response;
      })
    );
  }

  // getMainImage(): any {
  //   this.service.transform(this.getMainImageUrl()).subscribe(resp => {
  //     // this.mainImageUrl = resp['changingThisBreaksApplicationSecurity'];
  //     this.mainImageId = true;
  //   }, () => this.mainImageId = false);
  // }

  // encodeLink(link: string): string {
  //   const newUri = parse(link)
  //   console.log(newUri);

  //   return encodeURIComponent(link);
  // }

  getUserProfile(): any {
    this.service.getUserProfile().subscribe(resp => {
      this.profileData = resp.data;

      if (this.profileData.entityid.draft === 1) {
        this.router.navigate(['/dashboard']).then(() => {
          this.snackbar.open('Unauthorized action!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
        })
      }
    });
  }

  getEntity(): any {
    this.isLoadingEntity = true;

    this.service.getEntity(this.entityId).subscribe(
      (resp) => {
        // Handle successful response
        this.entity = resp.data[0];
        if (this.entity.logofileid) {
          this.hasLogo = true;
          this.logoRefresh = [2];
        } else {
          this.hasLogo = false;
          this.logoRefresh = [2];
        }

        if (this.entity.mainimage) {
          this.hasMainImage = true;
          this.mainImageRefresh = [2];
        } else {
          this.hasMainImage = false;
          this.mainImageRefresh = [2];
        }
        
        if (this?.entity?.draft === -1 || this?.entity?.draft === 1) {
          this.entity.isCompany = false
        } else {
          this.entity.isCompany = true
        }
        
        this.isLoadingEntity = false;
      },
      (error) => {
        // Handle error
        console.error('Error fetching entity:', error);
        this.isLoadingEntity = false; // Ensure loading state is updated even in case of error
        // You can also display an error message to the user or perform other error handling tasks
      }
    );
  }


  getNewsfeed(): any {
    // this.service
    //   .getNewsfeed(this.entityId)
    //   .subscribe((resp) => (this.newsfeeds = resp.data.newsfeed));
    this.isLoadingNewsfeed = true
    this.service.getNewsfeedHomepage(this.numberNewsfeedPosts).subscribe(resp => {
      if (JSON.stringify(resp['data']['newsfeed']) !== JSON.stringify(this.newsfeeds)) {
        this.newsfeeds = resp['data']['newsfeed'];
        this.isLoadingNewsfeed = false
      }
    });
  }

  onScroll(): any {
    this.numberNewsfeedPosts += 3; // Increment the number of newsfeed posts to fetch
    this.getNewsfeed(); // Fetch more newsfeed items
  }

  getContests(): any {
    try {
      this.service
        .getContests(this.entityId)
        .subscribe((resp) => { this.contests = resp.data });
    } catch (error) {
      console.error(error);
    }
  }

  getProjects(): any {
    try {
      this.service
        .getProjects(this.entityId)
        .subscribe((resp) => (this.projects = resp.data));
    } catch (error) {
      console.error(error);
    }
  }

  getArticles(): any {
    this.service
      .getArticles(this.entityId)
      .subscribe((resp) => {
        this.articles = resp.data
      })
  }

  getAllConnections(): any {
    return Promise.all([
      this.getContestApplicants(),
      this.getProjectApplicants()
    ])
  }

  getContestApplicants(): any {
    this.contestService.getContestConnections(this.entityId).subscribe(res => {
      const { userInfo } = res
      if (userInfo?.length > 0) {
        userInfo.forEach(user => {
          this.connections.push(user)
        });
      }
    })
  }

  getProjectApplicants(): any {
    this.projectService.getProjectConnections(this.entityId).subscribe(res => {
      const { userInfo } = res
      if (userInfo?.length > 0) {
        userInfo.forEach(user => {
          this.connections.push(user)
        });
      }
    })
  }

  getPeople(): any {
    this.service
      .getPeople(this.entityId)
      .subscribe((resp) => (this.people = resp.data));
  }

  getLogoUrl(): string {
    return this.hasLogo
      ? this.pictureUrl + this.entity.logofileid
      : '../../../../assets/logo-final.png';
  }

  getMainImageUrl(): string {
    return this.pictureUrl + this.entity.mainimage;
  }

  getUrl(id): any {
    return this.pictureUrl + id;
  }

  createArticle(): any {
    this.articleService.getDrafts(this.entityId).subscribe((resp) => {
      if (resp.data.length !== 0) {
        this.dialog.open(ArticleDraftListComponent, {
          width: '600px',
          data: { drafts: resp.data, entityId: this.entityId },
          autoFocus: false,
        });
      } else {
        this.router
          .navigate(['article/form', { id: 0, entityId: this.entityId }])
          .then();
      }
    });
  }

  createContest(): any {
    this.contestService.getDrafts(this.entityId).subscribe((resp) => {
      if (resp.data.length !== 0) {
        this.dialog.open(ContestDraftListComponent, {
          width: '600px',
          data: { drafts: resp.data, entityId: this.entityId },
          autoFocus: false,
        });
      } else {
        this.router
          .navigate(['contest/form', { id: 0, entityId: this.entityId }])
          .then();
      }
    });
  }

  createProject(): any {
    this.projectService.getDrafts(this.entityId).subscribe((resp) => {
      if (resp.data.length !== 0) {
        this.dialog.open(ProjectDraftListComponent, {
          width: '600px',
          data: { drafts: resp.data, entityId: this.entityId },
          autoFocus: false,
        });
      } else {
        this.router
          .navigate(['project/form', { id: 0, entityId: this.entityId }])
          .then();
      }
    });
  }

  readNewsContent(id, type): any {
    if (type === 'article') {
      this.router
        .navigate(['article/read', { id, entityId: this.entityId }])
        .then();
    } else if (type === 'contest') {
      this.router
        .navigate(['contest/read', { id, entityId: this.entityId }])
        .then();
    } else {
      this.router
        .navigate(['project/read', { id, entityId: this.entityId }])
        .then();
    }
  }

  public isNewsArticle(newstype: any) {
    if (newstype == 'article') return true;
  }

  public isNewsContestOrProject(newstype: any) {
    if (newstype == 'contest' || newstype == 'project') return true;
  }

  manageNewsContent(id, type): any {
    if (type === 'project') {
      this.router
        .navigate(['project/manage', { id, entityId: this.entityId }])
        .then();
    } else if (type === 'contest') {
      this.router
        .navigate(['contest/manage', { id, entityId: this.entityId }])
        .then();
    }
  }

  public modifyCompany(id) {
    this.router.navigate(['organization', { id }]).then();
  }

  public isNewsContestApplicable(newstype: any) {
    if (newstype == 'contest') return true;
  }

  public isNewsProjectApplicable(newstype: any) {
    if (newstype == 'project') return true;
  }

  changeLogo(event): any {
    const formData: FormData = new FormData();
    const file: File = event.target.files[0];
    formData.append('filecontent', file, file.name);
    this.service.changeLogo(formData, this.entityId).subscribe(
      (resp) => {
        this.entity['logofileid'] = resp.logofileid;
        this.logoRefresh = [3];
        this.hasLogo = true;
      },
      () => { }
    );
  }

  changeMainImage(event): any {
    const formData: FormData = new FormData();
    const file: File = event.target.files[0];
    formData.append('filecontent', file, file.name);
    this.service.changeMainImage(formData, this.entityId).subscribe(
      (resp) => {
        this.entity.mainimage = resp.logofileid;
        this.mainImageRefresh = [3];
        this.hasMainImage = true;
      },
      () => { }
    );
  }

  followUnfollow(): any {
    if (this.entity.following) {
      this.service
        .unfollow(this.entityId)
        .subscribe(() => (this.entity.following = !this.entity.following));
    } else {
      this.service
        .follow(this.entityId)
        .subscribe(() => (this.entity.following = !this.entity.following));
    }
  }

  report(): any {
    this.service.reportAbuse(this.entityId).subscribe();
  }

  sendMessage(): any {
    this.service.message(this.entityId, this.messageForm.value).subscribe(
      () => {
        // this.trigger.closeMenu();
        this.trigger.toArray()[1].closeMenu();
        this.snackbar.open('Message Sent!', 'Close', {
          duration: 3000,
          panelClass: 'success-snackbar',
        });
        this.messageForm.reset();
      },
      () => {
        this.snackbar.open('Message Failed To Send!', 'Close', {
          duration: 3000,
          panelClass: 'danger-snackbar',
        });
      }
    );
  }

  share(trigger?): any {
    trigger.closeMenu();
    this.service.entityShare(this.entityId, this.shareForm.value).subscribe(
      () => {
        // this.trigger.closeMenu();
        this.trigger.toArray()[0].closeMenu();
        this.snackbar.open('Shared Successfully!', 'Close', {
          duration: 3000,
          panelClass: 'success-snackbar',
        });
        this.shareForm.reset();
      },
      () => {
        this.snackbar.open('Sharing Failed!', 'Close', {
          duration: 3000,
          panelClass: 'danger-snackbar',
        });
      }
    );
  }

  _filterShareSets(name: string): any {
    const filterValue = name.toLowerCase();
    return this.myShareSets.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  getMyShareSets(): any {
    this.userService.getMyShareSets().subscribe((resp) => {
      this.myShareSets = resp.data.entities;
      // this.filteredShareSets = this.shareForm.controls['person'].valueChanges
      //   .pipe(
      //     startWith(''),
      //     map(value => typeof value === 'string' ? value : value.name),
      //     map(name => name ? this._filterShareSets(name) : this.myShareSets.slice())
      //   );
    });
  }

  getMyNetwork(): any {
    this.userService
      .getMyNetwork({})
      .subscribe((resp) => (this.myNetwork = resp.data.entities));
  }

  getComments(id): any {
    this.service
      .getComments(id)
      .subscribe((resp) => (this.comments = resp.data.comments));
  }

  comment(id, trigger): any {
    trigger.closeMenu();
    this.service.postComment(id, this.commentForm.value).subscribe(
      (resp) => {
        this.trigger.toArray()[4].closeMenu();
        this.snackbar.open('Comment Added!', 'Close', {
          duration: 3000,
          panelClass: 'success-snackbar',
        });
        this.commentForm.reset();
      },
      () => {
        this.snackbar.open('Comment Failed!', 'Close', {
          duration: 3000,
          panelClass: 'danger-snackbar',
        });
      }
    );
  }

  shareNewsfeed(): any {
    this.service
      .newsfeedShare(this.entityId, this.newsfeedShareForm.value)
      .subscribe(
        () => {
          this.trigger.toArray()[5].closeMenu();
          this.snackbar.open('Shared Successfully!', 'Close', {
            duration: 3000,
            panelClass: 'success-snackbar',
          });
          this.shareForm.reset();
        },
        () => {
          this.snackbar.open('Sharing Failed!', 'Close', {
            duration: 3000,
            panelClass: 'danger-snackbar',
          });
        }
      );
  }

  send(trigger): any {
    trigger.closeMenu();
    this.service.send(this.entityId, this.sendForm.value).subscribe(
      () => {
        this.trigger.toArray()[6].closeMenu();
        this.snackbar.open('Message Sent!', 'Close', {
          duration: 3000,
          panelClass: 'success-snackbar',
        });
        this.sendForm.reset();
      },
      () => {
        this.snackbar.open('Message Failed To Send!', 'Close', {
          duration: 3000,
          panelClass: 'danger-snackbar',
        });
      }
    );
  }

  likeUnlike(newsfeed): any {
    if (newsfeed.entity.is_liked) {
      this.service
        .unlike(newsfeed.objectid, this.entityId)
        .subscribe(
          (resp) => (newsfeed.entity.is_liked = !newsfeed.entity.is_liked)
        );
      this.numberOfLikes--;
    } else {
      this.service
        .like(newsfeed.objectid, this.entityId)
        .subscribe(
          (resp) => (newsfeed.entity.is_liked = !newsfeed.entity.is_liked)
        );
      this.numberOfLikes++;
    }
  }

  onSuccess(): any {
    this.snackbarMessages.onSuccess()
  }

  onError(error): any {
    this.snackbarMessages.onError
  }

  newsFeed() {
    this.selectedTab = 'newsfeed';
  }

  profile() {
    this.selectedTab = 'profile';
  }

  createContent() {
    this.selectedTab = 'content';
  }

  ngOnDestroy() {
    this.watcher.unsubscribe();
  }
}
