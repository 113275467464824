import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileService } from '../../../profile/_services/profile.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { DashboardService } from '../../_services/dashboard.service';
import { MatIconRegistry } from '@angular/material/icon';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogConfirmComponent } from '../../../common/dialog-confirm/dialog-confirm.component';
import { environment } from '../../../../environments/environment';
import { ExpertPublicProfileComponent } from '../expert-public-profile/expert-public-profile.component';
import { MentorPublicProfileComponent } from '../mentor-public-profile/mentor-public-profile.component';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';



@Component({
  selector: 'app-newsfeed-card',
  templateUrl: './newsfeed-card.component.html',
  //template: 'it should work now',
  styleUrls: ['./newsfeed-card.component.scss'],
  providers: [DialogConfirmComponent]
})

export class NewsfeedCardComponent implements OnInit {

  @Input() newsfeed;
  @Input() entityId;
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  url = `${environment.API_HOST}/files/download/`;
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  secureImageUrl: Observable<string>;
  sendForm: FormGroup;
  shareForm: FormGroup;
  commentForm: FormGroup;

  myShareSets;
  myNetwork;
  comments: Array<any>;

  numberOfLikes: number = 0;
  profile;
  entity;
  userPersonalData;
  loadingUserById;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private userService: ProfileService,
    private service: DashboardService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dashService: DashboardService,
    private confirmDialog: DialogConfirmComponent,
    private http: HttpClient
  ) {
    this.sendForm = fb.group({
      userid: [null],
    });

    this.shareForm = fb.group({
      mynetwork: [null]
    });

    this.commentForm = fb.group({
      comment: ['']
    });

    this.matIconRegistry.addSvgIcon("filter", domSanitizer.bypassSecurityTrustResourceUrl("/assets/filter.svg"))
    this.matIconRegistry.addSvgIcon("folder", domSanitizer.bypassSecurityTrustResourceUrl("/assets/folder.svg"))
    this.matIconRegistry.addSvgIcon("postSettings", domSanitizer.bypassSecurityTrustResourceUrl("/assets/post-settings.svg"))
    this.matIconRegistry.addSvgIcon("like", domSanitizer.bypassSecurityTrustResourceUrl("/assets/like.svg"))
    this.matIconRegistry.addSvgIcon("liked", domSanitizer.bypassSecurityTrustResourceUrl("/assets/liked.svg"))
    this.matIconRegistry.addSvgIcon("comment", domSanitizer.bypassSecurityTrustResourceUrl("/assets/comment.svg"))
    this.matIconRegistry.addSvgIcon("share", domSanitizer.bypassSecurityTrustResourceUrl("/assets/share.svg"))
    this.matIconRegistry.addSvgIcon("send", domSanitizer.bypassSecurityTrustResourceUrl("/assets/send.svg"))
    this.matIconRegistry.addSvgIcon("post-image", domSanitizer.bypassSecurityTrustResourceUrl("/assets/post-image.svg"))
  }

  ngOnInit(): void {
    this.getMyShareSets();
    this.getMyNetwork();
    this.getUserProfile();
    this.checkLocation()
    this.numberOfLikes = this.newsfeed.entity.likescount;    
    this.secureImageUrl = this.getSecureUrl(this.newsfeed.mainimage);
  }

  getUserProfile(): any {
    this.dashService.getUserProfile().subscribe(resp => {
      this.profile = resp.data;      
    });
  }


  checkLocation(): any {
    if (this.checkIfInDashboard()) {
      return false
    };
    if (this.checkIfInEntityView()) {
      return true
    }
  }

  showManageButton(): boolean {
    return this.checkLocation()
  }

  checkIfInDashboard(): any {
    return window.location.pathname.includes('dashboard')
  }

  checkIfInEntityView(): boolean {
    return window.location.pathname.includes('entity')
  }

  ownsContent(eid): boolean {
    const currentCompany = localStorage.getItem('currentEntity')
    return currentCompany === eid
  }

  checkEntityExists(entityId: string): boolean {
    return this?.profile?.entities.some(entity => entity?.eid === entityId);
  }

  manageButtonProject(projectid, entityid): any {
    this.router.navigate(['project/manage', { id: projectid, entityId: entityid }]).then();
  }


  canApply(status): any {
    if (!status) return true

    return status === 3
  }

  canWithdraw(status) {
    if (!status) return false

    return status === 2
  }

  canGotoChallenge(status) {
    if(!status) return false

    return status === 2 || status === 4
  }

  isEntityInProfileEntities(entityIdItem): boolean {
    return this.profile?.entities.some(entity => entity.eid === entityIdItem);
  }

  readNewsContent(id, type, eid): any {        
    if (type === 'article') {      
      this.router.navigate(['article/read', { id, entityId: eid, }]).then(); //newsfeed: JSON.stringify(this.newsfeed.entity)
    } else if (type === 'project') {
      this.router.navigate(['project/read', { id, entityId: eid, }]).then();
    } else {
      this.router.navigate(['contest/read', { id, entityId: eid, }]).then();
    }
  }


  // public isNewsContestOrProject(newstype: any){
  //   if( newstype == 'contest' || newstype == 'project' ) return true;
  // }

  // public isNewsArticle(newstype: any){
  //   if( newstype == 'article' ) return true;
  // }


  // public isNewsContestApplicable(newstype: any){
  //   if( newstype == 'contest' ) return true;
  // }

  // public isNewsProjectApplicable(newstype: any){
  //   if( newstype == 'project' ) return true;
  // }


  public isNewsArticle(newstype: any) {
    if (newstype == 'article') return true;
  }

  public isNewsContest(newstype: any) {
    if (newstype == 'contest') return true
  }

  public isNewsProject(newstype: any) {
    if (newstype == 'project') return true;
  }

  getUrl(id): any {
    return this.profilePictureUrl + id;
  }

  getSecureUrl(id: string): Observable<any> {
    const transformedUrl = this.getUrl(id); // Original URL transformation
  
    // Adjust the request to expect a 'blob' response
    return this.http.get(transformedUrl, { responseType: 'blob' }).pipe(
      map(blob => 
        this.domSanitizer.bypassSecurityTrustUrl(
          `url('${URL.createObjectURL(blob)}')`
        )
      ), // Convert the blob to a local URL
      finalize(() => { /* Any cleanup or final steps */ })
    );
  }
  
  
  send(trigger): any {
    trigger.closeMenu()
    this.service.send(this.entityId, this.sendForm.value).subscribe(() => {
      this.trigger.toArray()[7].closeMenu();
      this.snackbar.open('Message Sent!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
      this.sendForm.reset();
    }, () => {
      this.snackbar.open('Message Failed To Send!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    });
  }

  share(trigger): any {
    trigger.closeMenu()
    this.service.newsfeedShare(this.entityId, this.shareForm.value).subscribe(() => {
      this.trigger.toArray()[6].closeMenu();
      this.snackbar.open('Shared Successfully!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
      this.shareForm.reset();
    }, () => {
      this.snackbar.open('Sharing Failed!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    });
  }

  getMyShareSets(): any {
    this.userService.getMyShareSets().subscribe(resp => this.myShareSets = resp.data.entities);
  }

  getMyNetwork(): any {
    this.userService.getMyNetwork({}).subscribe(resp => this.myNetwork = resp.data.entities);
  }

  getComments(id): any {
    this.service.getComments(id).subscribe(resp => {
      this.comments = resp.data;
    });
  }

  comment(id, trigger): any {
    trigger.closeMenu()
    this.service.postComment(this.newsfeed.objectid, this.commentForm.value).subscribe(resp => {
      this.snackbar.open('Comment Added!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
      this.newsfeed.comment_count++
      this.commentForm.reset();
    }, () => {
      this.snackbar.open('Comment Failed!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    });
  }

  likeUnlike(newsfeedx): any {
    let newsfeed = this.newsfeed
    if (newsfeed.is_liked) {
      this.dashService
        .unlike(newsfeed.objectid, this.profile.userid)
        .subscribe(resp => {
          if (!resp?.error) {
            newsfeed.is_liked = !newsfeed.is_liked
            newsfeed.like_count--;
          }
        });
    } else {
      this.dashService
        .like(newsfeed.objectid, this.profile.userid)
        .subscribe(resp => {
          if (!resp?.error) {
            newsfeed.is_liked = !newsfeed.is_liked
            newsfeed.like_count++;
          }
        });
    }
  }

  applyButtonContest(contestid, entity): any {
    this.confirmDialog.message = 'Are you sure you want to apply for this contest?';
    this.confirmDialog.openDialog((res) => {
      if (res) {
        this.router.navigate(['contest/application', { entityId: this.profile.entityid.eid, contestid: contestid }]).then();
      }
    });
  }

  manageButtonContest(contestid): any {
    this.router.navigate(['contest/edit', { entityId: this.entityId, contestid }]).then();
  }

  applyButtonProject(projectid): any {
    this.confirmDialog.message = 'Are you sure you want to apply for this project?';
    this.confirmDialog.openDialog((res) => {
      if (res) {
        this.router.navigate(['project/application', { entityId: this.profile.entityid.eid, projectid: projectid }]).then();
      }
    });
  }

  openEntityDialog() {
    const entityid = this.route.snapshot.paramMap.get('entityId');
    if (!entityid) return;

    const isMentor = this.route.snapshot.paramMap.get('isMentor');

    if (this.dialog.openDialogs.length === 1) return;

    if (isMentor === 'true') {
      this.dialog.open(MentorPublicProfileComponent, {
        width: '1800px',
        height: '900px',
        panelClass: 'mentor-public-profile',
        data: { entityId: entityid }
      })
        .afterClosed()
        .subscribe(() => { });
    } else {
      this.dialog.open(ExpertPublicProfileComponent, {
        width: '800px',
        height: '700px',
        data: { entityId: entityid }
      })
        .afterClosed()
        .subscribe(() => { });
    }
  }

  openExpertProfile(entity): any {
    if (entity.is_mentor) {
      this.dialog.open(MentorPublicProfileComponent, {
        width: '1800px',
        height: '900px',
        panelClass: 'mentor-public-profile',
        data: { entityId: entity.entityid }
      })
        .afterClosed()
        .subscribe(() => { });
      // this.router.navigate(['/newsfeed', { entityId: entity.entityid, isMentor: 'true' }]).then();
    } else {
      this.dialog.open(ExpertPublicProfileComponent, {
        width: '800px',
        height: '700px',
        closeOnNavigation: true,
        data: { entityId: entity.entityid }
      })
        .afterClosed()
        .subscribe(() => { });
      // this.router.navigate(['/newsfeed', { entityId: entity.entityid, isMentor: 'false' }]).then();
    }

  }

  openExpertPublicProfile(id, event): any {
    event.stopPropagation();

    this.dialog.open(ExpertPublicProfileComponent, { 
      width: '800px',
      height: '700px',
      data: {entityId: id} 
    })
    .afterClosed()
    .subscribe(() => { });

  }

  generateCustomLink(platform: string, data): string {
  const url = 'https://test.a-gora.com' 
  switch (platform) {
    case 'facebook':          
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${url}/${data?.type}/read;id=${data?.objectid};entityId=${data?.entity?.entityid}`)}`;    
    case 'messenger':
      return `https://www.facebook.com/dialog/send?link=${encodeURIComponent(`${url}/${data?.type}/read;id=${data?.objectid};entityId=${data?.entity?.entityid}`)}`;
    case 'whatsapp':
      return `https://api.whatsapp.com/send?text=${encodeURIComponent(`${url}/${data?.type}/read;id=${data?.objectid};entityId=${data?.entity?.entityid}`)}`;
    case 'instagram':
      return `https://www.instagram.com/share?url=${encodeURIComponent(`${url}/${data?.type}/read;id=${data?.objectid};entityId=${data?.entity?.entityid}`)}`;
    case 'viber':
      return `viber://forward?text=${encodeURIComponent(`${url}/${data?.type}/read;id=${data?.objectid};entityId=${data?.entity?.entityid}`)}`;
    default:
      return `${url}/${data?.type}/read;id=${data?.objectid};entityId=${data?.entity?.entityid}`;
  }
  
}

shareLink(platform: string, data): void {
  const link = this.generateCustomLink(platform, data);
  window.open(link, '_blank');
}


}
