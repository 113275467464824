import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-social-media-controlls',
  templateUrl: './social-media-controlls.component.html',
  styleUrls: ['./social-media-controlls.component.scss']
})
export class SocialMediaControllsComponent implements OnInit {
  @Input() content
  newsfeed = []
  myNetwork = []
  shareForm: FormGroup;
  commentForm: FormGroup
  comments = []
  isOwner
  isOpenForAppl

  constructor(
    private fb: FormBuilder,

  ) {
    this.commentForm = fb.group({
      comment: ['']
    });

    this.shareForm = fb.group({
      mynetwork: [null]
    });
   }

  ngOnInit(): void {
  }


  share(item): any {

  }

  comment(content): any {

  }

  getComments(): any {

  }

  likeUnlike(content): any {

  }

}
