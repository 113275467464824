<div class="left-sidebar" id="left-sidebar">
  <div class="profile" id="profile">
    <div class="profile-wrapper">
      <div class="profile-pic-and-info">
        <div class="profile-pic">
          <img class="image" [src]="profilePictureUrl | secure | async" />
        </div>
        <div class="profile-info">
          <div class="name">
            {{ profile?.firstname }} {{ profile?.lastname }}
          </div>
          <div class="designation">{{ profile?.headline }}</div>
        </div>
      </div>
      <div class="profile-status">
        <div class="profile-status-header">
          <div class="profile-status-icon-wrapper">
            <mat-icon class="profile-status-icon">assessment</mat-icon>
          </div>
          <span>{{'view-content.profile-stats.title' | translate}}</span>
        </div>
        <div (click)="openProjectsList()" class="project-item">
          <label class="project">{{'view-content.profile-stats.project-count' | translate}}</label>
          <label class="count">{{profileStats?.projects}}</label>
        </div>
        <div (click)="openContestsList()" class="project-item">
          <label class="project">{{'view-content.profile-stats.contest-count' | translate}}</label>
          <label class="count">{{profileStats?.contests}}</label>
        </div>
      </div>

    </div>
    <!-- Activities from >= 720 width starts here -->
    <div id="activities" class="activity">
      <div class="activity-wrapper">
        <div class="activity-header">
          <div class="clock-wrapper">
            <img class="clock-icon" src="assets/clock.svg" />
          </div>
          <span class="activity-label">{{'view-content.activities.title' | translate}}</span>
        </div>
        <div class="activity-log-spread">
          <div *ngFor="let activity of activities">
            <div class="date">{{ activity.timestamp }}</div>
            <div class="text">You {{ activity.details }}</div>
            <br />
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- Mobile profile -->
  <div class="profile-mobile" id="profile">
    <div class="profile-wrapper">
      <div class="profile-pic-and-info">
        <div class="profile-pic">
          <img class="image" [src]="profilePictureUrl | secure | async" />
        </div>
        <div class="profile-info">
          <div class="name">
            {{ profile?.firstname }} {{ profile?.lastname }}
          </div>
          <div class="designation">{{ profile?.headline }}</div>
        </div>
      </div>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="profile-status-icon">assessment</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            Profile Stats
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="profile-status">
          <div class="project-item">
            <label class="project">Projects</label>
            <label class="count">{{profileStats?.projects}}</label>
          </div>
          <div class="project-item">
            <label class="project">Contests</label>
            <label class="count">{{profileStats?.contests}}</label>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <!-- Activities mobile Start Here -->
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <img class="clock-icon" src="assets/clock.svg" />
        </mat-panel-title>
        <mat-panel-description>
          My Activities
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="activity-log-spread">
        <div *ngFor="let activity of activities">
          <div class="date">{{ activity.timestamp }}</div>
          <div class="text">You {{ activity.details }}</div>
          <br />
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>