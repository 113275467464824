<div class="container">

  <mat-horizontal-stepper #stepper animationDuration="0ms">
    <form [formGroup]="formGroup" (ngSubmit)="submit()" autocomplete="off">

      <!-- Basic Info Step -->
      <mat-step [label]="'edit-content.article.steps.first' | translate" [hasError]="hasErrorFirstTab()"
        errorMessage="Check for errors">
        <section class="basic-info">
          <mat-form-field appearance="outline">
            <mat-label>{{'content.article.basic-info.title' | translate}}</mat-label>
            <input matInput [placeholder]="'content.article.basic-info.placeholder' | translate"
              formControlName="title" />
            <mat-error *ngIf="formGroup.controls['title'].hasError('serverError')">
              {{ formGroup.controls["title"].getError("serverError") }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{'content.article.basic-info.category.title' | translate}}</mat-label>
            <input type="text" [placeholder]="'content.article.basic-info.category.placeholder'" matInput
              formControlName="category" [matAutocomplete]="auto1" />
            <mat-autocomplete #auto1="matAutocomplete">
              <mat-option *ngFor="let category of categories" [value]="'content.article.basic-info.drop-down.categories.'+category.key | translate">
                {{'content.article.basic-info.drop-down.categories.'+category.key | translate}} </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="formGroup.controls['category'].hasError('serverError')">
              {{ formGroup.controls["category"].getError("serverError") }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{'content.article.basic-info.type-publication.title' | translate}}</mat-label>
            <input type="text" [placeholder]="'content.article.basic-info.type-publication.placeholder' | translate"
              matInput formControlName="type" [matAutocomplete]="auto2" required />
            <mat-autocomplete #auto2="matAutocomplete">
              <mat-option *ngFor="let type of publicationTypes" [value]="'content.article.basic-info.drop-down.types.'+type.key | translate">
                {{'content.article.basic-info.drop-down.types.'+type.key | translate}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="formGroup.controls['type'].hasError('serverError')">
              {{ formGroup.controls["type"].getError("serverError") }}
            </mat-error>
          </mat-form-field>

          <div class="quill-editor">
            <h3 class="abstract-title">{{'content.article.basic-info.abstract' | translate}}</h3>
            <quill-editor [styles]="{ height: '170px', 'margin-bottom': '5px' }"
              formControlName="abstract"></quill-editor>
            <mat-error *ngIf="formGroup.controls['abstract'].hasError('serverError')">
              {{ formGroup.controls["abstract"].getError("serverError") }}
            </mat-error>
          </div>

          <div class="image-upload-section">
            <div *ngFor="let i of list" class="logo-container-edit">
              <img [src]="getUrl(mainImageId) | secure | async" />
            </div>
            <div class="button">
              <input #input1 class="hidden-input" type="file" accept="image/*"
                (change)="handleAbstractFileInput($event)" />
              <div class="image-text-container">
                <button type="button" mat-flat-button (click)="input1.click()" [class.spinner]="loading">
                  <mat-icon>insert_photo</mat-icon>
                  {{'content.article.basic-info.article-image.title' | translate}}
                </button>
              </div>
            </div>
          </div>

          <div class="footer-button">
            <button type="button" class="backward-button" mat-raised-button disabled>
              <mat-icon>arrow_backward</mat-icon>
              {{'content.article.article-content.back-button' | translate}}
            </button>

            <button type="button" mat-raised-button matStepperNext>
              {{'content.article.basic-info.next-button.text' | translate}} <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </section>
      </mat-step>

      <!-- Article Content Step -->
      <mat-step [label]="'edit-content.article.steps.second' | translate" [hasError]="hasErrorSecondTab()"
        errorMessage="Check for errors">
        <section class="article-content">
          <div class="quill-editor">
            <h3>Content</h3>
            <quill-editor [styles]="{ height: '270px', 'margin-bottom': '5px' }" formControlName="content"
              class="custom-quill-tooltip"></quill-editor>
            <mat-error *ngIf="formGroup.controls['content'].hasError('serverError')">
              {{ formGroup.controls["content"].getError("serverError") }}
            </mat-error>
            <div *ngFor="let file of files" class="file listoffiles">
              <a [href]="getUrl(file.id) | secure | async" download="{{ file.name }}">
                <span>{{ file.name }}</span>
                <span class="file-size">({{ file.sizebytes | fileSize }})</span>
              </a>
              <mat-icon (click)="removeFile(file.id)">close</mat-icon>
            </div>
          </div>

          <div class="footer-button">
            <button mat-icon-button class="hidden-input" (click)="input2.click()" type="button"
              [class.spinner]="loading">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input #input2 class="hidden-input" type="file" accept="*/*" (change)="handleFileInput($event)" />
            <button mat-flat-button class="attach_file" (click)="input2.click()" type="button"
              [class.spinner]="loading">
              <mat-icon>attach_file</mat-icon>
              Attach File
            </button>
            <div class="btn-container">
              <button mat-raised-button class="backward-button" matStepperPrevious type="button">
                <mat-icon>arrow_backward</mat-icon>
                Back
              </button>
              <button mat-raised-button matStepperNext type="button">
                Next <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </div>
        </section>
      </mat-step>

      <!-- Additional Information Step -->
      <mat-step [label]="'edit-content.article.steps.third' | translate" [hasError]="hasErrorThirdTab()"
        errorMessage="Check for errors">
        <section class="additional-information">
          <div class="quill-editor">
            <h3>Bibliography</h3>
            <quill-editor [styles]="{ height: '170px', 'margin-bottom': '5px' }" formControlName="bibliography"
              class="custom-quill-tooltip"></quill-editor>
            <mat-error *ngIf="formGroup.controls['bibliography'].hasError('serverError')">
              {{ formGroup.controls["bibliography"].getError("serverError") }}
            </mat-error>
          </div>

          <div class="keywords-section">
            <mat-form-field appearance="outline" class="keyword-input">
              <mat-label>Keywords</mat-label>
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip *ngFor="let keyword of this.formGroup.controls['keywords'].value; let i = index"
                  [selectable]="selectable" [removable]="removable" (removed)="removeKeyword(i)">
                  {{ keyword.keyword }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Enter keyword here... - Press ENTER or ',' to separate" [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addKeyword($event)" />
              </mat-chip-list>
            </mat-form-field>
          </div>

          <div class="authorship-section">
            <!-- Authorship Components -->
          </div>

          <!-- <div class="visibility-container">
            <mat-form-field appearance="outline">
              <mat-label>Visibility</mat-label>
              <mat-select formControlName="visibility">
                <mat-option *ngFor="let visibility of visibilities" [value]="visibility.type">
                  {{ visibility.type }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls['visibility'].hasError('serverError')">
                {{ formGroup.controls["visibility"].getError("serverError") }}
              </mat-error>
            </mat-form-field>
          </div> -->

          <div class="footer-button-end">
            <button mat-raised-button class="backward-button" matStepperPrevious type="button">
              <mat-icon>arrow_backward</mat-icon>
              Back
            </button>
            <button mat-flat-button type="submit" class="publish-button" (click)="submit()">
              Publish
            </button>
          </div>
        </section>
      </mat-step>

    </form>
  </mat-horizontal-stepper>
</div>