<div class="container">

  <mat-horizontal-stepper #stepper labelPosition="bottom" [@.disabled]="true">

    <mat-step>
      <form [formGroup]="introForm" (ngSubmit)="submitIntro()">
        <ng-template matStepLabel>{{'profile.manage.talent.intro.tab' | translate}}</ng-template>
        <div class="info-profile-container">
          <mat-form-field appearance="outline">
            <mat-label>{{'profile.manage.talent.intro.first-name.label' | translate}} *</mat-label>
            <input matInput [placeholder]="'profile.manage.talent.intro.first-name.placeholder' | translate"
              formControlName="firstname">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'profile.manage.talent.intro.last-name.label' | translate}} *</mat-label>
            <input matInput [placeholder]="'profile.manage.talent.intro.last-name.placeholder' | translate"
              formControlName="lastname">
          </mat-form-field>
        </div>
        <div fxLayoutAlign="center center">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'profile.manage.talent.intro.summary.label' | translate}}</mat-label>
            <textarea [placeholder]="'profile.manage.talent.intro.summary.placeholder' | translate" matInput #summary
              maxlength="700" formControlName="summary" style="height: 150px;" value=""></textarea>
            <mat-hint align="end">{{summary.value.length}} / 700</mat-hint>
          </mat-form-field>
        </div>
        <div class="footer-button">
          <button mat-stroked-button class="backward-button" matStepperPrevious type="button" disabled>
            <mat-icon>arrow_backward</mat-icon>
            <span>{{'profile.manage.talent.intro.buttons.back.text' | translate}}</span>
          </button>
          <button mat-raised-button matStepperNext type="submit">
            <span>{{'profile.manage.talent.intro.buttons.next.text' | translate}}</span>
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>


    <mat-step>
      <ng-template matStepLabel>{{'profile.manage.talent.experience.tab' | translate}}</ng-template>
      <div class="general-profile-label">
        <h2>{{'profile.manage.talent.experience.header' | translate}}</h2>
        <button mat-mini-fab color="primary" type="button" (click)="openExperienceForm(null)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div cdkDropList (cdkDropListDropped)="dropExperience($event)" class="list">
        <div *ngFor="let experience of experiences" cdkDrag>
          <div class="general-profile-container">
            <div fxLayout="column" fxFlex="80">
              <h3>{{experience.position}} - {{experience.company}}</h3>
              <div *ngIf="!experience.iscurrent">{{experience.frommonth}} {{experience.fromyear}} -
                {{experience.tomonth}} {{experience.toyear}}</div>
              <div *ngIf="experience.iscurrent">{{experience.frommonth}} {{experience.fromyear}} - Present</div>
            </div>
            <div fxFlex="10" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="end center">
              <button mat-icon-button (click)="deleteExperience(experience.eid)">
                <mat-icon class="delete-button">delete</mat-icon>
              </button>
            </div>
            <div fxFlex="10" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="end center">
              <button mat-icon-button (click)="openExperienceForm(experience)">
                <mat-icon class="edit-button">edit</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-button">
        <button mat-stroked-button class="backward-button" matStepperPrevious type="button" disabled>
          <mat-icon>arrow_backward</mat-icon>
          <span>{{'profile.manage.talent.experience.buttons.back.text' | translate}}</span>
        </button>
        <button mat-raised-button matStepperNext type="submit">
          <span>{{'profile.manage.talent.experience.buttons.next.text' | translate}}</span>
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </mat-step>


    <mat-step>
      <ng-template matStepLabel>{{'profile.manage.talent.education.tab' | translate}}</ng-template>
      <div class="general-profile-label">
        <h2>{{'profile.manage.talent.education.header' | translate}}</h2>
        <button mat-mini-fab color="primary" type="button" (click)="openEducationForm(null)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div cdkDropList (cdkDropListDropped)="dropEducation($event)" class="list">
        <div *ngFor="let education of educations" class="general-profile-container" fxLayoutAlign="center center"
          cdkDrag>
          <div fxLayout="column" fxFlex="80">
            <h3>{{education.entityname}}</h3>
            <h4>{{education.degree}} , {{education.fieldofstudy}}</h4>
            <div>{{education.frommonth}} {{education.fromyear}} - {{education.tomonth}} {{education.toyear}}</div>
          </div>
          <div fxFlex="10" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="deleteEducation(education.eid)">
              <mat-icon class="delete-button">delete</mat-icon>
            </button>
          </div>
          <div fxFlex="10" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="openEducationForm(education)">
              <mat-icon class="edit-button">edit</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="footer-button">
        <button mat-stroked-button class="backward-button" matStepperPrevious type="button" disabled>
          <mat-icon>arrow_backward</mat-icon>
          <span>{{'profile.manage.talent.education.buttons.back.text' | translate}}</span>
        </button>
        <button mat-raised-button matStepperNext type="submit">
          <span>{{'profile.manage.talent.education.buttons.next.text' | translate}}</span>
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </mat-step>


    <mat-step>
      <ng-template matStepLabel>{{'profile.manage.talent.qualifications.tab' | translate}}</ng-template>
      <div class="general-profile-label">
        <h2>{{'profile.manage.talent.qualifications.header' | translate}}</h2>
        <button mat-mini-fab color="primary" type="button" (click)="openQualificationForm(null)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div cdkDropList (cdkDropListDropped)="dropQualification($event)" class="list">
        <div *ngFor="let qualification of qualifications" class="general-profile-container"
          fxLayoutAlign="center center" cdkDrag>
          <div fxLayout="column" fxFlex="80">
            <h3>{{qualification.description}}</h3>
            <div>{{qualification.organization}}</div>
          </div>
          <div fxFlex="10" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="deleteQualification(qualification.qid)">
              <mat-icon class="delete-button">delete</mat-icon>
            </button>
          </div>
          <div fxFlex="10" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="openQualificationForm(qualification)">
              <mat-icon class="edit-button">edit</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="footer-button">
        <button mat-stroked-button class="backward-button" matStepperPrevious type="button" disabled>
          <mat-icon>arrow_backward</mat-icon>
          <span>{{'profile.manage.talent.qualifications.buttons.back.text' | translate}}</span>
        </button>
        <button mat-raised-button matStepperNext type="submit">
          <span>{{'profile.manage.talent.qualifications.buttons.next.text' | translate}}</span>
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </mat-step>


    <mat-step>
      <ng-template matStepLabel>{{'profile.manage.talent.portfolio.tab' | translate}}</ng-template>
      <div class="general-profile-label">
        <h2>{{'profile.manage.talent.portfolio.header' | translate}}</h2>
        <button mat-mini-fab color="primary" type="button" (click)="openPortfolioForm(null)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <mat-hint>
        {{'profile.manage.talent.portfolio.hint' | translate}}
      </mat-hint>
      <br /><br />
      <div cdkDropList (cdkDropListDropped)="dropPortfolio($event)" class="list">
        <div *ngFor="let portfolio of portfolios" fxLayout="row" class="general-profile-container"
          fxLayoutAlign="center center" cdkDrag>
          <div fxLayout="column" fxFlex="80">
            <h3>{{portfolio.title}}</h3>
            <div>{{portfolio.description}}</div>
            <div>{{portfolio.files.length > 2 ? 'Files attached ☑️' : 'No files attached'}}</div>
          </div>
          <div fxFlex="10" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="deletePortfolio(portfolio.pid)">
              <mat-icon class="delete-button">delete</mat-icon>
            </button>
          </div>
          <div fxFlex="10" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="openPortfolioForm(portfolio)">
              <mat-icon class="edit-button">edit</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div *ngFor="let file of files" class="file listoffiles uploadedfiles" fxLayout="row"
        fxLayoutAlign="space-between center">
        <a [href]="getUrl(file.id) | secure | async" download="{{file.name}}">
          <span>{{file.name}}</span>
          <span class="file-size">({{file.sizebytes | fileSize}})</span>
        </a>
      </div>

      <div class="footer-button">
        <button mat-stroked-button class="backward-button" matStepperPrevious type="button" disabled>
          <mat-icon>arrow_backward</mat-icon>
          <span>{{'profile.manage.talent.portfolio.buttons.back.text' | translate}}</span>
        </button>
        <button mat-raised-button matStepperNext type="submit">
          <span>{{'profile.manage.talent.portfolio.buttons.next.text' | translate}}</span>
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </mat-step>


    <mat-step>
      <ng-template matStepLabel>{{'profile.manage.talent.skills.tab' | translate}}</ng-template>
      <div class="general-profile-label">
        <h2>{{'profile.manage.talent.skills.header' | translate}}</h2>
        <button mat-mini-fab color="primary" type="button" (click)="openSkillsForm(null)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div cdkDropList (cdkDropListDropped)="dropSkills($event)" class="list">
        <div *ngFor="let skill of skills" class="general-profile-container" cdkDrag>
          <div fxLayout=" column" fxFlex="80">
            <h3>{{skill.industry}}</h3>
            <div class="skill-keyword-container">
              <ng-container *ngFor="let skillItem of skill.skills; let last = last">
                {{ skillItem.skill }}{{ last ? '' : ', ' }}
              </ng-container>
            </div>

          </div>
          <div fxFlex="10" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="deleteSkill(skill.sid)">
              <mat-icon class="delete-button">delete</mat-icon>
            </button>
          </div>
          <div fxFlex="10" fxFlexAlign="center" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="openSkillsForm(skill)">
              <mat-icon class="edit-button">edit</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="footer-button">
        <button mat-stroked-button class="backward-button" matStepperPrevious type="button">
          <mat-icon>arrow_backward</mat-icon>
          <span>{{'profile.manage.talent.skills.buttons.back.text' | translate}}</span>
        </button>
        <button mat-raised-button matStepperNext (click)="submit()">
          <mat-icon>check</mat-icon>
          <span>{{'profile.manage.talent.skills.buttons.submit.text' | translate}}</span>
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

</div>