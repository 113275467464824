<form [formGroup]="skillsForm" (ngSubmit)="submit()">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 style="margin: 0">{{ data ? ('profile.manage.talent.skills.add-modal.edit' | translate ):
      ('profile.manage.talent.skills.add-modal.add' | translate)}}</h2>
  </div>
  <mat-divider></mat-divider>

  <div style="margin-top: 20px">
    <mat-form-field fxFlex>
      <mat-label>{{'profile.manage.talent.skills.add-modal.industry.label' | translate}}</mat-label>
      <input type="text" [placeholder]="'profile.manage.talent.skills.add-modal.industry.placeholder' | translate"
        matInput formControlName="industry" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let industry of industries" [value]="industry.name">
          {{'profile.manage.talent.skills.add-modal.industry.industries.'+industry.key | translate}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="chip-list" fxFlex>
      <mat-label>{{'profile.manage.talent.skills.add-modal.skills.label' | translate}}</mat-label>
      <mat-chip-list #chipList aria-label="Skill selection">
        <mat-chip *ngFor="let skill of selectedSkills" [selectable]="selectable" [removable]="removable"
          (removed)="remove(skill)">
          {{skill}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input [placeholder]="'profile.manage.talent.skills.add-modal.skills.placeholder' | translate" #skillInput
          formControlName="skills" [matAutocomplete]="auto1" [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let skill of skills | async" [value]="skill">
          {{skill}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
    <button mat-stroked-button type="button"
      (click)="close()">{{'profile.manage.talent.skills.add-modal.buttons.cancel.text' | translate}}</button>
    <button mat-raised-button color="primary" type="submit">{{'profile.manage.talent.skills.add-modal.buttons.save.text'
      | translate}}</button>
  </div>

</form>