import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { TalentContestsListComponent } from '../talent-contests-list/talent-contests-list.component';
import { TalentProjectsListComponent } from '../talent-projects-list/talent-projects-list.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-talent-mobile-profile-tab',
  templateUrl: './talent-mobile-profile-tab.component.html',
  styleUrls: ['./talent-mobile-profile-tab.component.scss']
})
export class TalentMobileProfileTabComponent implements OnInit {
  profileStats;
  activities;
  userId

  constructor(
    private dashboardService: DashboardService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getUserStats()
    // this.getActivities()
  }


  getUserStats(): any {
    this.dashboardService.getUserStats().subscribe((resp) => {
      this.profileStats = resp.stats
      this.userId = resp.user_id
      console.log(resp);
      
      this.getActivities()
    })
  }

  openProjectsList(): any {
    this.dialog.open(TalentProjectsListComponent, {
      width: '750px',
      height: '450px',
      panelClass: 'mentor-public-profile',
      data: {  }
    })
      .afterClosed()
      .subscribe(() => { });
  }

  openContestsList(): any {
    this.dialog.open(TalentContestsListComponent, {
      width: '750px',
      height: '450px',
      panelClass: 'mentor-public-profile',
      data: {  }
    })
      .afterClosed()
      .subscribe(() => { });
  }

  getActivities(): any {
    this.dashboardService.getMyActivities(this.userId).subscribe(resp => {
      const { data } = resp
      this.activities = data;
      console.log(resp);
      
    });
  }
}
