<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 style="margin: 0">{{ data ? ('profile.manage.talent.experience.add-modal.edit' | translate ):
    ('profile.manage.talent.experience.add-modal.add' | translate)}}</h2>
  <!--    <button mat-icon-button type="button" (click)="close()">-->
  <!--      <mat-icon>close</mat-icon>-->
  <!--    </button>-->
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="experienceForm" (ngSubmit)="submit()">
    <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign="start center" style="margin-top: 20px">
      <div fxLayout="column" fxFlex="48">

        <mat-form-field>
          <mat-label>{{'profile.manage.talent.experience.add-modal.position.label' | translate}}</mat-label>
          <input matInput [placeholder]="'profile.manage.talent.experience.add-modal.position.placeholder' | translate"
            formControlName="position">
        </mat-form-field>

        <h4 style="margin: 0">{{'profile.manage.talent.experience.add-modal.start.title' | translate}}</h4>

        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex="30">
            <mat-label>{{'profile.manage.talent.experience.add-modal.start.month.title' | translate}}</mat-label>
            <mat-select formControlName="frommonth">
              <mat-option *ngFor="let month of months" [value]="month">
                {{'profile.manage.talent.experience.add-modal.start.month.'+month.toLowerCase() | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="30">
            <mat-label>{{'profile.manage.talent.experience.add-modal.start.year.title' | translate}}</mat-label>
            <mat-select formControlName="fromyear">
              <mat-option *ngFor="let year of years" [value]="year">
                {{year}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxFlex="48">
        <mat-form-field>
          <mat-label>{{'profile.manage.talent.experience.add-modal.company.label' | translate}}</mat-label>
          <input matInput [placeholder]="'profile.manage.talent.experience.add-modal.company.placeholder' | translate"
            formControlName="company">
        </mat-form-field>

        <h4 style="margin: 0">{{'profile.manage.talent.experience.add-modal.end.title' | translate}}</h4>

        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex="30">
            <mat-label>{{'profile.manage.talent.experience.add-modal.end.month.title' | translate}}</mat-label>
            <mat-select formControlName="tomonth">
              <mat-option *ngFor="let month of months" [value]="month">
                {{'profile.manage.talent.experience.add-modal.end.month.'+month.toLowerCase() | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="30">
            <mat-label>{{'profile.manage.talent.experience.add-modal.end.year.title' | translate}}</mat-label>
            <mat-select formControlName="toyear">
              <mat-option *ngFor="let year of years" [value]="year">
                {{year}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div>
      <mat-checkbox formControlName="iscurrent">{{'profile.manage.talent.experience.add-modal.currently-working' | translate}}</mat-checkbox>
    </div>

    <br>
    <mat-divider></mat-divider>
    <br>

    <!--  <div fxLayout="row">-->
    <!--    <mat-form-field fxFlex>-->
    <!--      <mat-label>Summary</mat-label>-->
    <!--      <textarea matInput placeholder="Describe your work experience" formControlName="summary"></textarea>-->
    <!--    </mat-form-field>-->
    <!--  </div>-->

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
      <button mat-stroked-button type="button"
        (click)="close()">{{'profile.manage.talent.experience.add-modal.buttons.cancel.text' | translate}}</button>
      <button mat-raised-button color="primary"
        type="submit">{{'profile.manage.talent.experience.add-modal.buttons.save.text' | translate}}</button>
    </div>
  </form>
</mat-dialog-content>