<form [formGroup]="portfolioForm" (ngSubmit)="submit()">

  <input id="files" name="files" type="hidden" value="gkbj,">

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 style="margin: 0">{{ data ? ('profile.manage.talent.portfolio.add-modal.edit' | translate ):
      ('profile.manage.talent.portfolio.add-modal.add' | translate)}}</h2>
  </div>
  <mat-divider></mat-divider>
  <div style="margin-top: 20px">
    <mat-form-field fxFlex>
      <mat-label>{{'profile.manage.talent.portfolio.add-modal.title.label' | translate}}</mat-label>
      <input matInput [placeholder]="'profile.manage.talent.portfolio.add-modal.title.placeholder' | translate"
        formControlName="title">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field fxFlex>
      <mat-label>{{'profile.manage.talent.portfolio.add-modal.description.label' | translate}}</mat-label>
      <textarea matInput [placeholder]="'profile.manage.talent.portfolio.add-modal.description.placeholder' | translate"
        formControlName="description"></textarea>
    </mat-form-field>
  </div>

  <input type="hidden" formControlName="files">
  <div *ngIf="allfiles">
    <div *ngFor="let file of allfiles; let i = index">
      <a [href]="getUrl(file.fileid) | secure | async" download="{{ file.filename }}">
        <span class="file-size">{{ file.filename }}</span>
      </a>
      <mat-icon (click)="removeSelectedFile(i)" style="cursor: pointer; position: relative;
      top: 8px;">close</mat-icon>
      <br />
    </div>
  </div>

  <div>
    <hr width="100%">
  </div>
  <div>
    <button mat-icon-button class="mobile-file" (click)="input.click()" type="button" [class.spinner]="loading">
      <mat-icon>attach_file</mat-icon>
    </button>

    <input #input class="hidden-input" type="file" accept="*/*" (change)="handleFileInput($event)">
    <button mat-flat-button class="desktop-file" (click)="input.click()" type="button" [class.spinner]="loading">
      <mat-icon>attach_file</mat-icon>
      {{'profile.manage.talent.portfolio.add-modal.attach-file.button.label' | translate}}
    </button>
  </div>


  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
    <button mat-stroked-button type="button"
      (click)="close()">{{'profile.manage.talent.portfolio.add-modal.buttons.cancel.text' | translate}}</button>
    <button mat-raised-button color="primary"
      type="submit">{{'profile.manage.talent.portfolio.add-modal.buttons.save.text' | translate}}</button>
  </div>

</form>