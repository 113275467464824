<div id="activities" class="activity">
    <div class="activity-wrapper">
        <div class="activity-header">
            <div class="clock-wrapper">
                <img class="clock-icon" src="assets/clock.svg" />
            </div>
            <span class="activity-label">{{'dashboard.left-column.activities.title' | translate}}</span>
        </div>
        <div class="activity-log-spread">
            <div *ngFor="let activity of activities">
                <div class="date">{{ activity.timestamp }}</div>
                <div class="text">You {{ activity.details }}</div>
                <br />
            </div>
        </div>
    </div>
</div>