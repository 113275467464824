<div class="background">
  <div class="container">
    <div class="row login">
      <!--  <img alt="icon" src="../../assets/login-background.svg" class="login-background-icon">  -->

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 columns">
        <img alt="icon" class="auth-logo" src="../../assets/logo-final-white.svg" (click)="goToHome()"
          style="cursor: pointer;">
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 columns">
        <mat-tab-group #mtg class="header-less-tabs" animationDuration="0ms">
          <mat-tab>
            <ng-container>
              <form (ngSubmit)="signup()" [formGroup]="signupForm">
                <app-language-dropdown></app-language-dropdown>
                <div class="login-container">
                  <div class="title"> {{'register.title' | translate}} </div>
                  <div class="subtitle"> {{'register.sub-title' | translate}} </div>
                  <div class="company-name-login">
                    <!--  <img alt="icon" src="../../../assets/logo-final.png" style="width: 200px">  -->
                    <!--              <h1>A-GORA</h1>-->
                  </div>
                  <div class="login-body">
                    <div class="login-forms">
                      <div class="error-field" [ngStyle]="{'visibility': serverErrors ? 'visible': 'hidden'}">
                        {{serverErrors ? serverErrors : '&nbsp;'}}
                      </div>

                      <div>
                        <!--  <div class="input-icon">
                            <img alt="input icon" src="../../../assets/login-user.svg">
                          </div>  -->
                        <mat-form-field appearance="outline" class="input-form">
                          <mat-label class="auth-text">{{'register.email-label' | translate}}</mat-label>
                          <input matInput autocapitalize="off" autocomplete="off" class="input-form-email"
                            formControlName="email" placeholder="Email" type="text">
                          <mat-error *ngIf="signupForm.get('email').hasError('email')">
                            {{'register.email-validation' | translate}}
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div>
                        <!--  <div class="input-icon">
                            <img alt="input icon" src="../../../assets/password-icon.svg">
                          </div>  -->
                        <mat-form-field appearance="outline" class="input-form">
                          <mat-label class="auth-text">{{'register.password-label' | translate}}</mat-label>
                          <input matInput autocapitalize="off" autocomplete="off" class="input-form-password"
                            formControlName="password" placeholder="Password" type="password"
                            [type]="hide1 ? 'password' : 'text'">
                          <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide1">
                            <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>

                      <div>
                        <!--  <div class="input-icon">
                            <img alt="input icon" src="../../../assets/password-icon.svg">
                          </div>  -->
                        <mat-form-field appearance="outline" class="input-form">
                          <mat-label class="auth-text">{{'register.repeat-password-label' | translate}}</mat-label>
                          <input matInput autocapitalize="off" autocomplete="off" class="input-form-password"
                            formControlName="passwordConfirm" placeholder="Repeat Password" type="password"
                            [type]="hide2 ? 'password' : 'text'">
                          <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide2">
                            <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>

                      <div *ngIf="!accountTypeIsPreset">
                        <mat-form-field appearance="outline" class="input-form">
                          <mat-label class="auth-text">{{'register.account-type-label' |translate }}</mat-label>
                          <mat-select formControlName="registrationType">
                            <mat-option [value]="0">{{'register.account-type.company' | translate}}</mat-option>
                            <mat-option [value]="1">{{'register.account-type.talent' |translate}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="bottom-buttons">
                      <div class="login-button">
                        <button mat-button type="submit">{{'register.sign-up' | translate}}</button>
                      </div>
                      <!--  <div class="login-button-google">
                          <button mat-button type="submit">
                            <img alt="icon" src="../../../assets/google-signin.svg" style="margin-right: 17%; margin-left: 8%;">
                            Sign up with Google
                          </button>
                        </div>  -->
                      <div class="separation-line">
                        <span class="line"></span>
                        <span class="line-text" style="padding: 0 10px;"> {{'register.separator' | translate}} </span>
                        <span class="line"></span>
                      </div>
                      <div class="signin-button">
                        <button mat-button (click)="goToLogin()">{{'register.sign-in' | translate}}</button>
                      </div>
                    </div>
                    <!-- <p class="signup">You have an account?
                        <b (click)="goToLogin()" class="pointer" style="text-decoration: underline;">Login</b>
                      </p>  -->
                  </div>
                </div>
              </form>
            </ng-container>
          </mat-tab>

          <mat-tab>
            <ng-container>
              <form (ngSubmit)="confirm()" [formGroup]="pinForm">
                <div class="login-container">
                  <div class="company-name-login">
                    <img alt="icon" src="../../../assets/logo-final.png" style="width: 200px">
                    <!--              <h1>A-GORA</h1>-->
                  </div>
                  <div class="login-body">
                    <div class="login-forms">
                      <div class="error-field" [ngStyle]="{'visibility': serverErrors ? 'visible': 'hidden'}">
                        {{serverErrors ? serverErrors : '&nbsp;'}}
                      </div>

                      <div>
                        <mat-form-field class="input-form">
                          <input matInput autocapitalize="off" autocomplete="off" class="input-form-email"
                            formControlName="pincode" placeholder="Pin" type="text">
                          <!--                    <mat-error *ngIf="userForm.get('firstname').hasError('firstname')">-->
                          <!--                      Please enter a valid email address-->
                          <!--                    </mat-error>-->
                        </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="center center">
                        <button fxFlex="50" mat-stroked-button color="primary" type="button"
                          (click)="getNewPinCode()">Resend
                          Code
                        </button>
                      </div>
                    </div>
                    <div class="login-button">
                      <button mat-button type="submit">Confirm</button>
                      <p class="signup">You have an account?
                        <b (click)="goToLogin()" class="pointer" style="text-decoration: underline;">Login</b>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>

    </div>
  </div>
</div>