import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DashboardService } from '../../_services/dashboard.service';

@Component({
  selector: 'app-talent-profile-stats',
  templateUrl: './talent-profile-stats.component.html',
  styleUrls: ['./talent-profile-stats.component.scss']
})
export class TalentProfileStatsComponent implements OnInit {

  profile;
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  skills;
  achievements;
  isMobile = false;
  entityId;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.getUserProfile();
    this.getActivities();
  }

  getUserProfile(): any {
    this.dashboardService.getUserProfile().subscribe((resp) => {
      this.profile = resp.data;
      this.entityId = this.profile.entityid;
    });
  }

  getActivities(): any {
    this.dashboardService.getUserActivity().subscribe((resp) => {      
      this.achievements = resp.data.achievements;
    });
  }

}
