import { Component, OnInit, Inject } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-contact-modal',
  templateUrl: './add-contact-modal.component.html',
  styleUrls: ['./add-contact-modal.component.scss']
})
export class AddContactModalComponent implements OnInit {

  formGroup: FormGroup;
  subscription_data: [];
  profile;

  constructor(private service: DashboardService,
              private fb: FormBuilder, 
              @Inject(MAT_DIALOG_DATA) public data: any, 
              private snackbar: MatSnackBar, 
              private router: Router,
              private dialogRef: MatDialogRef<AddContactModalComponent>) 
              { }

  ngOnInit(): void {   
    this.getProfileData() 
    this.getCredits()

    this.formGroup = this.fb.group({
      subscription: ['', Validators.required],
    });
  }

  getCredits() {
    this.service
      .getSubscriptions()
      .subscribe((resp) => {
        if(resp.data.length > 0) {
          this.subscription_data = resp.data.filter((s) => s.credit_type === 'contacts')    
        }        
    });
  }

  getProfileData() {
    this.service
      .getUserProfile()
      .subscribe((resp) =>{
        this.profile = resp.data
      })
  }

  submit(selectedValue: any): void {
    const dataObj = {
        user_id: this.data.id,
        entity_id: this.profile.entityid.eid,
        subscription_id: selectedValue.id,
        credit_amount: selectedValue.credit_amount,
        sender_eid: this.profile.entityid.eid
    };


    this.service.postEntityContact(dataObj).subscribe(
        () => {
            this.snackbar.open('User added to your contacts successfully!', 'Close', {
                duration: 3000,
                panelClass: 'success-snackbar',
            });
            this.dialogRef.close();
            this.router.navigate(['/message', { entityId: dataObj.entity_id }]).then();
        },
        (error) => {
          console.log(error);
          
            let errorMessage = 'An error occurred while adding the user to your contacts.';
            if (error.status === 409) {
                errorMessage = 'User is already in your contacts.';
            } else if (error.status === 401) {
                errorMessage = 'Not enough credits.';
            }

            this.snackbar.open(errorMessage, 'Close', {
                duration: 5000,
                panelClass: 'danger-snackbar',
            });
        }
    );
}


}
