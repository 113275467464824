<div class="entity-project-container" id="newsfeed">
    <div *ngIf="projects && projects.length > 0 ; else notFoundProjects" class="project-card">
        <div class="" *ngFor="let project of projects">
            <app-entity-project-view [project]="project"></app-entity-project-view>
        </div>
    </div>

    <ng-template #notFoundProjects>
        <mat-card>
            <mat-card>
                <mat-card-subtitle>
                    {{'entity-dashboard.mobile-version.bottom-nav.entity-content.empty-message.projects' | translate}}
                </mat-card-subtitle>
            </mat-card>
        </mat-card>
    </ng-template>
</div>