<div class="entity-contest-container" id="newsfeed">
    <div *ngIf="contests && contests.length > 0 ; else notFoundContests"  class="contest-card">
        <div *ngFor="let contest of contests">
            <app-entity-contest-view [contest]="contest"></app-entity-contest-view>
        </div>
    </div>

    <ng-template #notFoundContests>
        <mat-card>
            <mat-card>
                <mat-card-subtitle>
                    {{'entity-dashboard.mobile-version.bottom-nav.entity-content.empty-message.contests'  | translate}}
                </mat-card-subtitle>
            </mat-card>
        </mat-card>
    </ng-template>
</div>