// dashboard-tabs-routing.module.ts

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TalentMobileProfileTabComponent } from 'src/app/dashboard/_components/talent-mobile-profile-tab/talent-mobile-profile-tab.component';
import { TalentMobileNewsfeedTabComponent } from 'src/app/dashboard/_components/talent-mobile-newsfeed-tab/talent-mobile-newsfeed-tab.component';
import { TalentMobileChallengesTabComponent } from 'src/app/dashboard/_components/talent-mobile-challenges-tab/talent-mobile-challenges-tab.component';


const routes: Routes = [
  { path: 'nav-tab/profile', component: TalentMobileProfileTabComponent },
  { path: 'nav-tab/newsfeed', component: TalentMobileNewsfeedTabComponent },
  { path: 'nav-tab/challenges', component: TalentMobileChallengesTabComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardTabsRoutingModule { }
