import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-talent-projects-list',
  templateUrl: './talent-projects-list.component.html',
  styleUrls: ['./talent-projects-list.component.scss']
})
export class TalentProjectsListComponent implements OnInit {
  projects: []
  constructor(private dashService: DashboardService, private router: Router) {}

  ngOnInit(): void {
    this.dashService.getTalentProjects().subscribe((resp) => {
      this.projects = resp.data
    })
  }

  goToProject(id, eid) {
    this.router.navigate(['project/manage', { id, entityId: eid }]).then();
  }
}
