<div class="container">
  <span *ngIf="isLoadingEntity" class="spinner-container">
    <mat-spinner diameter="60" color="primary"></mat-spinner>
  </span>

  <div *ngIf="!isLoadingEntity" class="grid-container">
    <div *ngIf="entity?.isCompany" class="desktop-container">
      <div class="profile-card">
        <!-- <input type="file" #input1  accept="image/*" (change)="changeLogo($event)"> -->
        <div class="logo-info-container">

          <div *ngFor="let i of logoRefresh">
            <div class="logo-container mat-elevation-z2">
              <img class="image" src="../../../../assets/logo-final.png" alt="logo" class="logo-image" *ngIf="!hasLogo">
              <img class="image" [src]="getLogoUrl() | secure | async" alt="logo" class="logo-image" *ngIf="hasLogo">
              <!-- <div class="middle">
              <mat-icon>camera_alt</mat-icon>
              <div class="span">Change Logo</div>
            </div> -->
            </div>
          </div>

          <!-- <div class="logo-container mat-elevation-z2" *ngIf="secureImageUrl | async as bufferUrl; else loadingImage" [style.background-image]="bufferUrl"> </div>
          <ng-template #loadingImage class="logo-container mat-elevation-z2"><span>Image is Loading...</span></ng-template> -->


          <div class="text-block">
            <div class="name">{{entity?.orgname}}</div>
            <div class="designation">{{entity?.website}}</div>
            <div class="place">{{entity?.industry}}</div>
            <!-- <div class="social-block">
              <button mat-icon-button>
                <mat-icon svgIcon="instagram"></mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon svgIcon="twitter"></mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon svgIcon="facebook"></mat-icon>
              </button>
            </div> -->
          </div>
        </div>
        <div class="card~">
          <div class="project-spread-container">
            <div class="active-project">
              <span class="project-label">{{'entity-dashboard.desktop-version.profile-info.article-count.text' |
                translate}}</span>
              <h3 class="count">{{ articles?.length > 0 ? articles?.length : 0 }}</h3>
            </div>
            <div class="in-progress">
              <span class="project-label">{{'entity-dashboard.desktop-version.profile-info.contest-count.text' |
                translate}}</span>
              <h3 class="count">{{contests?.length > 0 ? contests?.length : 0}}</h3>
            </div>
            <div class="complete">
              <span class="project-label">{{'entity-dashboard.desktop-version.profile-info.project-count.text' |
                translate}}</span>
              <h3 class="count">{{ projects?.length > 0 ? projects?.length : 0 }}</h3>
            </div>
          </div>
        </div>
        <div class="edit-icon">
          <button (click)="modifyCompany(entityId)">
            <mat-icon class="edit-button">edit</mat-icon>
          </button>
        </div>
      </div>

      <div class="entity-newsfeed" id="newsfeed">
        <div class="create-content-container">
          <mat-card class="create-content">
            <mat-card-title class="create-content-header">{{'entity-dashboard.desktop-version.create-content.title' |
              translate}}</mat-card-title>
            <mat-card-actions class="create-content-button-container">
              <button mat-button type="button" (click)="createArticle()">
                <mat-icon class="create-article-button-icon">library_books</mat-icon>
                <span>{{'entity-dashboard.desktop-version.create-content.create-article' | translate}}</span>
              </button>
              <button mat-button type="button" (click)="createContest()">
                <mat-icon class="create-contest-button-icon" svgIcon="contest-create-trophy"></mat-icon>
                <span>{{'entity-dashboard.desktop-version.create-content.create-contest' | translate}}</span>
              </button>
              <button mat-button type="button" (click)="createProject()">
                <mat-icon class="create-project-button-icon">business_center</mat-icon>
                <span>{{'entity-dashboard.desktop-version.create-content.create-project' | translate}}</span>
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="newsfeed-card" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300"
          (scrolled)="onScroll()">
          <div class="newsfeed-cards-container" *ngFor="let newsfeed of newsfeeds" #lastElement>
            <app-newsfeed-card [newsfeed]="newsfeed" [entityId]="this.entityId"></app-newsfeed-card>
          </div>
        </div>
      </div>
      <div class="rightcolumn entity-homepage-columns">
        <!-- <nav mat-tab-nav-bar class="mobile-content-tab-container">
        <a mat-tab-link [disableRipple]="true" *ngFor="let link of links" (click)="activeLink = link.name"
          [active]="activeLink == link.name" [ngClass]="link.class" [routerLink]="['tab', link.name]"
          routerLinkActive="active">
          {{link.name}}
        </a>
      </nav> -->


        <div class="card">
          <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
            <mat-tab style="width: 20px;"
              [label]="'entity-dashboard.desktop-version.entity-content.contests-tab.tab' | translate">
              <app-small-contest-card [contests]="contests"></app-small-contest-card>
            </mat-tab>
            <mat-tab [label]="'entity-dashboard.desktop-version.entity-content.projects-tab.tab' | translate">
              <app-small-project-card [projects]="projects"></app-small-project-card>
            </mat-tab>
            <mat-tab [label]="'entity-dashboard.desktop-version.entity-content.articles-tab.tab' | translate">
              <app-small-article-card [articles]="articles"></app-small-article-card>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!-- <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
        <mat-tab style="width: 20px;" label="People">
          People
          <app-small-people-experts-container></app-small-people-experts-container>
        </mat-tab>
      </mat-tab-group> -->
      </div>
    </div>
    <!-- <div class="mobile-container"> -->
    <div *ngIf="entity?.isCompany" class="bottom-nav">
      <div class="profile-card">
        <!-- <input type="file" #input1  accept="image/*" (change)="changeLogo($event)"> -->
        <div class="logo-info-container">
          <div *ngFor="let i of logoRefresh">
            <div class="logo-container mat-elevation-z2" (click)="input1.click()">
              <img class="image" src="../../../../assets/logo-final.png" alt="logo" class="logo-image" *ngIf="!hasLogo">
              <img class="image" [src]="getLogoUrl() | secure | async" alt="logo" class="logo-image" *ngIf="hasLogo">
              <!-- <div class="middle">
                <mat-icon>camera_alt</mat-icon>
                <div class="span">Change Logo</div>
              </div> -->
            </div>
          </div>
          <div class="text-block">
            <div class="name">{{entity?.orgname}}</div>
            <div class="designation">{{entity?.website}}</div>
            <div class="place">{{entity?.industry}}</div>
            <div class="social-block">
              <button mat-icon-button>
                <mat-icon svgIcon="instagram"></mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon svgIcon="twitter"></mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon svgIcon="facebook"></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="card2">
          <div class="project-spread-container">
            <div class="active-project">
              <span class="project-label">{{'entity-dashboard.mobile-version.profile-info.article-count.text' |
                translate}}</span>
              <h3 class="count">{{ articles?.length > 0 ? articles?.length : 0 }}</h3>
            </div>
            <div class="in-progress">
              <span class="project-label">{{'entity-dashboard.mobile-version.profile-info.contest-count.text' |
                translate}}</span>
              <h3 class="count">{{contests?.length > 0 ? contests?.length : 0}}</h3>
            </div>
            <div class="complete">
              <span class="project-label">{{'entity-dashboard.mobile-version.profile-info.project-count.text' |
                translate}}</span>
              <h3 class="count">{{ projects?.length > 0 ? projects?.length : 0 }}</h3>
            </div>
          </div>
        </div>
        <div class="edit-icon">
          <button (click)="modifyCompany(entityId)">
            <mat-icon class="edit-button">edit</mat-icon>
          </button>
        </div>
      </div>
      <div class="create-content-container">
        <mat-card class="create-content">
          <mat-card-title class="create-content-header">{{'entity-dashboard.mobile-version.create-content.title' |
            translate}}</mat-card-title>
          <mat-card-actions class="create-content-button-container">

            <button mat-button type="button" (click)="createArticle()">
              <mat-icon class="create-article-button-icon">library_books</mat-icon>
              <span>{{'entity-dashboard.mobile-version.create-content.create-article' | translate}}</span>
            </button>
            <button mat-button type="button" (click)="createContest()">
              <mat-icon class="create-contest-button-icon" svgIcon="contest-create-trophy"></mat-icon>
              <span>{{'entity-dashboard.mobile-version.create-content.create-contest' | translate}}</span>
            </button>
            <button mat-button type="button" (click)="createProject()">
              <mat-icon class="create-project-button-icon">business_center</mat-icon>
              <span>{{'entity-dashboard.mobile-version.create-content.create-project' | translate}}</span>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
      <nav mat-tab-nav-bar class="bottom-nav-button-container">
        <a mat-tab-link [disableRipple]="true" *ngFor="let bottomNavLink of bottomNavLinks"
          (click)="bottomNavActiveLink = bottomNavLink.name" [active]="bottomNavActiveLink == bottomNavLink.name"
          [ngClass]="bottomNavLink.class" [routerLink]=" bottomNavLink.link" routerLinkActive="active">
          <!-- {{bottomNavLink.name.toLowerCase()}} -->
          {{'entity-dashboard.mobile-version.bottom-nav.'+bottomNavLink.name.toLowerCase() | translate}}
        </a>
      </nav>
      <router-outlet></router-outlet>

    </div>

    <div *ngIf="!entity?.isCompany" class="no-entity-selected">
      <div class="no-entity-info">
        {{'entity-dashboard.desktop-version.error-entity.text' | translate}}
      </div>
    </div>

  </div>

</div>