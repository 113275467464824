import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProfileService } from '../../_services/profile.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SnackbarMessagesComponent } from 'src/app/common/snackbar-messages/snackbar-messages.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organization-entity',
  templateUrl: './organization-entity.component.html',
  styleUrls: ['./organization-entity.component.scss']
})

export class OrganizationEntityComponent implements OnInit {
  snackbarMessages: SnackbarMessagesComponent

  form: FormGroup;
  industries: Observable<string[]>;
  disciplines: Observable<string[]>;
  isEducational = false;
  list = [];
  orgLegalTypes;
  orgTypes;
  loading = false;
  logoUrl = `${environment.API_HOST}/files/profilepicture/`;

  watcher: Subscription;
  isMobile = false;
  isUpdate = false;

  entity = {
    orgtype: null,
    orgname: '',
    headline: '',
    description: '',
    website: '',
    publicurls: '', // Corrected to string
    industry: '',
    academicdiscipline: '',
    // isinstitution: '',
    legaltype: '',
    logofileid: ''
  };

  constructor(private fb: FormBuilder,
    private service: ProfileService,
    private snackbar: MatSnackBar,
    private router: Router,
    private location: Location,
    private mediaObserver: MediaObserver,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.snackbarMessages = new SnackbarMessagesComponent(snackbar, translate)

    this.form = this.fb.group({
      orgtype: [null, Validators.required],
      orgname: ['', Validators.required],
      headline: ['', Validators.required],
      description: [''],
      website: [''],
      publicurls: [''],
      industry: [''],
      academicdiscipline: [''],
      // isinstitution: [''],
      legaltype: [''],
      logofileid: ['']
    });

    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.isMobile = change.mqAlias == 'xs'
    })
  }

  ngOnInit(): void {
    this.getOrgTypes();
    this.getOrgLegalTypes();

    if (this.route.snapshot.paramMap.get('id')) {
      this.getEntityData(this.route.snapshot.paramMap.get('id'))
    } else {
      this.form.controls['orgtype'].valueChanges.subscribe(value => {
        this.form.controls['orgname'].patchValue('');
        this.form.controls['headline'].patchValue('');
        this.form.controls['description'].patchValue('');
        this.form.controls['website'].patchValue('');
        this.form.controls['publicurls'].patchValue('');
        this.form.controls['industry'].patchValue('');
        this.form.controls['academicdiscipline'].patchValue('');
        // this.form.controls['isinstitution'].patchValue('');
        this.form.controls['legaltype'].patchValue('');
        this.form.controls['logofileid'].patchValue('');
        this.list = [];

        this.industries = this.form.controls['industry'].valueChanges.pipe(
          startWith(''),
          map(val => this.getIndustryPredictions(val)));
      });
    }
  }

  getEntityData(eid) {
    this.service.readEntity(eid).subscribe(resp => {
      if (resp.data[0]) {
        this.isUpdate = true;
        this.entity = resp.data[0];
        this.form.patchValue({
          orgname: this.entity.orgname,
          headline: this.entity.headline,
          description: this.entity.description,
          orgtype: this.entity.orgtype,
          website: this.entity.website,
          publicurls: this.entity.publicurls,
          industry: this.entity.industry,
          legaltype: this.entity.legaltype,
          logofileid: this.entity.logofileid
        });

        if (this.form.controls['logofileid'].value !== "") this.list = [this.form.controls['logofileid']];

        // Determine if it's an educational institution
        this.isEducational = this.entity.orgtype === 'Educational institution';

        // Fetch disciplines if it's an educational institution, else fetch industries
        this.industries = this.form.controls['industry'].valueChanges.pipe(
          startWith(''),
          map(val => this.getIndustryPredictions(val)));
      }
    });
  }

  updateEnity(): any {
    if (this.form.valid) {
      try {
        this.service.updateEntity(this.form.value, this.route.snapshot.paramMap.get('id')).subscribe((resp) => this.onSuccess(resp), (error) => this.onError(error));
      } catch (error) {
        console.log(error);
      }
    } else {
      this.snackbar.open('Please fill out all inputs!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    }
  }

  getIndustryPredictions(input: string): Array<any> {
    const list = [];
    this.service.searchEntityIndustries({ srch: input }).subscribe(response => {
      response['data'].forEach(item => list.push(item));
    });
    return list;
  }

  getIndustries(input: string): any {
    this.service.searchEntityIndustries({ srch: input }).subscribe(resp => {
      this.industries = resp.data
    })
  }

  getAcademicPredictions(input: string): Array<any> {
    const list = [];
    this.service.searchAcademicDisciplines({ srch: input }).subscribe(response => {
      response['data'].forEach(item => list.push(item.academicdiscipline));
    });
    return list;
  }

  getOrgLegalTypes(): any {
    this.service.getOrgLegalTypes().subscribe(resp => this.orgLegalTypes = resp['data']);
  }

  getOrgTypes(): any {
    this.service.getOrgTypes().subscribe(resp => this.orgTypes = resp['data']);
  }

  handleFileInput(event): any {
    this.loading = true;
    const formData: FormData = new FormData();
    const file: File = event.target.files[0];
    formData.append('filecontent', file, file.name);
    this.service.uploadLogo(formData).subscribe(resp => {
      this.form.controls['logofileid'].patchValue(resp['logofileid']);
      this.list = [1];
      this.loading = false;
    }, () => {
    });
  }

  getUrl(): string {
    return this.logoUrl + this.form.controls['logofileid'].value;
  }

  submit(): any {
    console.log(this.form);

    if (this.form.valid) {
      try {
        this.service.createEntity(this.form.value).subscribe((resp) => this.onSuccess(resp), (error) => this.onError(error));
      } catch (err) {
        console.log(err);
      }
    } else {
      this.snackbar.open('Please fill out all inputs!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    }
  }

  initPublicUrl(): any {
    return this.fb.group({
      name: [''],
      url: ['']
    });
  }

  addPublicUrl(): any {
    const control = this.form.controls['publicurls'] as FormArray;
    control.push(this.initPublicUrl());
  }

  removePublicUrl(i: number): any {
    const control = this.form.controls['publicurls'] as FormArray;
    control.removeAt(i);
  }

  onSuccess(resp): any {
    this.snackbarMessages.onSuccess()
    if (resp.entityid) {
      window.location.href = "entity/homepage/" + resp.entityid;
    } else {
      window.location.href = "entity/homepage/" + this.route.snapshot.paramMap.get('id')
    }
  }

  onError(error): any {
    this.snackbarMessages.onError()
    this.setServerErrors(error);
  }

  setServerErrors(error): any {
    error.data?.msg.forEach(obj => {
      this.form.get(obj.param).setErrors({ serverError: obj.msg });
    });
  }

  goBack(): any {
    this.router.navigate(['/profile'])
  }

}
