import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { EntityHomepageComponent } from './_components/entity-homepage/entity-homepage.component';
import { ManageMessagesComponent } from './_components/manage-messages/manage-messages.component';
import { ManageNotificationsComponent } from './_components/manage-notifications/manage-notifications.component';
import { SmallArticleCardComponent } from './_components/small-article-card/small-article-card.component';
import { SmallContentCardsComponent } from './_components/small-content-cards/small-content-cards.component';
import { SmallProjectCardComponent } from './_components/small-project-card/small-project-card.component';
import { SmallContestCardComponent } from './_components/small-contest-card/small-contest-card.component';
import { NewsfeedComponent } from './_components/newsfeed/newsfeed.component';
import { MobileContentComponent } from './_components/mobile-content/mobile-content.component';
import { MobileConnectComponent } from './_components/mobile-connect/mobile-connect.component';
import { EntityArticleViewComponent } from './_components/entity-article-view/entity-article-view.component';
import { EntityContestViewComponent } from './_components/entity-contest-view/entity-contest-view.component';
import { EntityProjectViewComponent } from './_components/entity-project-view/entity-project-view.component';
import { EntityContentContestComponent } from './_components/entity-content-contest/entity-content-contest.component';
import { EntityContentProjectComponent } from './_components/entity-content-project/entity-content-project.component';
import { EntityContentArticleComponent } from './_components/entity-content-article/entity-content-article.component';
import { EntityEditContentComponent } from './_components/entity-edit-content/entity-edit-content.component';
import { ArticleReadComponent } from '../article/_components/article-read/article-read.component';
import { ContestReadComponent } from '../contest/_components/contest-read/contest-read.component';
import { ProjectReadComponent } from '../project/_components/project-read/project-read.component';
import { EntityViewArticleComponent } from './_components/entity-view-article/entity-view-article.component';
import { EntityViewContestComponent } from './_components/entity-view-contest/entity-view-contest.component';
import { EntityViewProjectComponent } from './_components/entity-view-project/entity-view-project.component';
import { DashboardTabsRoutingModule } from '../navigation/dashboard-tabs-routing/dashboard-tabs-routing.module';

const routes: Routes = [
  {
    path: '', component: DashboardComponent, children: [
      {
        path: '',
        loadChildren: () => DashboardTabsRoutingModule,
      }
    ]
  },
  { path: 'manage-messages', component: ManageMessagesComponent },
  { path: 'manage-notifications', component: ManageNotificationsComponent },
  {
    path: 'homepage/:id', component: EntityHomepageComponent,
    children: [
      { path: '', redirectTo: 'nav-tab/newsfeed', pathMatch: 'full' },
      { path: 'nav-tab/newsfeed', component: NewsfeedComponent },
      {
        path: 'nav-tab/content', component: MobileContentComponent,
        children: [
          { path: 'tab', redirectTo: 'tab/articles', pathMatch: 'full' },
          // { path: 'tab/:tabName', component: SmallArticleCardComponent }
          {
            path: 'tab/Articles', component: EntityContentArticleComponent,
            children: [
              { path: 'view/:articleId', component: EntityArticleViewComponent }
            ]
          },
          {
            path: 'tab/Contests', component: EntityContentContestComponent,
            children: [
              { path: 'view/:contestId', component: EntityContestViewComponent }
            ]
          },
          {
            path: 'tab/Projects', component: EntityContentProjectComponent,
            children: [
              {
                path: 'view/:projectId', component: EntityProjectViewComponent
              }
            ]
          }
        ]
      },
      { path: 'nav-tab/connect', component: MobileConnectComponent }
    ]
  },
  {
    path: 'view-content/:entityId', component: EntityEditContentComponent,
    children: [
      { path: 'article/:articleId', component: EntityViewArticleComponent },
      { path: 'contest/:contestId', component: EntityViewContestComponent },
      { path: 'project/:projectId', component: EntityViewProjectComponent },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {
}
