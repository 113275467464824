import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-entity-contest-view',
  templateUrl: './entity-contest-view.component.html',
  styleUrls: ['./entity-contest-view.component.scss']
})
export class EntityContestViewComponent implements OnInit {
  @Input() contest;

  myNetwork;
  sendForm: FormGroup;
  shareForm: FormGroup;
  commentForm: FormGroup;
  contestImgURL = `${environment.API_HOST}/files/profilepicture/`;

  constructor(
    private fb: FormBuilder,
    private router: Router

  ) { 
    this.sendForm = fb.group({
      userid: [null],
    });

    this.shareForm = fb.group({
      mynetwork: [null]
    });

    this.commentForm = fb.group({
      comment: ['']
    });
  }

  ngOnInit(): void {

  }

  getContestMainImage(contest): any {
    return `${this.contestImgURL}${contest}`
  }

  keywordParser(contest): any {
    return contest.keywords
    // return JSON.parse(keywords)
  }

  share(shareTrigger): any {

  }

  comment(item, postTrigger): any {

  }

  like(item): any {

  }

  getComments(item): any {

  }

  editContest(): any {
    this.router.navigate([`entity/view-content/${this.contest.ownerid}/contest/${this.contest.contestid}`])
  }
}
