<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 style="margin: 0">{{ data ? ('profile.manage.talent.education.add-modal.edit' | translate ):
    ('profile.manage.talent.education.add-modal.add' | translate)}}</h2>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="educationForm" (ngSubmit)="submit()">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0px" style="margin-top: 20px">
      <mat-form-field>
        <mat-label>{{'profile.manage.talent.education.add-modal.country.label' | translate}}</mat-label>
        <input matInput [placeholder]="'profile.manage.talent.education.add-modal.country.placeholder' | translate"
          formControlName="country" [matAutocomplete]="auto1">
        <mat-autocomplete #auto1="matAutocomplete">
          <mat-option *ngFor="let country of countries" [value]="'profile.manage.talent.education.add-modal.country.'+country.key | translate">
            {{'profile.manage.talent.education.add-modal.country.'+country.key | translate}}
          </mat-option>
        </mat-autocomplete>
        <mat-hint>{{'profile.manage.talent.education.add-modal.country.hint' | translate}}</mat-hint>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{'profile.manage.talent.education.add-modal.school.label' | translate}}</mat-label>
        <input matInput [placeholder]="'profile.manage.talent.education.add-modal.school.placeholder' | translate"
          formControlName="entityname">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field fxFlex>
        <mat-label>{{'profile.manage.talent.education.add-modal.degree.label' | translate}}</mat-label>
        <input matInput [placeholder]="'profile.manage.talent.education.add-modal.degree.placeholder' | translate"
          formControlName="degree">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field fxFlex>
        <mat-label>{{'profile.manage.talent.education.add-modal.field-of-study.label' | translate}}</mat-label>
        <input matInput
          [placeholder]="'profile.manage.talent.education.add-modal.field-of-study.placeholder' | translate"
          formControlName="fieldofstudy">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0px">
      <div fxLayout="column" fxFlex="50">
        <h4 style="margin: 0">{{'profile.manage.talent.education.add-modal.start-date.header' | translate}}</h4>

        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex="30">
            <mat-label>{{'profile.manage.talent.education.add-modal.start-date.month.title' | translate}}</mat-label>
            <mat-select formControlName="frommonth">
              <mat-option *ngFor="let month of months" [value]="month">
                {{'profile.manage.talent.education.add-modal.start-date.month.'+month.toLowerCase() | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="30">
            <mat-label> {{'profile.manage.talent.education.add-modal.start-date.year.label' | translate}} </mat-label>
            <mat-select formControlName="fromyear">
              <mat-option *ngFor="let year of years" [value]="year">
                {{year}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxFlex="50">
        <h4 style="margin: 0">{{'profile.manage.talent.education.add-modal.end-date.header' | translate}}</h4>

        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex="30">
            <mat-label>{{'profile.manage.talent.education.add-modal.end-date.month.title' | translate}}</mat-label>
            <mat-select formControlName="tomonth">
              <mat-option *ngFor="let month of months" [value]="month">
                {{'profile.manage.talent.education.add-modal.end-date.month.'+month.toLowerCase() | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="30">
            <mat-label> {{'profile.manage.talent.education.add-modal.end-date.year.label' | translate}}
            </mat-label>
            <mat-select formControlName="toyear">
              <mat-option *ngFor="let year of years" [value]="year">
                {{year}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <br>
    <mat-divider></mat-divider>
    <br>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
      <button mat-stroked-button type="button"
        (click)="close()">{{'profile.manage.talent.education.add-modal.buttons.cancel.text' | translate}}</button>
      <button mat-raised-button color="primary"
        type="submit">{{'profile.manage.talent.education.add-modal.buttons.save.text' | translate}}</button>
    </div>
  </form>
</mat-dialog-content>