import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandler } from '../../common/error-handler/error.handler';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { passwordMisMatchValidator } from '../../common/error-handler/customValidators';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { ScreenSizeService } from 'src/app/navigation/screen-size.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('mtg', { static: true }) mtg: MatTabGroup;
  loginForm: FormGroup;
  pinForm: FormGroup;
  pwdForm: FormGroup;
  errors: any = {};
  returnUrl: string;
  errorMessage = null;
  hide = true;
  profile;
  screenSize: number;
  screenSizeSub: Subscription;

  constructor(private authService: AuthService,
    private router: Router,
    private errorHandler: ErrorHandler,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private screen: ScreenSizeService
  ) {
    this.screenSizeSub = this.screen.getScreenWidth().subscribe(width => {
      this.screenSize = width
    })
  }

  ngOnInit(): void {
    this.authService.logout();
    this.loginForm = this.getLoginForm();
    this.initialPinForm();
    this.initialResetPasswordForm();
    this.onFormValueChanges();
    this.returnUrl = 'dashboard';
  }

  ngOnDestroy(): void {
    if (this.screenSizeSub) this.screenSizeSub.unsubscribe()
  }

  goToHome(): any {
    this.router.navigate(['home/']).then();
  }

  public getLoginForm(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.required, Validators.maxLength(150)]],
      password: ['', [Validators.required, Validators.maxLength(128)]]
    });
  }

  initialPinForm(): any {
    this.pinForm = this.fb.group({
      pincode: ['', Validators.required],
    });
  }

  initialResetPasswordForm(): any {
    this.pwdForm = this.fb.group({
      reset_pwd_email: ['', Validators.required],
    });
  }

  login(): any {
    console.log('start login')

    if (this.loginForm.invalid) {
      console.log('invalid ??')

      this.errorHandler.handleErrors(this.loginForm, this.errors);
      return;
    }

    this.authService.login(this.loginForm.value).subscribe(
      (resp) => {
        // console.log("DATA: ", resp['data'])
        // if (!resp['data']['profileConfirmed']) {
        //   this.mtg.selectedIndex = 1;
        // } else {
        //   this.onSuccess();
        // }
        this.onSuccess();
      }, error => this.onError(error)
    );
  }

  goToResetPass() {
    console.log('test');

    this.mtg.selectedIndex = 2;
  }

  confirm(): any {
    if (this.pinForm.valid) {
      this.authService.activateProfile(this.pinForm.value).subscribe(() => {
        this.onSuccess();
      }, (error) => {
        this.onError(error);
      });
    }
  }

  sendResetPasswordLink() {
    if (this.pwdForm.valid) {
      this.authService.generatePasswordLink(this.pwdForm.value).subscribe(
        (resp) => {
          this.router.navigate(['home']);
          this.snackBar.open('Operation successful! Check your inbox.', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
        },
        (error) => {
          this.snackBar.open('An error occurred while sending reset password link.', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
        }
      );
    }
  }
  onFormValueChanges(): any {
    this.loginForm.valueChanges.subscribe(() => {
      this.errors.serverError = null;
      this.errorMessage = null;
    });
  }

  goToSignup(): any {
    this.router.navigate(['auth/signup/']).then();
  }

  goToLogin(): any {
    this.mtg.selectedIndex = 0;
    this.loginForm.reset();
  }

  getNewPinCode(): any {
    this.authService.getNewPinCode().subscribe(
      () => this.snackBar.open('Pin Code sent to email!', 'Close', { duration: 3000, panelClass: 'success-snackbar' }));
  }

  onSuccess(): void {

    this.screen.getScreenWidth().subscribe(width => {
      this.screenSize = width

    })
    this.authService.getProfile().subscribe((resp) => {
      this.profile = resp.data;      
      if (this.profile.entityid.draft === 1) {
        if (this.screenSize <= 720) {
          this.router.navigateByUrl('/dashboard/nav-tab/newsfeed')
        } else if (this.screenSize > 720) {
          this.router.navigate(['dashboard']).then();
        }

      } else {
        const firstEntity = this.profile.entities.filter((e) => e.eid !== this.profile?.entityid?.eid)[0]?.eid        

        if (firstEntity) {
          this.router.navigate(['entity/homepage/' + firstEntity]);
          localStorage.setItem('currentEntity', firstEntity)
        } else {
          this.router.navigate(['entity/homepage/' + this.profile?.entityid?.eid]);
        }
      }
    });
  }

  onError(error): void {
    this.errorHandler.organizeServerErrors(error.errors, this.loginForm);
    this.errorMessage = error['data']['msg'];
    // this.snackBar.open(error.message, 'Close', {duration: 3000});
  }
}














@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.html',
  styleUrls: ['./login.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  passwordForm: FormGroup;
  errors: any = {};

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandler,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.passwordForm = this.getChangePasswordForm();
  }

  public getChangePasswordForm(): FormGroup {
    const passwordValidators = [Validators.required, Validators.maxLength(128), Validators.minLength(8)];
    return this.fb.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [...passwordValidators]],
      repeat_new_password: ['', [...passwordValidators]]
    }, { validators: passwordMisMatchValidator });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public changePassword(): any {
    if (this.passwordForm.invalid) {
      this.errorHandler.handleErrors(this.passwordForm, this.errors);
      return;
    }

    this.authService.changePassword(this.passwordForm.value).subscribe(
      response => this.onSuccess(response),
      error => this.onError(error)
    );
  }

  private onSuccess({ message }): void {
    this.snackBar.open(message, 'Close', { duration: 4000 });
    this.authService.logout();
    this.dialogRef.close();
    this.router.navigate(['/login']).then();
  }

  private onError(error): void {
    this.errorHandler.organizeServerErrors(error.errors, this.passwordForm);
  }
}

