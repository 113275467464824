import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-small-project-card',
  templateUrl: './small-project-card.component.html',
  styleUrls: ['./small-project-card.component.scss']
})
export class SmallProjectCardComponent implements OnInit {
  @Input() projects;
  leadingPictureURL = `${environment.API_HOST}/files/profilepicture/`;


  constructor(
    private service: DashboardService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,

  ) { }
  ngOnInit(): void {
  }


  getLeadContestImage(id): any {
    return this.leadingPictureURL + id;
  }

  editProject(project): any {
    this.router.navigate(['project/read', { id: project.projectid, entityId: project.ownerid, }]).then();
  }
}
