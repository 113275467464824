<mat-card-header>
    <mat-card-title class="header">
        <h4 *ngIf="contests.length; else elseBlock">A list of contests that you interact with</h4>
        <ng-template #elseBlock>
            <h4>No Contests Available</h4>
        </ng-template>
    </mat-card-title>
    <span fxFlex></span>
</mat-card-header>
<br />

<div *ngFor="let contest of contests" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" #lastElement>
    <div *ngIf="contest.contest.length" (click)="goToContest(contest.projectid, contest.contest[0].ownerid)"
        class="notification-unread">
        <section class="row-contest-item">
            <div>
                <h5 class="notification-header">{{contest?.contest[0]?.title}}</h5>
                <h5 class="notification-header">{{contest?.contest[0]?.maincategory}}</h5>
            </div>
            <div>
                <h5 class="notification-header">Status:
                    <ng-container *ngIf="contest.state === 1">In Progress</ng-container>
                    <ng-container *ngIf="contest.state === 2">Submitted</ng-container>
                    <ng-container *ngIf="contest.state === 3">Canceled</ng-container>
                    <ng-container *ngIf="contest.state === 4">Winner</ng-container>
                </h5>
            </div>

        </section>
    </div>
</div>