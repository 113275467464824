import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContestService {
  private ENTITY_URL = `${environment.API_HOST}/entity/`;

  constructor(private http: HttpClient) { }

  getCategories(): Observable<any> {
    return this.http.get<any>(`${this.ENTITY_URL}contest/maincategories/`);
  }

  getSubCategories(data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}contest/subcategories/`,
      data
    );
  }

  getDrafts(entityId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/contest/getdrafts/`
    );
  }

  getDraft(entityId, id): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/contest/getdraft/${id}/`
    );
  }

  getApplication(entityId, contestid): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/contest/application/${contestid}`
    );
  }

  cancelApplication(entityId, contestid, applid): Observable<any> {
    return this.http.put<any>(
      `${this.ENTITY_URL}${entityId}/contest/application/${contestid}`, { entityId, contestid, applid }
    );
  }

  createApplication(entityId, contestid, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/application/${contestid}`,
      data
    );
  }

  submitApplication(entityId, contestid, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/application/apply/${contestid}`,
      data
    );
  }

  getNewApplicationId(entityId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/contest/getnewapplicationid/`
    );
  }

  getContest(entityId, id): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/contest/read/${id}/`
    );
  }

  getAppFiles(entityId, draftId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/contest/application/getfiles/${draftId}/`
    );
  }

  addAppFile(entityId, data, id): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/application/addfile/${id}/`,
      data
    );
  }

  removeAppFile(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/application/remove/file`,
      data
    );
  }

  postDraft(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/draft/`,
      data
    );
  }

  getNewDraftId(entityId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/contest/getnewdraftid/`
    );
  }

  createContest(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/create/`,
      data
    );
  }

  updateContest(entityId, contestId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/update/${contestId}}`,
      data
    );
  }

  getFiles(entityId, draftId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/contest/getfiles/${draftId}/`
    );
  }

  addFile(entityId, data, id): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/addfile/${id}/`,
      data
    );
  }

  removeFile(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/removefile`,
      data
    );
  }

  deleteDraft(entityId, id): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/discard/${id}/`,
      {}
    );
  }

  deleteContest(entityId, id): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/delete/${id}`,
      {}
    );
  }

  postMainimage(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/draft/mainimage`,
      data
    );
  }

  postMainimageContest(entityId, data): Observable<any> {
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/mainimage`,
      data
    );
  }

  getComments(entityId, draftId): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/contest/comments/${draftId}/`
    );
  }

  getApplications(entityId, contestid): Observable<any> {
    return this.http.get<any>(
      `${this.ENTITY_URL}${entityId}/contest/mgmt/listapps/${contestid}/`
    );
  }

  readApplication(entityId, contestid, data): Observable<any> {
    let data2 = {
      applicant_id: data,
    };
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/mgmt/getapp/${contestid}/`,
      data2
    );
  }

  saveRating(entityId, contestid, numberStars, applid): Observable<any> {
    let data = {
      rating: numberStars,
      applicant_id: applid,
    };
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/mgmt/apps-ratings/${contestid}/`,
      data
    );
  }

  postAward(entityId, contestid, applid, note, position): Observable<any> {
    let data = {
      applicant_id: applid,
      position: position,
      note: note
    };
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/mgmt/award-contest/${contestid}/`,
      data
    );
  }

  cancelAward(entityId, contestid, applid): Observable<any> {
    let data = {
      applicant_id: applid,
    };
    return this.http.post<any>(
      `${this.ENTITY_URL}${entityId}/contest/mgmt/cancel-contest-award/${contestid}/`,
      data
    );
  }

  filterContests(filters): Observable<any> {
    return this.http.post<any>(
      `${environment.API_HOST}/contest/browse/`,
      filters
    );
  }

  getContestConnections(entityId): Observable<any> {
    return this.http.get<any>(`${environment.API_HOST}/contest/mgmt/get-applicants/${entityId}`)
  }
}
