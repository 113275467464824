<div class="container" style="padding-bottom:2rem">
  <div class="flex">
    <div class="leftcolumn" *ngIf="profile?.entityid?.draft === 1">
      <app-profile-info [userId]="userId"></app-profile-info>
    </div>

    <div class="read-card" [ngClass]="profile?.entityid?.draft === 0 ? 'full-width' : '' ">
      <mat-card class="article-main-container mat-elevation-z3">
        <!-- <pre>{{ article | json }}</pre> -->
        <div class="top-buttons-wrapper">
          <div class="row" style="display: flex; padding: 16px">
            <div style="display: flex; gap: 8px">
              <div>
                <div *ngIf="entity?.draft === 0" style="cursor: pointer" (click)="openExpertProfile()">
                  <img [src]="getUrl(entity?.logofileid) | secure | async" alt="photo" class="profile-pic"
                    *ngIf="entity?.logofileid !== ''" />
                  <div *ngIf="entity?.logofileid === ''" class="profile-initial">
                    {{ entity?.orgname ? entity?.orgname[0].toUpperCase() : '' }}
                  </div>
                </div>

                <div *ngIf="entity?.draft === 1" style="cursor: pointer"
                  (click)="openExpertPublicProfile(article.userid)">
                  <img [src]="getUrl(userPersonalData?.profilepicture) | secure | async" alt="photo" class="profile-pic"
                    *ngIf="userPersonalData?.profilepicture" />
                  <div *ngIf="!userPersonalData?.profilepicture" class="profile-initial">
                    {{ userPersonalData?.firstname ? userPersonalData?.firstname[0].toUpperCase() : '' }}
                  </div>
                </div>

              </div>
              <h1 class="article-title">{{ article?.title }}</h1>
              <p class="mute-tag">{{ article?.type }}</p>
            </div>
            <div style="display: flex; gap: 8px" class="mutes-container">
              <p class="mute-tag">
                {{('view-content.article.published' | translate) + " " + article?.ts?.split("T")[0]}}
              </p>
            </div>
            <p class="mute-tag">{{('view-content.article.category.header' | translate) + " " + article?.category }} </p>
          </div>

          <div class="apply-edit-manage">
            <div *ngIf="isOwner" class="footer-controls-article">
              <button mat-stroked-button (click)="editButtonArticle()">
                <mat-icon class="edit-button">edit</mat-icon>
              </button>

              <button mat-stroked-button (click)="deleteButtonArticle()">
                <mat-icon class="delete-button">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <center *ngFor="let i of list" class="logo-container-article">
          <img [src]="getUrl(article?.mainimage) | secure | async" />
        </center>

        <div class="content-container">
          <!-- <h2>Content</h2> Not sure if this is needed-->
          <quill-view-html [content]="article?.content"></quill-view-html>
        </div>


        <div class="bibliography-container">
          <h2>{{'view-content.article.bibliography.header' | translate}}</h2>
          <quill-view-html [content]="article?.bibliography" theme="snow"></quill-view-html>
        </div>

        <div *ngIf="article?.mainimage && files?.length - 1 > 0" class="bibliography-container">
          <h2>{{'view-content.article.additional-resources.header' | translate}}</h2>
          <div *ngFor="let file of files" fxLayout="row" fxLayoutAlign="space-between left">
            <a *ngIf="file?.id !== article?.mainimage" [href]="getUrl(file?.id) | secure | async"
              download="{{ file.name }}">
              <span>{{ file.name }}</span>
              <span class="file-size">({{ file.sizebytes | fileSize }})</span>
            </a>
          </div>
        </div>

        <div class="keyword-container" fxLayout="row wrap" fxLayoutGap="10px">
          <p *ngFor="let keyword of article?.keywords" class="keyword-wrap">
            {{ keyword?.keyword }}
          </p>
        </div>

        <div class="social-wrapper">
          <div class="social-pad">
            <div class="social-info-block">
              <div class="likes-wrapper">{{(newsfeed?.like_count || 0 )+ " "+('view-content.socials.count-likes'
                |
                translate)}}</div>
              <div class="comments-wrapper">
                <a href="javascript:void(0)" [matMenuTriggerFor]="readComments" (click)="getComments(articleId)"
                  #commentTrigger="matMenuTrigger">{{(newsfeed?.comment_count || 0 )+ "
                  "+('view-content.socials.count-comments'
                  |
                  translate)}}</a>
              </div>
            </div>
          </div>
          <div class="button-wrapper">
            <div class="button-likes">
              <button mat-button [color]="newsfeed?.is_liked ? 'primary' : ''" [matTooltip]="newsfeed?.is_liked ? ('view-content.socials.like.unlike' | translate) 
              : ('view-content.socials.like.like' |
                translate)" (click)="likeUnlike(newsfeed)">
                <mat-icon>thumb_up</mat-icon>{{newsfeed?.is_liked ? ('view-content.socials.like.liked' |
                translate) :
                ('view-content.socials.like.like' |
                translate)}}
              </button>

            </div>
            <!-- <div class="button-shares">
              <button mat-button matTooltip="Share the contest" [matMenuTriggerFor]="shareMenu"
                #shareTrigger="matMenuTrigger"> <mat-icon>reply</mat-icon> Share</button>
            </div> -->
            <div class="button-comments">
              <button mat-button [matTooltip]="'view-content.socials.comment.mat-menu.tooltip' | translate"
                [matMenuTriggerFor]="postComment" #postTrigger="matMenuTrigger"> <mat-icon>comment</mat-icon>
                {{'view-content.socials.comment.icon' | translate}}</button>
            </div>
          </div>
        </div>


        <mat-menu #readComments="matMenu" class="custom-mat-menu">
          <div *ngFor="let comment of comments" class="comment-unit">
            <div class="comment-header-wrapper">
              <div>{{comment.userData.firstname + ' ' + comment.userData.lastname}}</div>
            </div>
            <mat-hint>{{comment.ts | date: 'medium'}}</mat-hint>
            <div>{{comment.comment}}</div>
          </div>
          <div class="no-comment-unit" *ngIf="comments?.length === 0">
            {{'view-content.socials.comment.mat-menu.button' |
            translate}}
          </div>
        </mat-menu>

        <mat-menu #postComment="matMenu" class="custom-mat-menu">
          <form [formGroup]="commentForm" style="padding: 0 20px" fxLayout="column" (click)="$event.stopPropagation()"
            fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-form-field class="custom-form-field" appearance="outline">
              <mat-label>{{'view-content.socials.comment.mat-menu.label' | translate}}</mat-label>
              <textarea matInput type="text"
                [placeholder]="'view-content.socials.comment.mat-menu.placeholder' | translate"
                formControlName="comment"></textarea>
            </mat-form-field>

            <button type="button" mat-stroked-button (click)="comment(articleId, postTrigger)">
              {{'view-content.socials.comment.mat-menu.button' | translate}}
            </button>
          </form>
        </mat-menu>

        <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
          <form [formGroup]="shareForm" style="padding: 0 20px" (click)="$event.stopPropagation()">
            <p>{{'view-content.socials.share.header' | translate}}</p>
            <div fxLayout="column" fxLayoutAlign="start center">
              <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body"
                bindValue="uuid" bindLabel="name" [clearable]="true" formControlName="mynetwork"
                [notFoundText]="'No Results'" typeToSearchText="Search"
                [placeholder]="'view-content.article.share.placeholder' | translate">
              </ng-select>

              <!-- <button type="button" mat-stroked-button (click)="share(shareTrigger)">
                Share
              </button> -->
            </div>
          </form>
        </mat-menu>
      </mat-card>
    </div>
    <!-- <div class="rightcolumn">
    <div class="similar-articles">
      <span class="articles-label">Similar Articles</span>
    </div>
  </div> -->
  </div>
</div>