<mat-card *ngIf="!contests?.length">
    <mat-card-subtitle>
        {{'entity-dashboard.desktop-version.entity-content.contests-tab.no-content' | translate}}
    </mat-card-subtitle>
</mat-card>
<div *ngIf="contests" style="display: flex; flex-direction: column; gap: 1rem; cursor: pointer;"
    class="entity-desktop-contest-wrapper">
    <mat-card mat-ripple *ngFor="let contest of contests" (click)="editContest(contest)">
        <mat-card-title>{{contest?.title}}</mat-card-title>
        <!-- <mat-card-subtitle>{{ 'entity-dashboard.desktop-version.entity-content.categories.' + contest?.category |
            translate }}</mat-card-subtitle> -->
        <mat-card-content>
            <i>
                <quill-view-html
                    [content]="contest?.abstract?.length > 150 ? (contest?.abstract | slice:0:150) + '...' : contest?.abstract"></quill-view-html>
            </i>
            <div class="picture-description-container">
                <img *ngIf="contest?.picture" mat-card-md-image
                    [src]="getLeadContestImage(contest?.picture) | secure | async" alt="contest image">
                <quill-view-html
                    [content]="contest?.description?.length > 150 ? (contest?.description | slice:0:150) + '...' : contest?.description"></quill-view-html>
            </div>
        </mat-card-content>
        <mat-card-footer>
            <!-- <mat-card-subtitle>Start: {{contest?.startdate}}</mat-card-subtitle>
            <mat-card-subtitle>End: {{contest?.enddate}}</mat-card-subtitle> -->
        </mat-card-footer>
    </mat-card>
</div>