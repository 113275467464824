import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss']
})

export class LanguageDropdownComponent implements OnInit {
  languages = [
    {
      value: 'en',
      text: 'English',
      icon: '',
    },
    {
      value: 'bg',
      text: 'Български',
      icon: ''
    }
  ]

  @Input() design: string;

  selectedLanguage;

  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('bg');
    this.translateService.use(this.getSavedLanguage());
  }

  ngOnInit(): void {
    this.selectedLanguage = this.getSavedLanguage() || 'bg'
  }

  private getSavedLanguage(): any {
    return localStorage.getItem('selectedLanguage') || localStorage.setItem('selectedLanguage', 'bg')
  }

  public setLanguage(lang: string): void {
    this.translateService.use(lang)
    localStorage.setItem('selectedLanguage', lang)
    this.selectedLanguage = lang;
  }

}
